import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom"
import Mold_list from "./Mold_list";
import ShiftReport_All from "./All_Shift_Reports";
import New_ShiftReport from "./New_Shift_Reports";
import '../../Responsive.css'


const Production_dashboard = () => {
  const [animation, setAnimation] = useState(true);
  var userName = (window.localStorage.getItem("Name").replace(/[{""}]/g, ""));
  const [Area, setArea] = useState("");
  var Stage = (window.localStorage.getItem("Stage"));
  const [rank_stage, setrank_stage] = useState(false);
  var Ranked = (window.localStorage.getItem("Rank"));
  const [mold_list, setmold_list] = useState(false);
  const [New_Report, setNew_Report] = useState(false);
  const [All_Reports, setAll_Reports] = useState(false);

  useEffect(() => {
    if (Area == "Mold List") {
      setmold_list(true);
      setAnimation(false);
      setNew_Report(false);
      setAll_Reports(false);
    } if (Area == "New Shift Report") {
      setmold_list(false);
      setAnimation(false);
      setNew_Report(true);
      setAll_Reports(false);
    } if (Area == "Shift Reports") {
      setmold_list(false);
      setAnimation(false);
      setNew_Report(false);
      setAll_Reports(true);
    }
  }, [Area]);
  const handleSelect = (selectedArea) => {
    setArea(selectedArea);

  };

  useEffect(() => {
    if (Ranked === '"Admin"' && Stage === '"2"') {
      setrank_stage(true);
    }
  }, [Ranked]);

  return (
    <div class="row" id="main-row">
      <div class="row" id="header-row-production">
        <div id="sidebar-col" >
          <div id="sidebar">
            <ul id="sidebar-nav">
              <li class="nav-item" id="nav-item-1" style={{ background: "#2B6532", padding: "0px 150px" }} onClick={() => handleSelect('Mold List')}>
                <span>Mold List</span>
              </li>
              <li class="nav-item" id="nav-item-2" style={{ background: "#2B6532", padding: "0px 150px" }} onClick={() => handleSelect('New Shift Report')}>
                <span>New-Report</span>
              </li>
              <li class="nav-item" id="nav-item-3" style={{ background: "#2B6532", padding: "0px 150px" }} onClick={() => handleSelect('Shift Reports')}>
                <span>All Reports</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col" id="content-col">
        <br />
        <div class="row" id="header-row">
          <div class="col-2" id="header-col-1"></div>
          <div class="col-2" id="header-col-2"></div>
          <div class="col-2" id="header-col-3"></div>
          <div class="col-4" id="header-col-4"></div>
          <div id="user-name-block" class="col-2">
            <span class="user-name">{userName}</span>
          </div>
        </div>
        <h1 id="area-title" class="area-title-production">{Area}</h1>
        {animation ? (
          <div id="title_supplychain_box">
            <p id="supply_chain_title">Production Module</p>
          </div>
        ) : null}
        {mold_list ? <Mold_list /> : null}
        {New_Report ? <New_ShiftReport /> : null}
        {All_Reports ? <ShiftReport_All /> : null}
      </div>
    </div>

  );
}

export default Production_dashboard;
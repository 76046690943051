import React, { useState, useEffect } from 'react'
import { Link, Redirect, useParams } from "react-router-dom"
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { host, Host } from '../../App';
import Axios from 'axios'
import '../../Responsive.css'

function History_Received_raw_Material() {
  var [postList, setPostList] = useState([]);
  var [PoNo, setPoNo] = useState({});
  var [poNo, setpoNo] = useState([]);
  var [Item_Desc, setItem_Desc] = useState("");
  var [Date1, setDate1] = useState(new Date());
  var [Postartdate, setPoStartdate] = useState('2023-01-01');
  var [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  var [Supplier, setSupplier] = useState({});
  var [ItemNo, setItemNo] = useState("");
  var [Customers, setCustomers] = useState([]);
  var [partNo, setpartNo] = useState([]);
  var [order, setOrder] = useState("1");
  var total = "";
  const [data, setData] = useState([{ PO_No: "", Item_No: "", ReceivedQty: "", ActualReceiveddate: "", Invoice_cost: "", Unit_cost: "", PO_CURRENCY: "", Supplier: "", Item_Description: "", QTY: "", Unit_price_po: "", ETA: "" },]);


  //var token = sessionStorage.getItem("token");
  var token = (window.localStorage.getItem("acessToken"));

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/_HistoryReceived_Raw_Material/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)}`).then((data) => {

      setData(data.data);
    })
  }, []);
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Raw_Material/pono/${token}`).then((data) => {
      setpoNo(data.data)
    });
  }, [])
  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Raw_Material/suppliers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Raw_Material/itemno/${token}`).then((data) => {
      setpartNo(data.data)
    });
  }, [])
  const search = () => {
    Axios.get(`https://` + Host + `/api/_HistoryReceived_Raw_Material/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)}`).then((data) => {
      setData(data.data);
    })
  }

  const [changedRows, setChangedRows] = useState(new Set());

  const Delete = (Po_No, Item_No, Receiving_Date) => {
    const queryParams = {
      token: token,
      Po_No: Po_No,
      Item_No: Item_No,
      Receiving_Date: Receiving_Date
    };

    Axios.delete(`https://` + host + `/api/History_ReceivedRawMaterial/Delete`, { params: queryParams }).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
        });
        search();
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });

  };

  return (
    <div className="MainPage_data">
      <div class="Container">
        <ToastContainer />
        <br></br>  <h1>History Received Raw Material</h1><br></br>
        <div class="row">
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='PO NO' list="PoNo" onChange={(e) => {
                setPoNo(e.target.value)
              }} onSelect={search} />
              <datalist id="PoNo">
                <option></option>
                {poNo.map(x => {
                  return <option>{x.PO_No}</option>
                })}
              </datalist>
            </div >

          </div >
          <div class="col">
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Item No' list='Itemno' onChange={(e) => {
                setItemNo(e.target.value)
              }} onSelect={search} />
              <datalist id="Itemno">
                <option></option>
                {partNo.map(x => {
                  return <option>{x.Item_No}</option>
                })}
              </datalist>
            </div>
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Item DESCRIPTION' list="Itemdesc" onChange={(e) => {
                setItem_Desc(e.target.value.replace(/[{"''"}]/g, ''));
                console.log((e.target.value.replace(/[{"''"}]/g, '')));

              }} onSelect={search} />
              <datalist id="Itemdesc">
                <option></option>
                {postList.map(x => {
                  return <option>{x.Item_Description}</option>
                })}
              </datalist>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col" id='moldng'>
                {/* Grab the Itemicular dates */}
                <input type='date' class="form-control form-rounded" placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                  setPoStartdate(e.target.value)
                }} onSelect={search} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                <span class="tooltiptext">Filter By ETA </span>

              </div>
              <div class="col">
                <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setPoEnddate(e.target.value)
                }} onSelect={search} />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Supplier */}
              <input type='text' class="form-control form-rounded" placeholder='Supplier' list='Suppliers' onChange={(e) => {
                setSupplier(e.target.value)
              }} onSelect={search} />
              <datalist id="Suppliers">
                <option></option>
                {Customers.map(x => {
                  return <option>{x.Supplier}</option>
                })}
              </datalist>
            </div>
            <div class="row">
              <Link to="/Suppluchain">
                <input type="button" class="btn btn-success" defaultValue="Back" />
              </Link>
            </div >

          </div>
        </div >
        <br></br>
        <br></br>

        <table className="table table-bordered" id='HistoryRRM'>
          <thead>
            <tr>
              <th>PO NO</th>
              <th>ITEM NO</th>
              <th>PO DATE</th>
              <th>ITEM DESCRIPTION</th>
              <th>SUPPLIER</th>
              <th>PO QTY</th>
              <th>ETA</th>
              <th>RECEIVED QTY</th>
              <th>RECEIVED DATE</th>
              <th>CURRENCY</th>
              <th>Unit Cost(PO)</th>
              <th>Unit Price-Database</th>
              <th>Invoice Cost</th>
              <th>Invoice Amount</th>
              <th id="comment">COMMENTS </th>
              <th>Delete</th>
            </tr>
          </thead>
          {data.map((val, index) => (
            <tbody key={index}
              className={val.Unit_Cost_PO > val.COST ? "highlight-row" : ""}
            >
              <tr
                key={`${val.PO_No}-${val.Item_No}`}
              >
                <td>{val.PO_No}</td>
                <td>{val.Item_No}</td>
                <td>{val.PO_Date ? val.PO_Date.slice(0, 10) : ""}</td>
                <td>{val.Item_Description}</td>
                <td>{val.Supplier}</td>
                <td>{val.PO_QTY}</td>
                <td>{val.ETA ? val.ETA.slice(0, 10) : ""}</td>
                <td>{val.QTY_Received}</td>
                <td>{val.Receiving_Date ? val.Receiving_Date.slice(0, 10) : ""}</td>
                <td>{val.CURRENCY_PO_RM_DATASET}</td>
                <td>${val.Unit_Cost_PO}</td>
                <td>${val.COST}</td>
                <td>${val.Unit_Cost_Invoice}</td>
                <td>${val.Invoice_Amount}</td>
                <td id="comment">{val.Comments}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      window.confirm("Are you sure you want to delete this Entry ?") &&
                      Delete(val.PO_No, val.Item_No, val.Receiving_Date.slice(0, 10))
                    }
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>

      </div >
    </div >
  )
}

export default History_Received_raw_Material
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import "../ShippingData.css";
import "./Add_Part_Dataset.css";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Host } from '../App';
import { loadCustomers } from '../Dashboard';

import App from '../App';

function Add_Internal_Part_Dataset() {

  //search for data
  const [PartNo, setPartno] = useState({});
  const [PartDesc, setPartDesc] = useState({});
  const [InternalPart, setInternalPart] = useState({});
  const [Customers, setCustomers] = useState({});
  const [Category, setCategory] = useState({});
  const [Type, setType] = useState({});
  const [editable, seteditable] = useState(false);
  const [Updateable, setUpdateable] = useState(false);
  const [del, setdel] = useState(0);
  const [partDataset, setpartDataset] = useState([]);
  const [Part_No, setPartNo] = useState("");
  const [Part_Description, setPart_description] = useState("");
  const [Customer, setCustomer] = useState("");
  const [CATEGORY, setcategory] = useState("");
  const [Unit_Price, setunitprice] = useState("");
  const [CURRENCY, setcurrency] = useState("");
  const [SIZE, setsize] = useState("");
  const [BASE_STYLE_TUHOY, setbasestyletoughy] = useState("");
  const [MOLDING, setMoulding] = useState("");
  const [WOODSHOP, setWoodshop] = useState("");
  const [UPHOLSTERY, setUpholstery] = useState("");
  const [BUY_AND_SELL, setbuyandsell] = useState("");
  var userName = (window.localStorage.getItem("Name").replace(/[{""}]/g, ""));
  const [rank, setrank] = useState(false);

  var Ranked = (window.localStorage.getItem("Rank"));
  var Stage = (window.localStorage.getItem("Stage"));

  var token = (window.localStorage.getItem("acessToken"));

  const [data, setData] = useState([
    { Part_No: "", Part_internalNo: "", Part_Description: "", Customer: "", Type: "" },
  ]);
  const [filter, setFilter] = useState(
    [{ Emp_ID: "", EmployeeName: "", ContactNumber: "", Department: "", Source: "", Position: "", Status: "", token: token },
    ]);
  const updateFilter = (e, key) => {
    const { value } = e.target;
    setFilter(prevData => {
      const newData = [...prevData];
      newData[0][key] = value;
      return newData;
    });
  };


  const partfilters = {
    PartNo: PartNo,
    PartDesc: PartDesc,
    Customers: Customers,
    Category: Category,
    token: token,
    InternalPart: InternalPart,
    Type: Type
  }
  useEffect(() => {
    if (Ranked === '"Admin"' && Stage === '"2"') {
      setrank(true);
    }
  }, [Ranked]);
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/Internal/partdataset`, { params: partfilters }).then((data) => {
      setpartDataset(data.data)
    });
  }, [PartNo, PartDesc, Customers, Category, del, InternalPart, Type])

  const submitValue = (e, index) => {
    const newData = [...data];
    newData[index].Part_No = e.target.value;
    if (partDataset.find((x) => x.Part_No === e.target.value)) {
      newData[index].Customer = (partDataset.find((x) => x.Part_No === e.target.value).Customer);

      newData[index].Part_Description = partDataset.find((x) => x.Part_No === e.target.value).Part_Description;
      setData(newData);
    }
  }
  //

  const [changedLine, setChangedLine] = useState(new Set());
  const handleInputChange = (e, index, key) => {
    const { value } = e.target;
    updateDatas(index, key, value);
  };

  const updateDatas = (index, key, value) => {
    setpartDataset(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    setChangedLine(prevRows => new Set(prevRows).add(index));
  };

  const submitpart = () => {
    console.log(data);
    Axios.post(`https://` + Host + `/api/add/InternalpartDataset`, { Data: data, token: token }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        setData([{ Part_No: "", Part_internalNo: "", Part_Description: "", Customer: "", Type: "" },]);
        window.alert(response.data.message);
      }


      seteditable(false);
      setdel(del + 1);
    })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  const Update = () => {
    const changedData_ = [...changedLine].map(index => {
      return partDataset[index];

    });
    console.log(changedData_);

    Axios.post(`https://` + Host + `/api/internalpartDataset/Update`, { changedData: JSON.stringify(changedData_), token: token }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        window.alert(response.data.message);
      }
      seteditable(false);
      setdel(del + 1);
    })
      .catch(function (error) {
        console.log(error.response);
      });

  };


  const submitpartdatasetdata = () => {
    console.log(Part_No + Part_Description + Customer + CATEGORY + Unit_Price + CURRENCY + SIZE + BASE_STYLE_TUHOY + MOLDING + WOODSHOP + UPHOLSTERY + BUY_AND_SELL);
    Axios.post(`https://` + Host + `/api/submitpartdatasetdata`, {
      Part_No: Part_No,
      Part_Description: Part_Description,
      Customer: Customer,
      CATEGORY: CATEGORY,
      Unit_Price: Unit_Price,
      CURRENCY: CURRENCY,
      SIZE: SIZE,
      BASE_STYLE_TUHOY: BASE_STYLE_TUHOY,
      MOLDING: MOLDING,
      WOODSHOP: WOODSHOP,
      UPHOLSTERY: UPHOLSTERY,
      BUY_AND_SELL: BUY_AND_SELL,
      token
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        window.alert(response.data.message);
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }


  return (
    <div className="MainPage_data">
      <div class="Container">
        <br></br>
        <div>
          <div>
            <h2 style={{ padding: 10 }}>Internal Part Dataset</h2>
            <br />
            {editable ?
              <div class="add_section">
                <center>
                  <div class="row" id="Part_dataset_add">
                    {data.map((line, index) => (
                      <tr>
                        <td style={{ padding: 10 }}>
                          <label>Part No</label>
                          <input
                            type="text"
                            name="Part_No"
                            class="form-control form-rounded"
                            list='PartNo'
                            onBlur={(e) => submitValue(e, index)}

                          />
                        </td>
                        <td style={{ padding: 10 }}>
                          <label>Internal Part No</label>
                          <input
                            type="text"
                            name="Internal Part No"
                            class="form-control form-rounded"
                            onChange={(e) => {
                              const newData = [...data];
                              newData[index].Part_internalNo = e.target.value;

                              setData(newData);
                            }}

                          />
                        </td>
                        <td style={{ padding: 10 }}>
                          <label>Part Description</label>
                          <input
                            type="text"
                            name="Part_Description"
                            class="form-control form-rounded"
                            Value={data[index].Part_Description}
                            onChange={(e) => {
                              const newData = [...data];
                              newData[index].Part_Description = e.target.value;

                              setData(newData);
                            }}

                          />
                        </td>
                        <td style={{ padding: 10 }}>
                          <label>Customer</label>
                          <input
                            type="text"
                            name="Customer"
                            class="form-control form-rounded"
                            Value={data[index].Customer}
                            onBlur={(e) => {
                              setCustomer(e.target.value);
                            }}
                            onChange={(e) => {
                              const newData = [...data];
                              newData[index].Customer = e.target.value;

                              setData(newData);
                            }}
                          />
                        </td>
                        <td style={{ padding: 10 }}>
                          <label>Type</label>
                          <input
                            type="text"
                            name="Type"
                            class="form-control form-rounded"
                            onBlur={(e) => {
                              setcategory(e.target.value);
                            }}
                            onChange={(e) => {
                              const newData = [...data];
                              newData[index].Type = e.target.value;

                              setData(newData);
                            }}
                            list="Type"
                          />
                          <div class="scrollbar">
                            <datalist id="category">
                              <option></option>
                              {partDataset.map((x) => {
                                return <option>{x.CATEGORY}</option>;
                              })}
                            </datalist>
                          </div>
                        </td>
                      </tr>
                    ))}
                    <div>
                      <input
                        type="button"
                        class="btn btn-success"
                        defaultValue="Submit"
                        style={{ width: "auto" }}
                        name="Submit"
                        onClick={submitpart}
                      />
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                </center>
              </div> : null}
            <div class="row">
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Part No' onChange={(e) => {
                  setPartno(e.target.value)
                }} list='PartNo' />
                <datalist id="PartNo">
                  <option></option>
                  {partDataset.map(x => {
                    return <option>{x.Part_No}</option>
                  })}
                </datalist>
              </div>
              <div class='col'>
                <div class="row">
                  <input type='text' class="form-control form-rounded" placeholder='Part Description' list='PartDescription' onChange={(e) => {
                    setPartDesc(e.target.value)
                  }} />
                  <datalist id="PartDescription">
                    <option></option>
                    {partDataset.map(x => {
                      return <option>{x.Part_Description}</option>
                    })}
                  </datalist>
                </div>
                <div class="row">
                  <input type='text' class="form-control form-rounded" placeholder='Internal Part No' list='InternalPart' onChange={(e) => {
                    setInternalPart(e.target.value)
                  }} />
                  <datalist id="InternalPart">
                    <option></option>
                    {partDataset.map(x => {
                      return <option>{x.Part_internalNo}</option>
                    })}
                  </datalist>
                </div>
              </div>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customer' onChange={(e) => {
                  setCustomers(e.target.value)
                }} />
                <datalist id="Customer">
                  <option></option>
                  {[...new Set(partDataset.map(x => x.Customer))].map((Customer, index) => (
                    <option key={index}>{Customer}</option>
                  ))}
                </datalist>
              </div>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Category' list='Category' onChange={(e) => {
                  setCategory(e.target.value)
                }} />
                <datalist id="Category">
                  <option></option>
                  {[...new Set(partDataset.map(x => x.CATEGORY))].map((CATEGORY, index) => (
                    <option key={index}>{CATEGORY}</option>
                  ))}
                </datalist>
              </div>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Type' list='Type' onChange={(e) => {
                  setType(e.target.value)
                }} />
                <datalist id="Type">
                  <option></option>
                  {[...new Set(partDataset.map(x => x.Type))].map((Type, index) => (
                    <option key={index}>{Type}</option>
                  ))}
                </datalist>
              </div>
              <div class="col">
                <button className='btn btn-primary' onClick={() => { seteditable(!editable) }} >Add Internal No</button><button className='btn btn-success' onClick={() => { setUpdateable(!Updateable) }} >Edit Internal No</button>
              </div>
            </div >
            <br />
            <div class="datatable-wide_part">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>PART NO</th>
                    <th>INTERNAL PART NUMBER</th>
                    <th>PART DESCRIPTION</th>
                    <th>CUSTOMER</th>
                    <th>CATEGORY</th>
                    <th>TYPE</th>
                    <th>Update</th>
                  </tr>
                </thead>
                <tbody >
                  {partDataset.map((val, index) => {
                    return (
                      <tr>
                        <td>{val.Part_No}</td>
                        <td>{Updateable ?
                          <input type="text" className="form-control form-rounded" Value={val.Part_internalNo} onChange={(e) => handleInputChange(e, index, 'Part_internalNo')} /> : val.Part_internalNo}</td>
                        <td>
                          {val.Part_Description}
                        </td>
                        <td>
                          {val.Customer}
                        </td>
                        <td>
                          {val.CATEGORY}
                        </td>
                        <td>
                          {val.Type}
                        </td>
                        <td><button class="btn btn-success" onClick={() => { Update((val.Part_No)) }}>Update</button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
    </div >
  )
}

export default Add_Internal_Part_Dataset;
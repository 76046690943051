
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Login.css';
import real_logo from "./login_real_logo.png";
import { getToken, removeUserSession, setUserSession } from "./Utils/Common";
import { Host } from './App';
import "./Responsive.css";

function Login(props) {
  var [app, setapp] = useState(true);
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);

    axios.post(`https://` + Host + `/users/signin`, { username: username.value, password: password.value }, { credentials: 'include' }).then(response => {
      window.localStorage.setItem("acessToken", response.data.ciphertext);
      window.localStorage.setItem("Name", response.data.name);
      window.localStorage.setItem("Rank", response.data.Ranked);
      window.localStorage.setItem("Stage", response.data.Stage);
      setLoading(false);
      setUserSession(response.data.token, response.
        data.user);
      props.history.push('/Admin_dashboard');
    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401) setError(error.response.data.message);
      else setError("Something went wrong. Please try again later.");
    });
  }
  return (
    <div>
      {/*<button onClick={clickHandler}></button>
      */}
      <section class="h-100 gradient-form" >
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-xl-10">
              <div class="card rounded-3 text-black">
                <div class="row g-0">
                  <div class="col-lg-6">
                    <div class="card-body p-md-5 mx-md-4">

                      <div class="text-center">
                        <img src={real_logo}
                          alt="logo" />
                        <h4 class="mt-1 mb-5 pb-1">Foam For the Future</h4>
                      </div>

                      <form>
                        <p>Please login to your account</p>

                        <div class="form-outline mb-4">
                          <input type="text" id="form2Example11" class="form-control"
                            placeholder="Phone number or email address"  {...username} />
                          <label class="form-label" for="form2Example11"  >Username</label>
                        </div>

                        <div class="form-outline mb-4">
                          <input type="password" id="form2Example22" class="form-control" {...password} />
                          <label class="form-label" for="form2Example22">Password</label>
                        </div>

                        <div class="text-center pt-1 mb-5 pb-1">
                          <button class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading}>Log
                            in</button>


                          <a class="text-muted" onClick={() => { window.confirm('Please Contact Administrator !',) }} >Forgot password?</a>
                        </div>

                        <div class="d-flex align-items-center justify-content-center pb-4">
                          <p class="mb-0 me-2">Don't have an account?</p>
                          <button type="button" class="btn btn-outline-danger" onClick={() => { window.confirm('Please Contact Administrator !',) }}>Create new</button>
                        </div>

                      </form>
                      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                    </div>
                  </div>
                  <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
                    <div id="mobileView" class="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h3 >We are more than just a company</h3>
                      <h4>We are experts in processing cold cured flexible and rigid polyurethane foams. Whether your need is for cushioning or to add detailing to a given product, polyurethane foam is lightweight,
                        cost effective and flexible in design. realform has earned an outstanding reputation for developing unique foam-based technical solutions to difficult problems.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
export default Login
import React, { useState, useEffect } from 'react'
import './Vaccation.css';
import Axios from 'axios'
import { host, Host } from '../App';


function Vaccation() {
  const [employees, setEmployees] = useState([]);
  const [editable, seteditable] = useState(false);

  // Form state
  const [employeeName, setEmployeeName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reason, setReason] = useState('');
  var [vaccation_data, setvaccation_data] = useState([]);
  var [vac, setVac] = useState(0);
  const [del, setdel] = useState(0);

  var user = (window.localStorage.getItem("Name").replace(/[{""}]/g, ""));
  var [Emp_id, setEmp_id] = useState('');
  var token = (window.localStorage.getItem("acessToken"));
  const [rank, setrank] = useState(false);
  var Ranked = (window.localStorage.getItem("Rank"));
  var Stage = (window.localStorage.getItem("Stage"));

  useEffect(() => {
    if (Ranked === '"Admin"' && Stage === '"2"') {
      setrank(true);
    }
  }, [Ranked]);

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/vaccation_data/${token}`).then((data) => {
      setvaccation_data(data.data);
    });
  }, [vac]);

  const updated_vac_data = () => {
    Axios.get(`https://` + Host + `/api/vaccation_data/${token}`).then((data) => {
      setvaccation_data(data.data);
    });
  }
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/employees_all/${token}`).then((data) => {
      setEmployees(data.data);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (employeeName.length > 0 && startDate.length > 0 && endDate.length > 0) {
      Emp_id = employees.find((x) => x.Name === employeeName).Emp_ID;
      console.log(Emp_id + employees.length);
      console.log('Form submitted:', { employeeName, Emp_id, startDate, endDate, reason });
      const vac_data = { Emp_id, employeeName, startDate, endDate, reason, user, token };
      Axios.post(`https://` + Host + `/api/add_vaccation`, vac_data)
        .then((response) => {
          // Handle the response if needed
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.alert("Please Enter all values")
    }
    setEmployeeName('');
    setStartDate('');
    setEndDate('');
    setReason('');
    setTimeout(function () {
      updated_vac_data();
    }, 3000);
  };

  const [changedLine, setChangedLine] = useState(new Set());
  const handleInputChange = (e, index, key) => {
    const { value } = e.target;
    updateDatas(index, key, value);
  };

  const updateDatas = (index, key, value) => {
    setvaccation_data(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    setChangedLine(prevRows => new Set(prevRows).add(index));
  };
  const Update = () => {
    const changedData_ = [...changedLine].map(index => {
      return vaccation_data[index];
    });
    Axios.post(`https://` + Host + `/api/vacation/Update`, { changedData: JSON.stringify(changedData_), token: token }).then((response) => {
      if (response.status === 200) {
        window.alert(response.data.message);
      }
      seteditable(false);
      setdel(del + 1);
    })
      .catch(function (error) {
        console.log(error.response);
      });
  }
  const approve = (Vaccation_ID) => {
    Axios.post(`https://` + host + `/attendancedetails/approve/${Vaccation_ID},${user},${token}`).catch(function (error) {
      console.log(error.response);
    })
    setTimeout(function () {
      updated_vac_data();
    }, 2000);
  };

  const Delete = (Vaccation_ID) => {
    Axios.delete(`https://` + host + `/attendancedetails/delete/${Vaccation_ID},${token}`).catch(function (error) {
      console.log(error.response);
    })
    setTimeout(function () {
      updated_vac_data();
    }, 2000);
  }

  return (
    <div className="vacation-form-container">
      <div class="row" id='vacation_table'>
        <div class="col-4" id='add_vacation_table'>
          <div id="add_new_vacation">
            <h2>Enter Vacation Data</h2>
            <form onSubmit={handleSubmit}>
              <label>
                Employee Name:
                <input type='text' class="form-control form-rounded" placeholder='Employee' list='employee' onChange={(e) => {
                  setEmployeeName(e.target.value)
                }} />
                <datalist id="employee">
                  <option></option>
                  {employees.map(x => {
                    return <option key={x.Emp_ID}>{x.Name}</option>
                  })}
                </datalist>
              </label>
              <label>
                Start Date:
                <input type="date" class="form-control form-rounded" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </label>
              <label>
                End Date:
                <input type="date" class="form-control form-rounded" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </label>
              <label>
                Reason:
                <textarea value={reason} class="form-control form-rounded" onChange={(e) => setReason(e.target.value)} />
              </label>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
        <div class="col-1"></div>
        <div class="col-7" id='add_vacation_table'>
          <h2>Active Vacation</h2>{editable ? <button className='btn btn-success' onClick={Update} >Update</button> : null}<button className='btn btn-primary' onClick={() => { seteditable(!editable) }} >Edit</button>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Reason</th>
                <th>Registered By</th>
                <th>Approval Status</th>
                {rank ? <th>Approve</th> : null}
                {rank ? <th>Delete</th> : null}
              </tr>
            </thead>
            {vaccation_data.map((val, index) => {
              return (
                <tbody>
                  <tr key={index}>
                    <td>{val.Employee_Name}</td>
                    <td>
                      {editable ? (
                        <input
                          type="date"
                          className="form-control form-rounded"
                          value={val.Start_Date.slice(0, 10)}
                          onChange={(e) => handleInputChange(e, index, 'Start_Date')}
                        />
                      ) : (
                        val.Start_Date.slice(0, 10)
                      )}
                    </td>
                    <td>
                      {editable ? (
                        <input
                          type="date"
                          className="form-control form-rounded"
                          value={val.End_Date.slice(0, 10)}
                          onChange={(e) => handleInputChange(e, index, 'End_Date')}
                        />
                      ) : (
                        val.End_Date.slice(0, 10)
                      )}
                    </td>
                    <td>
                      {editable ? (
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.Reason}
                          onChange={(e) => handleInputChange(e, index, 'Reason')}
                        />
                      ) : (
                        val.Reason
                      )}
                    </td>

                    <td>{val.User}</td>
                    <td>{(val.Approval_status)}</td>
                    {rank ? <td>{(val.Approval_status) == 'Pending' && (<button class="btn btn-primary" onClick={() => { window.confirm('Are you sure you want to Approve?',) && approve(val.Vaccation_ID) }}> Approve</button>
                    )}
                    </td> : null}
                    {rank ? <td><button class="btn btn-danger" onClick={() => { window.confirm('Are you sure you want to delete this Entry?',) && Delete(val.Vaccation_ID) }}> Delete</button></td> : null}
                  </tr>
                </tbody>
              )
            })}
            <thead>
            </thead>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Vaccation
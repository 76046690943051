import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import Clipboard from 'clipboard';
import real_l from "./real_logo.png";
import { Host } from '../App';
import './Pivot.css';

function DynamicTable({ postList }) {
  var token = (window.localStorage.getItem("acessToken"));
  var User = JSON.parse(window.localStorage.getItem("Name"));
  const [date, setDate] = useState(new Date().toISOString());
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();
  const headers = Object.keys(postList[0]);
  var [partdetailedList, setPartdetailedList] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [showUpholstery, setshowUpholstery] = useState(false);

  const Targeted_part_data = (PART_NO) => {
    setShowDiv(true);
    Axios.get(`https://` + Host + `/pivot/getpartdetails/${PART_NO},${token}`).then((data) => {
      setPartdetailedList(data.data)
    });
  }
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  }
  const toggleUpholstery = () => {
    setshowUpholstery(!showUpholstery);
  }
  const handleCopy = () => {

    const dataToCopy = document.querySelector('#tableData');

    const clipboard = new Clipboard('.copyBtn');

    // Get the data to copy
    //const dataToCopy = document.getElementById('myTable');

    // Create a new table element
    const newTable = document.createElement('table');

    // Add a border to the table
    newTable.style.border = '1px solid black';

    // Loop through each row in the data to copy
    dataToCopy.querySelectorAll('tr').forEach(row => {

      // Create a new row element
      const newRow = document.createElement('tr');

      // Loop through each cell in the row
      row.querySelectorAll('td').forEach(cell => {

        // Create a new cell element
        const newCell = document.createElement('td');

        // Set the content of the cell to the same as the original cell
        newCell.innerText = cell.innerText;

        // Add the new cell to the new row
        newRow.appendChild(newCell);
      });

      // Add the new row to the new table
      newTable.appendChild(newRow);
    });

    // Copy the new table to the clipboard




    navigator.clipboard.writeText(newTable.innerText)
      .then(() => {
        console.log(newTable);
        window.alert('Copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy to clipboard:', error);
      });

    clipboard.on('success', () => {
      console.log('Copied to clipboard!');
    });
    clipboard.on('error', () => {
      console.log('Failed to copy to clipboard!');
    });
    clipboard.on('success', function (e) {
      e.clearSelection();
    });
    clipboard.onClick = function (event) {
      event.preventDefault();
    };
    if (clipboard && clipboard.setText) {
      clipboard.setText(dataToCopy.innerText);
    } else {
      console.log('Clipboard not defined or setText method not available.');
    }
    clipboard.destroy();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    return `${day} ${month}`;
  };

  // Example usage
  const formatedDate = formatDate('2023-06-28');




  const handlePrint = () => {
    const table = document.querySelector('#alldata');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          table tr:last-child td {
            font-weight: bold;
            background-color: lightgrey; /* Add light grey background color */
          }
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <table class="table table-striped">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };


  const uniqueCustomers = [...new Set(postList.map((post) => (post.CUSTOMER)))];
  var cust_length = uniqueCustomers.length;
  const totalSum = postList.reduce((acc, post) => acc + post.Total, 0);
  const zeroColumns = headers.filter((header) => {
    const values = postList.map((post) => post[header]);
    return values.every((value) => value === 0);
  });

  // Remove the zero columns from the headers and postList arrays
  const filteredHeaders = headers.filter((header) => !zeroColumns.includes(header));
  const filteredPostList = postList.map((post) => {
    const filteredPost = {};
    Object.entries(post).forEach(([key, value]) => {
      if (!zeroColumns.includes(key)) {
        filteredPost[key] = value;
      }
    });
    return filteredPost;
  });
  const groupByDate = (data) => {
    return data.reduce((acc, item) => {
      const date = item.PO_SHIP_DATE || 'No Date';
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };
  const groupedPosts = groupByDate(postList);

  return (<div>
    <center>
      <button className="btn btn-primary" onClick={handlePrint}>Print Table</button>
      <button className="btn btn-light" onClick={toggleUpholstery}>{showUpholstery ? 'Hide Fabric' : 'Show Fabric'}</button>
      <div id="alldata" class='pivot_table_section'>
        {uniqueCustomers.map((customer) => (
          <p key={customer}>
            <center>
              <h2 id='title'>{customer}</h2>
            </center>
            <table id="tableData" className="table table-bordered">
              <thead>
                <tr>
                  {filteredHeaders.map((header) => (
                    header === 'PO_NO' || header === 'PART_NO' || header === 'PO_SHIP_DATE' || header === 'CUSTOMER' || header === 'Part_attached' || header === 'PART_DESCRIPTION' || header === 'FABRIC_STATUS' || header === 'FABRIC_STYLE' || header === 'FABRIC_COLOR' || header === 'ORIENTATION' || header === 'BASE_COLOR' || header === 'FABRIC_INSIDE_BACK' || header === 'FABRIC_OUTSIDE_BACK' || header === 'FABRIC_SEAT' || header === 'PIPING' || header === 'FABRIC_BACK' || header === 'WOOD_OUTSIDE_BACK' ? null : (
                      <th key={header} className='header_table'>{header}</th>
                    )
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredPostList.map((post) => (
                  (post.CUSTOMER === customer && (
                    <tr key={post.PO_NO + post.PART_NO}>
                      {filteredHeaders.map((header) => (
                        header === 'PO_NO' || header === 'PART_NO' || header === 'PO_SHIP_DATE' || header === 'CUSTOMER' || header === 'Part_attached' || header === 'PART_DESCRIPTION' || header === 'FABRIC_STATUS' || header === 'FABRIC_STYLE' || header === 'FABRIC_COLOR' || header === 'ORIENTATION' || header === 'BASE_COLOR' || header === 'FABRIC_INSIDE_BACK' || header === 'FABRIC_OUTSIDE_BACK' || header === 'FABRIC_SEAT' || header === 'PIPING' || header === 'FABRIC_BACK' || header === 'WOOD_OUTSIDE_BACK' ? null : (
                          <td key={`${post.PO_NO}_${header}`}>
                            {header === 'Description' ? (
                              <span className='tooltip-container' id='pivotj'>
                                <p className='Description' >
                                  <a href="#" className='description_link' style={{ "textDecoration": "none" }} onClick={() => { Targeted_part_data(post.PART_NO) }}>{(post.Description)}</a></p>

                                {showUpholstery ? (
                                  <span className='tooltiptext'>
                                    <div>
                                      {(post.FABRIC_STATUS && post.FABRIC_STATUS.length > 1) && (
                                        <div><strong>FABRIC STATUS: </strong>{post.FABRIC_STATUS}</div>
                                      )}
                                      {(post.FABRIC_STYLE && post.FABRIC_STYLE.length > 1) && (
                                        <div><strong>FABRIC BODY: </strong>{post.FABRIC_STYLE}</div>
                                      )}
                                      {(post.FABRIC_COLOR && post.FABRIC_COLOR.length > 1) && (
                                        <div><strong>FABRIC: </strong>{post.FABRIC_COLOR}</div>
                                      )}
                                      {(post.ORIENTATION && post.ORIENTATION.length > 1) && (
                                        <div><strong>ORIENTATION: </strong>{post.ORIENTATION}</div>
                                      )}
                                      {(post.BASE_COLOR && post.BASE_COLOR.length > 1) && (
                                        <div><strong>BASE COLOR: </strong>{post.BASE_COLOR}</div>
                                      )}
                                      {(post.FABRIC_INSIDE_BACK && post.FABRIC_INSIDE_BACK.length > 1) && (
                                        <div><strong>FABRIC INSIDE BACK: </strong>{post.FABRIC_INSIDE_BACK}</div>
                                      )}
                                      {(post.FABRIC_OUTSIDE_BACK && post.FABRIC_OUTSIDE_BACK.length > 1) && (
                                        <div><strong>FABRIC OUTSIDE BACK: </strong>{post.FABRIC_OUTSIDE_BACK}</div>
                                      )}
                                      {(post.FABRIC_SEAT && post.FABRIC_SEAT.length > 1) && (
                                        <div><strong>FABRIC SEAT: </strong>{post.FABRIC_SEAT}</div>
                                      )}
                                      {(post.PIPING && post.PIPING.length > 1) && (
                                        <div><strong>PIPING: </strong>{post.PIPING}</div>
                                      )}
                                      {(post.FABRIC_BACK && post.FABRIC_BACK.length > 1) && (
                                        <div><strong>FABRIC BACK: </strong>{post.FABRIC_BACK}</div>
                                      )}
                                      {(post.WOOD_OUTSIDE_BACK && post.WOOD_OUTSIDE_BACK.length > 1) && (
                                        <div><strong>WOOD OUTSIDE BACK: </strong>{post.WOOD_OUTSIDE_BACK}</div>
                                      )}
                                      {(post.PO_NO && post.PO_NO.length > 1) && (
                                        <div><strong>PO NO: </strong>{post.PO_NO}</div>
                                      )}
                                      {(post.PO_SHIP_DATE && post.PO_SHIP_DATE.length > 1) && (
                                        <div><strong>PO SHIP DATE: </strong>{(post.PO_SHIP_DATE).slice(0, 10)}</div>
                                      )}
                                    </div>
                                  </span>
                                ) : null}

                              </span>
                            ) : (
                              header === 'PO_SHIP_DATE' ? (
                                <span className='tooltip-container'>
                                  {post[header].slice(0, 10)}
                                  <span className='tooltiptext'>
                                    {(post.FABRIC_STATUS && post.FABRIC_STATUS.length > 1) && (
                                      <div><strong>FABRIC STATUS: </strong>{post.FABRIC_STATUS}</div>
                                    )}
                                    {(post.FABRIC_STYLE && post.FABRIC_STYLE.length > 1) && (
                                      <div><strong>FABRIC STYLE: </strong>{post.FABRIC_STYLE}</div>
                                    )}
                                    {(post.FABRIC_COLOR && post.FABRIC_COLOR.length > 1) && (
                                      <div><strong>FABRIC COLOR: </strong>{post.FABRIC_COLOR}</div>
                                    )}
                                    {(post.ORIENTATION && post.ORIENTATION.length > 1) && (
                                      <div><strong>ORIENTATION: </strong>{post.ORIENTATION}</div>
                                    )}
                                    {(post.BASE_COLOR && post.BASE_COLOR.length > 1) && (
                                      <div><strong>BASE COLOR: </strong>{post.BASE_COLOR}</div>
                                    )}
                                    {(post.FABRIC_INSIDE_BACK && post.FABRIC_INSIDE_BACK.length > 1) && (
                                      <div><strong>FABRIC INSIDE BACK: </strong>{post.FABRIC_INSIDE_BACK}</div>
                                    )}
                                    {(post.FABRIC_OUTSIDE_BACK && post.FABRIC_OUTSIDE_BACK.length > 1) && (
                                      <div><strong>FABRIC OUTSIDE BACK: </strong>{post.FABRIC_OUTSIDE_BACK}</div>
                                    )}
                                    {(post.FABRIC_SEAT && post.FABRIC_SEAT.length > 1) && (
                                      <div><strong>FABRIC SEAT: </strong>{post.FABRIC_SEAT}</div>
                                    )}
                                    {(post.PIPING && post.PIPING.length > 1) && (
                                      <div><strong>PIPING: </strong>{post.PIPING}</div>
                                    )}
                                    {(post.FABRIC_BACK && post.FABRIC_BACK.length > 1) && (
                                      <div><strong>FABRIC BACK: </strong>{post.FABRIC_BACK}</div>
                                    )}
                                    {(post.WOOD_OUTSIDE_BACK && post.WOOD_OUTSIDE_BACK.length > 1) && (
                                      <div><strong>WOOD OUTSIDE BACK: </strong>{post.WOOD_OUTSIDE_BACK}</div>
                                    )}
                                    {(post.SALES_ORDER_NO && post.SALES_ORDER_NO.length > 1) && (
                                      <div><strong>SALES ORDER NO: </strong>{post.SALES_ORDER_NO}</div>
                                    )}
                                  </span>
                                </span>
                              ) : (
                                post[header] === 0 ? '' : post[header]
                              )
                            )}
                          </td>
                        )
                      ))}
                    </tr>
                  ))
                ))}
                <tr className="Total">
                  {filteredHeaders.map((header) => (
                    header === 'PO_NO' || header === 'PART_NO' || header === 'PO_SHIP_DATE' || header === 'CUSTOMER' || header === 'Part_attached' || header === 'PART_DESCRIPTION' || header === 'FABRIC_STATUS' || header === 'FABRIC_STYLE' || header === 'FABRIC_COLOR' || header === 'ORIENTATION' || header === 'BASE_COLOR' || header === 'FABRIC_INSIDE_BACK' || header === 'FABRIC_OUTSIDE_BACK' || header === 'FABRIC_SEAT' || header === 'PIPING' || header === 'FABRIC_BACK' || header === 'WOOD_OUTSIDE_BACK' ? null : (
                      <td key={`total_${header}`}>
                        {header === 'Description' ? 'Total' : (
                          filteredPostList
                            .filter((post) => post.CUSTOMER === customer)
                            .reduce((sum, post) => sum + parseFloat(post[header]), 0)
                        )}
                      </td>
                    )
                  ))}
                </tr>
              </tbody>
              {showDiv ? <div className='overlay'>
                <div className='box'>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>PO NO</th>
                        <th>PART NO</th>
                        <th>PART DESCRIPTION</th>
                        <th>PO SHIP DATE</th>
                        <th>REMAINED PARTS</th>
                        <th>COMMENTS</th>
                      </tr>
                    </thead>
                    {partdetailedList.map((val, key) => {
                      return (
                        <tbody>
                          <tr>
                            <td>{val.PO_NO}</td>
                            <td>{val.PART_NO}</td>
                            <td>{val.PART_DESCRIPTION}</td>
                            <td>{(val.PO_SHIP_DATE).slice(0, 10)}</td>
                            <td>{val.Remained_parts}</td>
                            <td>{val.COMMENTS}</td>
                          </tr>
                        </tbody>
                      )
                    })}
                  </table>
                  <button type='button' className="form-control form-rounded" onClick={toggleDiv}>Close</button>
                </div>
              </div> : null}
            </table></p>
        ))}
      </div>


    </center>
  </div>
  );
}

export default DynamicTable;
<script src="dist/clipboard.min.js"></script>
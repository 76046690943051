import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import "../ShippingData.css";
import "./Add_Part_Dataset.css";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Host } from '../App';
import { loadCustomers } from '../Dashboard';
import real_l from "../real_logo.png";
import App from '../App';

function Upholstery_Part_Dataset() {

  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();

  //search for data
  const [PartNo, setPartno] = useState({});
  const [PartDesc, setPartDesc] = useState({});
  const [InternalPart, setInternalPart] = useState({});
  const [Customers, setCustomers] = useState({});
  const [Category, setCategory] = useState({});
  const [Type, setType] = useState({});
  const [editable, seteditable] = useState(false);
  const [Updateable, setUpdateable] = useState(false);
  const [del, setdel] = useState(0);
  const [partDataset, setpartDataset] = useState([]);
  const [allDataset, setallDataset] = useState([]);
  const [Part_No, setPartNo] = useState("");
  const [Part_Description, setPart_description] = useState("");
  const [Customer, setCustomer] = useState("");
  const [CATEGORY, setcategory] = useState("");
  const [Unit_Price, setunitprice] = useState("");
  const [CURRENCY, setcurrency] = useState("");
  const [SIZE, setsize] = useState("");
  const [BASE_STYLE_TUHOY, setbasestyletoughy] = useState("");
  const [MOLDING, setMoulding] = useState("");
  const [WOODSHOP, setWoodshop] = useState("");
  const [UPHOLSTERY, setUpholstery] = useState("");
  const [BUY_AND_SELL, setbuyandsell] = useState("");
  var userName = (window.localStorage.getItem("Name").replace(/[{""}]/g, ""));
  const [rank, setrank] = useState(false);
  var User = JSON.parse(window.localStorage.getItem("Name"));

  var Ranked = (window.localStorage.getItem("Rank"));
  var Stage = (window.localStorage.getItem("Stage"));

  var token = (window.localStorage.getItem("acessToken"));

  const [data, setData] = useState([
    { Part_No: "", Part_internalNo: "", Part_Description: "", Customer: "", Size: "", Unit_Price: "", BASE_STYLE_TUOHY: "", Type: "", Woodshop: "", Molding: "", Gluing: "", Fabric_Cutting: "", Single_Stitching: "", Top_Stitching: "", Upholstery: "", Assembly: "", Finishing: "", Packing: "", size_of_bag: "", Box: "", Bo: "", WOODSHOP2: "", BOM: "" },
  ]);
  const [filter, setFilter] = useState(
    [{ Emp_ID: "", EmployeeName: "", ContactNumber: "", Department: "", Source: "", Position: "", Status: "", token: token },
    ]);
  const updateFilter = (e, key) => {
    const { value } = e.target;
    setFilter(prevData => {
      const newData = [...prevData];
      newData[0][key] = value;
      return newData;
    });
  };


  const partfilters = {
    PartNo: PartNo,
    PartDesc: PartDesc,
    Customers: Customers,
    Category: Category,
    token: token,
    InternalPart: InternalPart,
    Type: Type
  }
  useEffect(() => {
    if (Ranked === '"Admin"' && Stage === '"2"') {
      setrank(true);
    }
  }, [Ranked]);
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/upholstery/partdataset`, { params: partfilters }).then((data) => {
      setpartDataset(data.data)
    });
  }, [PartNo, PartDesc, Customers, Category, del, InternalPart, Type])
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/upholstery/alldataset`, { params: partfilters }).then((data) => {
      setallDataset(data.data)
    });
  }, [])
  const submitValue = (e, index) => {
    const newData = [...data];
    newData[index].Part_No = e.target.value;
    if (allDataset.find((x) => x.Part_No === e.target.value)) {
      newData[index].Customer = (allDataset.find((x) => x.Part_No === e.target.value).Customer);

      newData[index].Part_Description = allDataset.find((x) => x.Part_No === e.target.value).Part_Description;
      setData(newData);
    }
  }
  //

  const [changedLine, setChangedLine] = useState(new Set());
  const handleInputChange = (e, index, key) => {
    const { value } = e.target;
    updateDatas(index, key, value);
  };

  const updateDatas = (index, key, value) => {
    setpartDataset(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    setChangedLine(prevRows => new Set(prevRows).add(index));
  };

  const submitpart = () => {

    Axios.post(`https://` + Host + `/api/add/upholsterypartDataset`, { Data: data, token: token }).then((response) => {
      console.log(response.data);
      if (response.status === 500) {
        window.alert(response.data.message);
      }
      if (response.status === 200) {
        setData([{ Part_No: "", Part_internalNo: "", Part_Description: "", Customer: "", Type: "" },]);
        window.alert(response.data.message);
      }
      seteditable(false);
      setdel(del + 1);
    })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  const Update = () => {
    const changedData_ = [...changedLine].map(index => {
      return partDataset[index];

    });
    console.log(changedData_);

    Axios.post(`https://` + Host + `/api/upholsterypartDataset/Update`, { changedData: JSON.stringify(changedData_), token: token }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        window.alert(response.data.message);
      }
      seteditable(false);
      setdel(del + 1);
      setUpdateable(false);
    })
      .catch(function (error) {
        console.log(error.response);
      });

  };


  const submitpartdatasetdata = () => {
    console.log(Part_No + Part_Description + Customer + CATEGORY + Unit_Price + CURRENCY + SIZE + BASE_STYLE_TUHOY + MOLDING + WOODSHOP + UPHOLSTERY + BUY_AND_SELL);
    Axios.post(`https://` + Host + `/api/submitpartdatasetdata`, {
      Part_No: Part_No,
      Part_Description: Part_Description,
      Customer: Customer,
      CATEGORY: CATEGORY,
      Unit_Price: Unit_Price,
      CURRENCY: CURRENCY,
      SIZE: SIZE,
      BASE_STYLE_TUHOY: BASE_STYLE_TUHOY,
      MOLDING: MOLDING,
      WOODSHOP: WOODSHOP,
      UPHOLSTERY: UPHOLSTERY,
      BUY_AND_SELL: BUY_AND_SELL,
      token
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        window.alert(response.data.message);
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }

  const handlePrint = () => {

    const table = document.querySelector('#alldata_upholsterydataset');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <table class="table table-striped">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };

  return (
    <div className="MainPage_data">
      <div class="Container">
        <br></br>
        <div>
          <div>
            <h2 style={{ padding: 10 }}>Upholstery Part Dataset</h2>
            <br />
            {editable ?
              <div class="add_section" id='add_section_upholstery'>
                <center>
                  <div class="row" id="Part_dataset_add" >
                    {data.map((line, index) => (
                      <tbody>
                        <tr>
                          <td style={{ padding: 10 }}>
                            <label>Part No</label>
                            <input
                              type="text"
                              name="Part_No"
                              class="form-control form-rounded"
                              list='AllPartNo'
                              onBlur={(e) => submitValue(e, index)}

                            />
                            <datalist id="AllPartNo">
                              <option></option>
                              {allDataset.map(x => {
                                return <option>{x.Part_No}</option>
                              })}
                            </datalist>
                          </td>
                          <td style={{ padding: 10 }}>
                            <label>Part Description</label>
                            <input
                              type="text"
                              name="Part_Description"
                              class="form-control form-rounded"
                              Value={data[index].Part_Description}
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Part_Description = e.target.value;
                                setData(newData);
                              }}

                            />
                          </td>
                          <td style={{ padding: 10 }}>
                            <label>Customer</label>
                            <input
                              type="text"
                              name="Customer"
                              class="form-control form-rounded"
                              Value={data[index].Customer}
                              onBlur={(e) => {
                                setCustomer(e.target.value);
                              }}
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Customer = e.target.value;

                                setData(newData);
                              }}
                            />
                          </td>


                          <td style={{ padding: 10 }}>
                            <label>Size</label>
                            <input
                              type="text"
                              name="Size"
                              class="form-control form-rounded"
                              list='Size'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Size = e.target.value;
                                setData(newData);
                              }}
                            />
                            <div class="scrollbar">
                              <datalist id="Size">
                                <option></option>
                                {partDataset.map((x) => {
                                  return <option>{x.Size}</option>;
                                })}
                              </datalist>
                            </div>
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Unit Price</label>
                            <input
                              type="text"
                              name="Unit_Price"
                              class="form-control form-rounded"
                              list='UnitPrice'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Unit_Price = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Base Style Tuohy</label>
                            <input
                              type="text"
                              name="BASE_STYLE_TUOHY"
                              class="form-control form-rounded"
                              list='BaseStyleTuohy'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].BASE_STYLE_TUOHY = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Type</label>
                            <input
                              type="text"
                              name="Type"
                              class="form-control form-rounded"
                              list='Type'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Type = e.target.value;
                                setData(newData);
                              }}
                            />
                            <div class="scrollbar">
                              <datalist id="Type">
                                <option></option>
                                {partDataset.map((x) => {
                                  return <option>{x.Type}</option>;
                                })}
                              </datalist>
                            </div>
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Woodshop</label>
                            <input
                              type="text"
                              name="Woodshop"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Woodshop = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 10 }}>
                            <label>Molding</label>
                            <input
                              type="text"
                              name="Molding"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Molding = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Gluing</label>
                            <input
                              type="text"
                              name="Gluing"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Gluing = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Fabric Cutting</label>
                            <input
                              type="text"
                              name="Fabric_Cutting"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Fabric_Cutting = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Single Stitching</label>
                            <input
                              type="text"
                              name="Single_Stitching"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Single_Stitching = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Top Stitching</label>
                            <input
                              type="text"
                              name="Top_Stitching"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Top_Stitching = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Upholstery</label>
                            <input
                              type="text"
                              name="Upholstery"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Upholstery = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Assembly</label>
                            <input
                              type="text"
                              name="Assembly"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Assembly = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Finishing</label>
                            <input
                              type="text"
                              name="Finishing"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Finishing = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 10 }}>
                            <label>Packing</label>
                            <input
                              type="text"
                              name="Packing"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Packing = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Size of Bag</label>
                            <input
                              type="text"
                              name="size_of_bag"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].size_of_bag = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Box</label>
                            <input
                              type="text"
                              name="Box"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Box = e.target.value;
                                setData(newData);
                              }}
                            />
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Bo</label>
                            <input
                              type="text"
                              name="Bo"
                              class="form-control form-rounded"
                              list='YesNo'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].Bo = e.target.value;
                                setData(newData);
                              }}
                            />
                            <div class="scrollbar">
                              <datalist id="YesNo">
                                <option></option>
                                <option>YES</option>
                                <option>NO</option>

                              </datalist>
                            </div>
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>Woodshop2</label>
                            <input
                              type="text"
                              name="WOODSHOP2"
                              class="form-control form-rounded"
                              list='Woodshop2'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].WOODSHOP2 = e.target.value;
                                setData(newData);
                              }}
                            />
                            <div class="scrollbar">
                              <datalist id="Woodshop2">
                                <option></option>
                                {partDataset.map((x) => {
                                  return <option>{x.WOODSHOP2}</option>;
                                })}
                              </datalist>
                            </div>
                          </td>

                          <td style={{ padding: 10 }}>
                            <label>BOM</label>
                            <input
                              type="text"
                              name="BOM"
                              class="form-control form-rounded"
                              list='BOM'
                              onChange={(e) => {
                                const newData = [...data];
                                newData[index].BOM = e.target.value;
                                setData(newData);
                              }}
                            />
                            <div class="scrollbar">
                              <datalist id="BOM">
                                <option></option>
                                {partDataset.map((x) => {
                                  return <option>{x.BOM}</option>;
                                })}
                              </datalist>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                    <div>
                      <input
                        type="button"
                        class="btn btn-success"
                        defaultValue="Submit"
                        style={{ width: "auto" }}
                        name="Submit"
                        onClick={submitpart}
                      />
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                </center>
              </div> : null}
            <div class="row" id='Upholstery_filter_row'>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Part No' onChange={(e) => {
                  setPartno(e.target.value)
                }} list='PartNo' />
                <datalist id="PartNo">
                  <option></option>
                  {partDataset.map(x => {
                    return <option>{x.Part_No}</option>
                  })}
                </datalist>
              </div>
              <div class='col'>
                <div class="row">
                  <input type='text' class="form-control form-rounded" placeholder='Part Description' list='PartDescription' onChange={(e) => {
                    setPartDesc(e.target.value)
                  }} />
                  <datalist id="PartDescription">
                    <option></option>
                    {partDataset.map(x => {
                      return <option>{x.Part_Description}</option>
                    })}
                  </datalist>
                </div>
                <div class="row">
                  <select
                    type="select"
                    class="form-control form-rounded"
                    placeholder="Section"
                    name="area"
                    onChange={(e) => {
                      setInternalPart(e.target.value)
                    }}
                  >
                    <option value="">All</option>
                    <option value="Woodshop">Woodshop</option>
                    <option value="Molding">Molding</option>
                    <option value="Gluing">Gluing</option>
                    <option value="Fabric_Cutting">Fabric Cutting</option>
                    <option value="Single_Stitching">Single Stitching</option>
                    <option value="Top_Stitching">Top Stitching</option>
                    <option value="Upholstery">Upholstery</option>
                    <option value="Assembly">Assembly</option>
                    <option value="Finishing">Finishing</option>
                    <option value="Packing">Packing</option>
                    <option value="size_of_bag">Size of Bag</option>
                    <option value="Box">Box</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customer' onChange={(e) => {
                  setCustomers(e.target.value)
                }} />
                <datalist id="Customer">
                  <option></option>
                  {[...new Set(partDataset.map(x => x.Customer))].map((Customer, index) => (
                    <option key={index}>{Customer}</option>
                  ))}
                </datalist>
              </div>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Type' list='Type' onChange={(e) => {
                  setType(e.target.value)
                }} />
                <datalist id="Type">
                  <option></option>
                  {[...new Set(partDataset.map(x => x.Type))].map((Type, index) => (
                    <option key={index}>{Type}</option>
                  ))}
                </datalist>
              </div>
              <div class="col">
                <button className='btn btn-secondary' onClick={handlePrint} >Print</button>
                <button className='btn btn-primary' onClick={() => { seteditable(!editable) }} >Add Internal No</button>
                <button className='btn btn-success' onClick={() => { setUpdateable(!Updateable) }} >Edit</button>
              </div>
            </div >
            <br />
            <div class="datatable-wide_part">
              <table class="table table-bordered table-striped" id="alldata_upholsterydataset">
                <thead>
                  <tr>
                    <th>PART NO</th>
                    <th>PART DESCRIPTION</th>
                    <th>CUSTOMER</th>
                    <th>SIZE</th>
                    <th>UNIT PRICE</th>
                    <th>BASE STYLE TUOHY</th>
                    <th>TYPE</th>
                    <th>WOODSHOP</th>
                    <th>MOLDING</th>
                    <th>GLUING</th>
                    <th>FABRIC CUTTING</th>
                    <th>SINGLE STITCHING</th>
                    <th>TOP STITCHING</th>
                    <th>UPHOLSTERY</th>
                    <th>ASSEMBLY</th>
                    <th>FINISHING</th>
                    <th>PACKING</th>
                    <th>SIZE OF BAG</th>
                    <th>BOX</th>
                    <th>BO</th>
                    <th>WOODSHOP2</th>
                    <th>BOM</th>
                  </tr>
                </thead>
                <tbody >
                  {partDataset.map((val, index) => {
                    return (
                      <tr>
                        <td>{val.Part_No}</td>
                        <td>{val.Part_Description}</td>
                        <td>{val.Customer}</td>
                        <td>
                          {Updateable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.Size}
                              onChange={(e) => handleInputChange(e, index, 'Size')}
                            />
                            : val.Size
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.Unit_Price}
                              onChange={(e) => handleInputChange(e, index, 'Unit_Price')}
                            />
                            : val.Unit_Price
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.BASE_STYLE_TUOHY}
                              onChange={(e) => handleInputChange(e, index, 'BASE_STYLE_TUOHY')}
                            />
                            : val.BASE_STYLE_TUOHY
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.Type}
                              onChange={(e) => handleInputChange(e, index, 'Type')}
                            />
                            : val.Type
                          }
                        </td>
                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Woodshop}
                              onChange={(e) => handleInputChange(e, index, 'Woodshop')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Woodshop
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Molding}
                              onChange={(e) => handleInputChange(e, index, 'Molding')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Molding
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Gluing}
                              onChange={(e) => handleInputChange(e, index, 'Gluing')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Gluing
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Fabric_Cutting}
                              onChange={(e) => handleInputChange(e, index, 'Fabric_Cutting')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Fabric_Cutting
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Single_Stitching}
                              onChange={(e) => handleInputChange(e, index, 'Single_Stitching')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Single_Stitching
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Top_Stitching}
                              onChange={(e) => handleInputChange(e, index, 'Top_Stitching')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Top_Stitching
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Upholstery}
                              onChange={(e) => handleInputChange(e, index, 'Upholstery')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Upholstery
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Assembly}
                              onChange={(e) => handleInputChange(e, index, 'Assembly')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Assembly
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Finishing}
                              onChange={(e) => handleInputChange(e, index, 'Finishing')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Finishing
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Packing}
                              onChange={(e) => handleInputChange(e, index, 'Packing')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Packing
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.size_of_bag}
                              onChange={(e) => handleInputChange(e, index, 'size_of_bag')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.size_of_bag
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Box}
                              onChange={(e) => handleInputChange(e, index, 'Box')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Box
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <select
                              className="form-control form-rounded"
                              value={val.Bo}
                              onChange={(e) => handleInputChange(e, index, 'Bo')}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            : val.Bo
                          }
                        </td>


                        <td>
                          {Updateable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.WOODSHOP2}
                              onChange={(e) => handleInputChange(e, index, 'WOODSHOP2')}
                            />
                            : val.WOODSHOP2
                          }
                        </td>

                        <td>
                          {Updateable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.BOM}
                              onChange={(e) => handleInputChange(e, index, 'BOM')}
                            />
                            : val.BOM
                          }
                        </td>
                        <td><button class="btn btn-success" onClick={() => { Update((val.Part_No)) }}>Update</button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
    </div >
  )
}

export default Upholstery_Part_Dataset;
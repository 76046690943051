import React, { useState, useRef, useEffect } from 'react';
import './Notes.css'
import Axios from 'axios'
import { Host } from '../App';
import attendance_image from './6274.jpg';
import { Line } from '@react-pdf/renderer';
import { Link } from '@mui/material';
var User = window.localStorage.getItem("Name");

function AutoScrollBox(props) {
  const USER = "If you’d like to ask users to submit a comment on a form, then a single-line text field won’t work. Users could submit a comment via a text input field, but most of their answers will be hidden. Instead, you can create a multi-line text field using the HTML element.";
  const [announcements, setAnnouncements] = useState([USER]);
  const [Date1, setDate1] = useState(new Date().toISOString().slice(0, 10));
  var [oldNews, setOldNews] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [showMidblock, setShowMidblock] = useState(1);
  var refresh = 0;
  var token = (window.localStorage.getItem("acessToken"));

  const queryParams = {
    start_date: Date1,
    token: token
  };

  useEffect(() => {
    Axios.get(`https://${Host}/getNews`, { params: queryParams }).then((data) => {
      if (data.data.length > 0) {
        oldNews = data.data;
        setOldNews(data.data);
        setShowDiv(true);
      } else if (data.data.length <= 0) {
        oldNews = "No News Received";
      }
    });
  }, [showMidblock])
  const handlePublish = () => {
    const textarea = document.getElementById('exampleFormControlTextarea6');
    const Department = document.getElementById('department');
    const Label = document.getElementById('flag');
    const newDepartment = Department.value;
    const newLabel = Label.value;
    const newAnnouncement = textarea.value.trim();
    Axios.post(`https://${Host}/addNews`, {
      User: User.replace(/[{""}]/g, ""),
      Date: Date1,
      Message: newAnnouncement,
      Department: newDepartment,
      Type: newLabel
    });
    setShowMidblock(prevShowMidblock => prevShowMidblock + 1);
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };
  return (
    <div>
      <div id='announcement' className="form-group shadow-textarea">
        <div className="announcement-box">
          {showDiv ? (
            oldNews.map((news) => (
              <div className='each_news' key={news.ID}>
                <p>{news.Message}</p>
                <p class="news_user"><b>{news.User}</b><br /><p>{new Date(news.Date).toISOString().slice(0, 10)}</p></p>
                <p class="news_details">{news.Type}<br /><p><b>{news.Department}</b></p></p>

                <br />

              </div>
            ))
          ) : (
            <p>No news available</p>
          )}
        </div>

        <br />

        <label id='announcement_label' htmlFor="exampleFormControlTextarea6">Enter Announcements</label>
        <textarea className="form-control z-depth-1" id="exampleFormControlTextarea6" rows="3" placeholder="Write something here..."></textarea>
        <tr>
          <td>
            <input
              type="select"
              className="form-control form-rounded"
              placeholder="Department"
              list='departments'
              id="department"
            />
            <datalist id='departments'>
              <option>PRODUCTION</option>
              <option>MAINTAINENANCE</option>
              <option>MANAGEMENT</option>
              <option>WOODSHOP</option>
              <option>UPHOLSTERY</option>
              <option>LOGISTICS</option>
              <option>GENERAL</option>
              <option>OTHER</option>
            </datalist>
          </td>
          <td>
            <input
              type="select"
              className="form-control form-rounded"
              placeholder="Label"
              list='Flag'
              id='flag'
            />
            <datalist id='Flag'>
              <option>High Important</option>
              <option>Low Important</option>
              <option>Warning</option>
              <option>Urgent</option>
              <option>Informative</option>
            </datalist>
          </td>
        </tr>
        <button class="btn btn-primary" id="publish" onClick={handlePublish}>Publish</button>
      </div >
    </div>
  );
}


export default AutoScrollBox;
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { host } from '../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

import "../Pages/MainPage.css";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { Button, Menu, Dropdown, Row } from 'antd';
import { Link } from "react-router-dom";
function BOM_entry(props) {
  var token = (window.localStorage.getItem("acessToken"));
  const user = JSON.parse(window.localStorage.getItem("Name"));
  var [Date1, setDate1] = useState(new Date());
  const [Date2, setDate2] = useState(Date1.toISOString().slice(0, 10));
  const [part_dataset, setpart_dataset] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  var length = 0;

  const [data, setData] = useState([
    { Part_No: '', Part_Description_from_part_dataset: '', Customer: '', Item_No: '', DEPARTMENT: '', Internal_Part_No: '', Comment: '', Item_Description: '', Supplier: '', Purchase_Unit: '', Revised_Amount: '', Qty_included_in_the_cost: '', Price: '', Currency: '', Amount: '' },
    { Part_No: '', Part_Description_from_part_dataset: '', Customer: '', Item_No: '', DEPARTMENT: '', Internal_Part_No: '', Comment: '', Item_Description: '', Supplier: '', Purchase_Unit: '', Revised_Amount: '', Qty_included_in_the_cost: '', Price: '', Currency: '', Amount: '' },
    { Part_No: '', Part_Description_from_part_dataset: '', Customer: '', Item_No: '', DEPARTMENT: '', Internal_Part_No: '', Comment: '', Item_Description: '', Supplier: '', Purchase_Unit: '', Revised_Amount: '', Qty_included_in_the_cost: '', Price: '', Currency: '', Amount: '' },
    { Part_No: '', Part_Description_from_part_dataset: '', Customer: '', Item_No: '', DEPARTMENT: '', Internal_Part_No: '', Comment: '', Item_Description: '', Supplier: '', Purchase_Unit: '', Revised_Amount: '', Qty_included_in_the_cost: '', Price: '', Currency: '', Amount: '' },
    { Part_No: '', Part_Description_from_part_dataset: '', Customer: '', Item_No: '', DEPARTMENT: '', Internal_Part_No: '', Comment: '', Item_Description: '', Supplier: '', Purchase_Unit: '', Revised_Amount: '', Qty_included_in_the_cost: '', Price: '', Currency: '', Amount: '' }
  ]);



  // Hook to update the date as today's date
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  useEffect(() => {
    Axios.get(`https://` + host + `/api/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data);
    });
  }, []);

  useEffect(() => {
    const newData = data.map((line) => ({ ...line, Date: Date2 }));
    setData(newData);
  }, [Date2])

  const submitValue = (e, index) => {
    const newData = [...data];
    newData[index].Part_No = e.target.value;
    newData[index].Customer = (part_dataset.find((x) => x.Part_No === e.target.value).Customer);
    newData[index].Part_Description_from_part_dataset = part_dataset.find((x) => x.Part_No === e.target.value).Part_Description;
    setData(newData);
  }

  const addLines = () => {
    if (data.length < 15) {
      const newData = [...data];
      for (let i = 0; i < 5; i++) {
        newData.push({ Part_No: '', Part_Description_from_part_dataset: '', Customer: '', Item_No: '', DEPARTMENT: '', Internal_Part_No: '', Comment: '', Item_Description: '', Supplier: '', Purchase_Unit: '', Revised_Amount: '', Qty_included_in_the_cost: '', Price: '', Currency: '', Amount: '' });
      }
      setData(newData);
    } else {
      window.alert("Lines Reached maximum limit")
    }

  }
  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    props.history.push('/');
  }
  const userMenu = (
    <Menu>
      <Menu.Item key="logout">
        <a onClick={handleLogout} href="/">
          <LogoutOutlined />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const submitdata = () => {
    const filteredData = [];

    data.forEach(item => {
      if (
        item.Part_No &&
        item.Part_Description_from_part_dataset &&
        item.Customer &&
        item.Item_No &&
        item.Amount
      ) {
        const newData = [
          item.Part_No,
          item.Part_Description_from_part_dataset,
          item.Customer,
          item.Item_No,
          item.DEPARTMENT,
          item.Internal_Part_No,
          item.Comment,
          item.Item_Description,
          item.Supplier,
          item.Purchase_Unit,
          item.Revised_Amount,
          item.Qty_included_in_the_cost,
          item.Price,
          item.Currency,
          item.Amount
        ];

        filteredData.push(newData);
      }
      length = filteredData.length;
    });
    setIsLoading(true);

    setTimeout(() => {
      Axios.post(`https://` + host + `/api/addBom`, { filteredData })
        .then((response) => {
          if (response.status === 200) {
            window.alert(response.data.message);
            setIsLoading(false);
            window.location.reload();

          }
        })
        .catch(error => {
          window.alert("Error Occured")
          setIsLoading(false);
        });
    }, 2000);

    console.log(filteredData);
    // window.location.reload(false);
  }
  return (
    <div>

      <br></br>
      <center><h1>Add New BOM</h1></center>

      <button onClick={addLines} class="btn btn-success">Add Row</button>
      <br></br>
      <br></br>

      <table class="table table-bordered">
        <tbody>

          <tr>
            <th>Part No</th>
            <th>Part Description</th>
            <th>Customer</th>
            <th>Item No</th>
            <th>Department</th>
            <th>Internal Part No</th>
            <th>Comment</th>
            <th>Item Description</th>
            <th>Supplier</th>
            <th>Purchase Unit</th>
            <th>Revised Amount</th>
            <th>Qty Included in the Cost</th>
            <th>Price</th>
            <th>Currency</th>
            <th>Amount</th>
          </tr>

          {data.map((line, index) => (
            <tr>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Part_No = e.target.value;
                    setData(newData);
                  }}
                  onBlur={(e) => submitValue(e, index)} // Call the submitValue function on blur
                  list="PartNo"
                  id="operator"
                />
                <div className="scrollbar">
                  <datalist id="PartNo">
                    <option></option>
                    {part_dataset.map((x) => {
                      return <option>{x.Part_No}</option>;
                    })}
                  </datalist>
                </div>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={data[index].Part_Description_from_part_dataset}
                  readOnly
                  required

                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={data[index].Customer}
                  readOnly

                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Item_No = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].Item_No}
                  name="Item No"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].DEPARTMENT = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].DEPARTMENT}
                  name="Department"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Internal_Part_No = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].Internal_Part_No}
                  name="Internal Part No"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Comment = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].Comment}
                  name="Comment"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Item_Description = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].Item_Description}
                  name="Item Description"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Supplier = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].Supplier}
                  name="Supplier"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Purchase_Unit = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].Purchase_Unit}
                  name="Purchase Unit"
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Revised_Amount = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].Revised_Amount}
                  name="Revised Amount"
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Qty_included_in_the_cost = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].Qty_included_in_the_cost}
                  name="Qty Included in the Cost"
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Price = e.target.value;
                    setData(newData);
                  }}
                  value={data[index].Price}
                  name="Price"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Currency = e.target.value;
                    setData(newData);
                  }}

                  value={data[index].Currency}
                  name="Currency"
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Amount = e.target.value;
                    setData(newData);
                  }}

                  value={data[index].Amount}
                  name="Amount"
                  required
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={submitdata} class="btn btn-primary">Submit</button>
      {isLoading ?
        <div className='overlay'>
          <div className="loading-container">
            <div className="loading-dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            <p className='loading'>Submitting</p>
          </div>

        </div> : null}
    </div>
  )
}

export default BOM_entry
import React, { useState, useEffect } from 'react';
import Chart from 'react-google-charts';
import Axios from 'axios';
import { Host } from '../App';
import { host } from '../App';
import real_l from "../real_logo.png";

const Attendance_report = () => {
  const [filter, setFilter] = useState('weekly');
  const [partNo, setPartNo] = useState([]);
  const [weekPeriod, setWeekPeriod] = useState(1);
  const [Operator, setOperator] = useState("");
  const [Date1, setDate1] = useState(new Date());
  const [startdate, setStartdate] = useState('2023-01-01');
  const [enddate, setEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [customer, setCustomer] = useState({});
  const [Status, setStatus] = useState("");
  const [operators, setoperators] = useState([]);
  const [Departments, setDepartments] = useState([]);
  const [Department, setDepartment] = useState("");
  var token = (window.localStorage.getItem("acessToken"));
  const [Station, setStation] = useState({});
  const [partInternalNo, setPartInternalNo] = useState({});
  const [leave_type_list, setleave_type_list] = useState([]);
  const [leave_type, setleave_type] = useState("");
  const [Attendance, setAttendance] = useState([]);
  const [rawdata, setrawdata] = useState([]);
  const [filter_leave_type_list, setfilter_leave_type_list] = useState([]);
  var User = JSON.parse(window.localStorage.getItem("Name"));
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();
  const [Graphview, setGraphview] = useState(false);

  const getFilteredData = () => {
    switch (filter) {
      default:
        return generateWeeklyData();
    }
  };

  const generateWeeklyData = () => {
    const data = [['x', 'SUM(Produced)']];
    const weekData = partNo.slice(-7 * weekPeriod);

    weekData.forEach((item) => {
      const date = new Date(item.Date);
      date.setDate(date.getDate() + 1); // Add 1 day to adjust the date
      const label = `${date.getDate()} ${getMonthName(date.getMonth())}`;
      data.push([label, item['SUM(Produced)']]);
    });

    return data;
  };

  const queryParams = {
    token: token,
    Status: Status,
    Operator: Operator,
    Department: Department,
    startdate: startdate,
    enddate: enddate,
    leave_type: leave_type
  };

  const getMonthName = (monthIndex) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    return months[monthIndex];
  };
  const handleWeekPeriodChange = (e) => {
    setWeekPeriod(parseInt(e.target.value, 10));
  };
  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const today = new Date();
    let startDate, endDate;

    if (selectedOption === 'lastWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    } else if (selectedOption === 'thisWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    } else if (selectedOption === 'lastMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (selectedOption === 'thisMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (selectedOption === 'lastYear') {
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today.getFullYear() - 1, 11, 31);
    } else if (selectedOption === 'thisYear') {
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
    } else if (selectedOption === 'nextWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (13 - today.getDay()));
    } else if (selectedOption === 'nextMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    } else if (selectedOption === 'nextYear') {
      startDate = new Date(today.getFullYear() + 1, 0, 1);
      endDate = new Date(today.getFullYear() + 1, 11, 31);
    } else {
      // Handle other options if needed
    }
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    };
    setStartdate(formatDate(startDate));
    setEnddate(formatDate(endDate));
  };

  // to retreive operators
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/employees_all/${token}`).then((data) => {
      setoperators(data.data);
    });
  }, []);
  // get list of departments
  useEffect(() => {
    Axios.get(`https://` + Host + `/get/Department/${token}`).then((data) => {
      setDepartments(data.data)
    });
  }, [])

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/leave_type`, { params: queryParams }).then((data) => {
      setfilter_leave_type_list(data.data);
    });
  }, []);

  //get leave types as per status selection 
  useEffect(() => {
    Axios.get(`https://` + Host + `/get/status/leavetype`, { params: queryParams }).then((data) => {
      setleave_type_list(data.data);
    });
  }, []);

  useEffect(() => {
    Axios.get(`https://` + Host + `/get/attendancereport/graph`, { params: queryParams }).then((data) => {
      setrawdata(data.data);
    });
  }, [Status, Operator, Department, startdate, enddate, leave_type]);

  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/getreport/searchgraph/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${JSON.stringify(Station)},${token}`).then((data) => {
      setPartNo(data.data)
    })
  }, [Station, Operator, startdate, enddate, customer, partInternalNo])
  const search = () => {
    Axios.get(`https://` + host + `/realapi/getreport/searchgraph/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${JSON.stringify(Station)},${token}`).then((data) => {
      setPartNo(data.data)
    })
  }
  const getFilteredDatas = () => {
    const data = [
      ['Employee Name', 'Present', 'Absent', 'Sick_Leave', 'Call_Off', 'Vacation', 'Bereavement_Leave', 'Left_Early', 'Half_Day', 'Late', 'Start_Early']
    ];
    rawdata.forEach(employee => {
      data.push([
        employee.Employee_Name,
        employee.Present || 0,
        employee.Absent || 0,
        employee.Sick || 0,
        employee.Call_Off || 0, // Assuming 'Call_Off' field exists, otherwise adjust
        employee.VACATION || 0,
        employee.Bereavement || 0,
        employee.Left_Early || 0,
        employee.Half_Day || 0,
        employee.Late || 0,
        employee.Start_Early || 0
      ]);
    });

    return data;
  }

  const handlePrint = () => {

    const table = document.querySelector('#alldata');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <table class="table table-striped">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };
  const renderWeekPeriodInput = () => {
    return (
      <div>
        <div id="linegraph" >
          <div class="row">
            <div class="col-md-auto">
              {/* Grab the particular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Operator' list="operators" onChange={(e) => {
                setOperator(e.target.value)
              }} onSelect={search} />
              <datalist id="operators">
                <option></option>
                {operators.map(x => {
                  return <option>{x.Name}</option>
                })}
              </datalist>

            </div>
            <div class="col-md-auto">
              <select class="form-control form-rounded" onChange={handleSelectChange}>
                <option value="">Select</option>
                <option value="lastWeek">Last Week</option>
                <option value="thisWeek">This Week</option>
                <option value="lastMonth">Last Month</option>
                <option value="thisMonth">This Month</option>
                <option value="thisYear">This Year</option>
                <option value="lastYear">Last Year</option>
                <option value="currentQuarter">Current Quarter</option>
              </select>
            </div>
            <div class="col-md-auto">
              <div class="row" style={{ "width": "auto" }} id='hidrow'>
                <div class="col-md-auto">
                  {/* Grab the particular dates */}
                  <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={startdate} onChange={(e) => {
                    setStartdate(e.target.value)
                  }} onSelect={search} />
                  {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                </div>
                <br />
                <br />
                <div class="col-md-auto">
                  <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                    setEnddate(e.target.value)
                  }} onSelect={search} />
                </div>
              </div>
            </div>
            <div class="col-md-auto">

              {/* Grab the particular Customer */}
              <input type='text' class="form-control form-rounded" placeholder='Department' list='Departments' onChange={(e) => {
                setDepartment(e.target.value)
              }} onSelect={search} />
              <datalist id="Departments">
                <option></option>
                {Departments.map(x => {
                  return <option>{x.Department}</option>
                })}
              </datalist>
            </div>
            <div class="col-md-auto">
              {/* Grab the particular partInternalNo */}
              <input type='text' class="form-control form-rounded" placeholder='Status ' list='Status' onChange={(e) => {
                if (e.target.value === "Absent") {
                  setStatus("Absent")
                }
                if (e.target.value === "Present") {
                  setStatus("Present")
                }

              }} onSelect={search} />
              <datalist id="Status">
                <option >Absent</option>
                <option >Present</option>
              </datalist>
            </div>
            {Status == 'Absent' && (
              <div>
                <select name="LeaveType" class="form-control form-rounded" placeholder="Select Reason" onChange={(e) => setleave_type(e.target.value)} >
                  <option value="">Select Reason</option>
                  {filter_leave_type_list.map((x) => (
                    <option key={x.type} value={x.type}>
                      {x.type}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {Status == 'Present' && (
              <div>
                <select name="LeaveType" class="form-control form-rounded" placeholder="Select Reason " onChange={(e) => setleave_type(e.target.value)} >
                  <option value="">Select Reason</option>
                  {filter_leave_type_list.map((x) => (
                    <option key={x.Present} value={x.Present}>
                      {x.Present}
                    </option>
                  ))}
                </select>
              </div>
            )}

          </div>
        </div>
      </div>
    );
  };
  const totals = rawdata.reduce((acc, employee) => {
    acc.Present += employee.Present || 0;
    acc.Absent += employee.Absent || 0;
    acc.Sick += employee.Sick || 0;
    acc.Call_Off += employee.Call_Off || 0;
    acc.VACATION += employee.VACATION || 0;
    acc.Bereavement += employee.Bereavement || 0;
    acc.Left_Early += employee.Left_Early || 0;
    acc.Half_Day += employee.Half_Day || 0;
    acc.Late += employee.Late || 0;
    acc.Start_Early += employee.Start_Early || 0;
    return acc;
  }, {
    Present: 0,
    Absent: 0,
    Sick: 0,
    Call_Off: 0,
    VACATION: 0,
    Bereavement: 0,
    Left_Early: 0,
    Half_Day: 0,
    Late: 0,
    Start_Early: 0,
  });

  const changeview = () => {

  }
  return (
    <div id="linegraph">
      <h2>Employee Attendance Report</h2>
      <button onClick={() => { setGraphview(!Graphview) }} className='btn btn-primary'>Change View</button>

      {renderWeekPeriodInput()}
      {!Graphview ?
        <div id="alldata_openorder">
          <center>
            <Chart
              width={'1500px'}
              height={'410px'}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={getFilteredDatas()}
              options={{
                hAxis: {
                  title: 'Count',
                },
                vAxis: {
                  title: 'Employee Name',
                },
                isStacked: true,
                legend: { position: 'bottom', maxLines: 11 },
                bars: 'vertical', // Required for Material Bar Charts.
                colors: ['#4CAF50', '#F44336', '#FFEB3B', '#9C27B0', '#03A9F4', '#FFC107', '#009688', '#795548', '#FF5722', '#607D8B']
                ,
              }}
              rootProps={{ 'data-testid': '2' }}
            />
          </center >
        </div>
        :
        <div>
          <button onClick={handlePrint} className='btn btn-primary'>Print</button>
          <br></br>
          <div id="alldata_openorder">
            <table class="table table-bordered" id='alldata'>
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Present</th>
                  <th>Absent</th>
                  <th>Sick Leave</th>
                  <th>Call Off</th>
                  <th>Vacation</th>
                  <th>Left Early</th>
                  <th>Half Day</th>
                  <th>Late</th>
                  <th>Start Early</th>
                  <th>Bereavement Leave</th>
                </tr>
              </thead>
              <tbody>
                {rawdata.map((employee, index) => (
                  <tr key={index}>
                    <td>{employee.Employee_Name}</td>
                    <td>{employee.Present || ''}</td>
                    <td>{employee.Absent || ''}</td>
                    <td>{employee.Sick || ''}</td>
                    <td>{employee.Call_Off || ''}</td>
                    <td>{employee.VACATION || ''}</td>
                    <td>{employee.Left_Early || ''}</td>
                    <td>{employee.Half_Day || ''}</td>
                    <td>{employee.Late || ''}</td>
                    <td>{employee.Start_Early || ''}</td>
                    <td>{employee.Bereavement || ''}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td>{totals.Present || ''}</td>
                  <td>{totals.Absent || ''}</td>
                  <td>{totals.Sick || ''}</td>
                  <td>{totals.Call_Off || ''}</td>
                  <td>{totals.VACATION || ''}</td>
                  <td>{totals.Left_Early || ''}</td>
                  <td>{totals.Half_Day || ''}</td>
                  <td>{totals.Late || ''}</td>
                  <td>{totals.Start_Early || ''}</td>
                  <td>{totals.Bereavement || ''}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>


  );
};

export default Attendance_report;



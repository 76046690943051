// src/components/PrivateRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken, getRank } from './Common';


const ManagerRoute = ({ component: Component, adminOnly, alternate, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const token = getToken();
        const user = getRank();
        console.log(user);
        if (!token) {
          // If no token is found, redirect to login page
          return <Redirect to="/login" />;
        }

        if (adminOnly && (user === 'Admin2' || user === 'Admin3')) {
          return <Component {...props}
          />;
        }
        // If the route is admin-only and the user is not an admin, redirect to unauthorized page
        return <Redirect to={alternate || "/NewDashboard"} />
        // If everything is fine, render the component

      }}
    />
  );
}

export default ManagerRoute;

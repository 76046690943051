import React, { useState, useEffect } from 'react'
import { Link, Redirect, useParams } from "react-router-dom"
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { host, Host } from '../../App';
import Axios from 'axios'
import { VerticalLeftOutlined } from '@ant-design/icons';
import { CheckBox } from '@mui/icons-material';

function History_Received_Supplies() {
  var [postList, setPostList] = useState([]);
  var [PoNo, setPoNo] = useState({});
  var [poNo, setpoNo] = useState([]);
  var [Item_Desc, setItem_Desc] = useState("");
  var [Date1, setDate1] = useState(new Date());
  var [Postartdate, setPoStartdate] = useState('2023-01-01');
  var [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  var [Supplier, setSupplier] = useState({});
  var [Catgory, setCategory] = useState({});
  var [Category_list, setCategory_list] = useState([]);
  var [ItemNo, setItemNo] = useState("");
  var [Status, setStatus] = useState("");
  var [Customers, setCustomers] = useState([]);
  var [partNo, setpartNo] = useState([]);
  var [order, setOrder] = useState("1");
  const [Invoice_status, setInvoice_status] = useState(true);

  const [data, setData] = useState([{ PO_No: "", Item_No: "", ReceivedQty: "", ActualReceiveddate: "", Invoice_cost: "", Unit_cost: "", PO_CURRENCY: "", Supplier: "", Item_Description: "", QTY: "", Unit_price_po: "", ETA: "" },]);


  //var token = sessionStorage.getItem("token");
  var token = (window.localStorage.getItem("acessToken"));

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/_HistoryReceived_supplies/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)},${JSON.stringify(Catgory)},${JSON.stringify(Status)}`).then((data) => {
      setData(data.data);
    })
  }, [Status]);
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/pono/${token}`).then((data) => {
      setpoNo(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/Category/${token}`).then((data) => {
      setCategory_list(data.data)
    });
  }, [])
  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/suppliers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/itemno/${token}`).then((data) => {
      setpartNo(data.data)
    });
  }, [])
  const search = () => {
    Axios.get(`https://` + Host + `/api/_HistoryReceived_supplies/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)},${JSON.stringify(Catgory)},${JSON.stringify(Status)}`).then((data) => {
      setData(data.data);

    })
  }
  const Delete = (Po_No, Item_No, Receiving_Date) => {
    Axios.delete(`https://` + host + `/api/History_Supplies/Delete/${Po_No},${Item_No},${Receiving_Date},${token}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message, {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
            style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
          });
          search();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });

  };

  //section for Invoice handling
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleCheckboxChange = (e, row) => {
    const isChecked = e.target.checked;
    const { PO_No, Item_No } = row;
    if (isChecked) {
      // Add to selectedRows
      setSelectedRows([...selectedRows, { PO_No, Item_No }]);
    } else {
      // Remove from selectedRows
      setSelectedRows(selectedRows.filter(item => item.PO_No !== PO_No || item.Item_No !== Item_No));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      // Select all rows
      setSelectedRows(data.map(row => ({ PO_No: row.PO_No, Item_No: row.Item_No })));
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  const ToggleInvoice = () => {
    setInvoice_status(!Invoice_status);
  }
  const handleSubmit = () => {

    Axios.post(`https://` + host + `/Supplies/Invoice/Update`, { changedData: JSON.stringify(selectedRows), token: token })
      .then((response) => {
        if (response.status === 200) {
          const numberInputs = document.querySelectorAll('input[type="number"]');
          numberInputs.forEach(input => {
            input.value = '';
          });
          toast.success(response.data.message, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
            style: { fontSize: '24px', borderRadius: '10px' },
          });
          setInvoice_status(false);
          search();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });

  };
  return (
    <div className="MainPage_data">
      <br></br>  <h1>History Received Supplies</h1><br></br>
      <div class="Container">
        <ToastContainer />
        <div class="row">
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='PO NO' list="PoNo" onChange={(e) => {
                setPoNo(e.target.value)
              }} onSelect={search} />
              <datalist id="PoNo">
                <option></option>
                {poNo.map(x => {
                  return <option>{x.PO_No}</option>
                })}
              </datalist>
            </div >
            <div class="row">
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Category' list="category" onChange={(e) => {
                setCategory((e.target.value).replace(/[{""}]/g, ""))
              }} onSelect={search} />
              <datalist id="category">
                <option></option>
                {Category_list.map(x => {
                  return <option>{x.Category}</option>
                })}
              </datalist>
            </div>

          </div >
          <div class="col">
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Item No' list='Itemno' onChange={(e) => {
                setItemNo(e.target.value)
              }} onSelect={search} />
              <datalist id="Itemno">
                <option></option>
                {partNo.map(x => {
                  return <option>{x.Item_No}</option>
                })}
              </datalist>
            </div>
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Item DESCRIPTION' list="Itemdesc" onChange={(e) => {
                setItem_Desc(e.target.value.replace(/[{"''"}]/g, ''));
                console.log((e.target.value.replace(/[{"''"}]/g, '')));

              }} onSelect={search} />
              <datalist id="Itemdesc">
                <option></option>
                {postList.map(x => {
                  return <option>{x.Item_Description}</option>
                })}
              </datalist>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col" id='moldng'>
                {/* Grab the Itemicular dates */}
                <input type='date' class="form-control form-rounded" placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                  setPoStartdate(e.target.value)
                }} onSelect={search} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                <span class="tooltiptext">Filter By ETA </span>

              </div>
              <div class="col">
                <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setPoEnddate(e.target.value)
                }} onSelect={search} />
              </div>
              <div class="row">
                {Invoice_status ? <div class="col">

                </div> :
                  <div class="col" id="moldng">
                    <select
                      type="select"
                      class="form-control form-rounded"
                      id="order"
                      name="Invoice Status"
                      onChange={(e) => {
                        setStatus(e.target.value);

                      }}
                    >
                      <option value="">Invoice Status</option>
                      <option >Pending</option>
                      <option >Received</option>
                    </select>
                    <span class="tooltiptext">Invoice Status </span>
                  </div>}
                <div class="col">

                </div>
              </div>


            </div>
          </div>
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Supplier */}
              <input type='text' class="form-control form-rounded" placeholder='Supplier' list='Suppliers' onChange={(e) => {
                setSupplier(e.target.value)
              }} onSelect={search} />
              <datalist id="Suppliers">
                <option></option>
                {Customers.map(x => {
                  return <option>{x.Supplier}</option>
                })}
              </datalist>
            </div>
            <div class="row">
              <div class="col">
                <input type="button" class="btn btn-primary" defaultValue="Open Invoices" onClick={() => { ToggleInvoice() }} />
              </div>
              <div class="col">
                <Link to="/Suppluchain">
                  <input type="button" class="btn btn-success" defaultValue="Back" />
                </Link>
              </div>

            </div >
          </div>
        </div >
        <br></br>
        <br></br>

        {Invoice_status ? <table class="table table-bordered">
          <thead>
            <tr>
              <th>PO NO</th>
              <th>ITEM NO</th>
              <th>PO DATE</th>
              <th>ITEM DESCRIPTION</th>
              <th>SUPPLIER</th>
              <th>PO QTY</th>
              <th>ETA</th>
              <th>RECEIVED QTY</th>
              <th>RECEIVED DATE</th>
              <th>CURRECNCY</th>
              <th>Unit Cost(PO)</th>
              <th>Invoice Cost</th>
              <th>Invoice Amount</th>
              <th id="comment">COMMENTS </th>
              <th>Delete</th>
            </tr>
          </thead>
          {data.map((val, index) => (
            <tbody key={index}>
              <tr key={`${val.SrNo}`}>
                <td>{val.PO_No}</td>
                <td>{val.Item_No}</td>
                <td>{val.PO_Date ? val.PO_Date.slice(0, 10) : ''}</td>
                <td>{val.Item_Description}</td>
                <td>{val.Supplier}</td>
                <td>{val.PO_QTY}</td>
                <td>{val.ETA ? val.ETA.slice(0, 10) : ''}</td>
                <td>{val.QTY_Received}</td>
                <td>{val.Receiving_Date ? val.Receiving_Date.slice(0, 10) : ''}</td>
                <td>{val.PO_CURRENCY}</td>
                <td>${val.Unit_Cost_PO}</td>
                <td>${val.UNIT_COST_INVOICE}</td>
                <td>${val.Invoice_Amount}</td>

                <td id="comment">{val.Comments}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      window.confirm('Are you sure you want to delete this Entry?') &&
                      Delete(val.PO_No, val.Item_No, val.Receiving_Date.slice(0, 10))
                    }
                  >
                    Delete
                  </button></td>
              </tr>
            </tbody>
          )
          )}
        </table> : <table class="table table-bordered">
          <thead>
            <tr>
              <th><input
                type="checkbox"
                class="form-check-input"
                checked={selectAll}
                onChange={handleSelectAll}
              /></th>
              <th>PO NO</th>
              <th>ITEM NO</th>
              <th>PO DATE</th>
              <th>ITEM DESCRIPTION</th>
              <th>SUPPLIER</th>
              <th>PO QTY</th>
              <th>ETA</th>
              <th>RECEIVED QTY</th>
              <th>RECEIVED DATE</th>
              <th>CURRECNCY</th>
              <th>Unit Cost(PO)</th>
              <th>Invoice Cost</th>
              <th>Invoice Amount</th>
              <th id="comment">Invoice</th>
              <th>Update</th>
            </tr>
          </thead>
          {data.map((val, index) => (
            <tbody key={index}>
              <tr key={`${val.SrNo}`}>
                <td>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    checked={selectedRows.some(row => row.PO_No === val.PO_No && row.Item_No === val.Item_No)}
                    onChange={(e) => handleCheckboxChange(e, val)}
                  />
                </td>
                <td>{val.PO_No}</td>
                <td>{val.Item_No}</td>
                <td>{val.PO_Date ? val.PO_Date.slice(0, 10) : ''}</td>
                <td>{val.Item_Description}</td>
                <td>{val.Supplier}</td>
                <td>{val.PO_QTY}</td>
                <td>{val.ETA ? val.ETA.slice(0, 10) : ''}</td>
                <td>{val.QTY_Received}</td>
                <td>{val.Receiving_Date ? val.Receiving_Date.slice(0, 10) : ''}</td>
                <td>{val.PO_CURRENCY}</td>
                <td>${val.Unit_Cost_PO}</td>
                <td>${val.UNIT_COST_INVOICE}</td>
                <td>${val.Invoice_Amount}</td>

                <td id="comment">{val.Invoice}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button></td>
              </tr>
            </tbody>
          )
          )}
        </table>}
      </div >
    </div >
  )
}

export default History_Received_Supplies
import React, { useState, useEffect } from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DynamicTable from './Table_format';
import BOM from '../../BOM/BOM';
import Axios from 'axios'
import moment from 'moment';
import './Pivot.css';
import { Host } from '../../App';
var filteredData = 0;
export const Data_Supply = (props) => {
  var [postList, setPostList] = useState([{
    "Description": " ",
    "QTY": " ",
    "Receiving Date": "",
  }]);
  const [Date1, setDate1] = useState(new Date());
  const [Postartdate, setPoStartdate] = useState(Date1.toISOString().slice(0, 10));
  const [Pofinaldatedate, setPoFinaldate] = useState(moment(Postartdate).add(6, 'days').format('YYYY-MM-DD'));
  const [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [isLoading, setIsLoading] = useState(false);
  var token = (window.localStorage.getItem("acessToken"));
  var [section, setsection] = useState("Supplies");
  const [Item_No, setItem_No] = useState("");
  const [showPart, setShowPart] = useState(false);

  const [Bom, setBOM] = useState([{
    "Description": " ",
    "QTY": " ",
    "Receiving Date": "",
  }]);
  const [isBOM, setIsBOM] = useState(false);

  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  const toggleparts = () => {
    setShowPart(!showPart);
  }
  const queryParams = {
    start_date: Postartdate,
    end_date: Poenddate,
    token: token,
    Item_No: Item_No
  };
  useEffect(() => {
    if (section === "Supplies") {
      setIsBOM(false)
    } if (section === "BOM") {
      setIsBOM(true)
    }

  }, [section, postList])
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      Axios.get(`https://` + Host + `/supplies/pivot`, { params: queryParams }).then((data) => {
        if (data.data === "Error") {
          setIsLoading(false);
        }
        else {
          if (data.data[5].length > 0) {
            setPostList(data.data[5]);
          } else if (data.data[5].length <= 0) {
            window.alert("No Data Received!")
            setPostList([{
              "Description": " ",
              "QTY": "",
              "Receiving date": "",
            }]);
          }
        }
        setIsLoading(false);
      }, 2000);

      setTimeout(() => {
        setIsLoading(false)
      }, 3000)
    });

  }, [section, Poenddate, Postartdate, token])

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      Axios.get(`https://` + Host + `/BOM/pivot`, { params: queryParams }).then((data) => {
        if (data.data === "Error") {
          setIsLoading(false);
        }
        else {
          if (data.data.length > 0) {
            setBOM(data.data);
          } else if (data.data.length <= 1) {
            window.alert("No Data Received!")
          }
        }
        setIsLoading(false);
      }, 2000);

      setTimeout(() => {
        setIsLoading(false)
      }, 3000)
    });

  }, [section, Poenddate, Postartdate, token, Item_No])

  const headers = Object.keys(postList[0]);
  const filteredHeaders = headers.filter((header) => {
    return header !== 'PO_NO' && header !== 'PART_NO' && header !== 'PART_DESCRIPTION';
  });

  filteredData = postList.map((post) => {
    const filteredPost = {};
    filteredHeaders.forEach((header) => {
      if (header === 'PO_SHIP_DATE') {
        // slice the PO_SHIP_Date column value to the first 10 characters
        filteredPost[header] = post[header];
      } else {
        filteredPost[header] = post[header];
      }
    });
    return filteredPost;
  });

  const downloadCSV = () => {
    const filename = "Pivot";
    // Convert data to CSV string
    const csv = XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(filteredData));
    // Create a Blob object and download the file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(blob, filename);
    //console.log(postList);
  };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const today = new Date();
    let startDate, endDate;

    if (selectedOption === 'lastWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    } else if (selectedOption === 'thisWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    } else if (selectedOption === 'lastMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (selectedOption === 'thisMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (selectedOption === 'lastYear') {
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today.getFullYear() - 1, 11, 31);
    } else if (selectedOption === 'thisYear') {
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
    } else if (selectedOption === 'nextWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (13 - today.getDay()));


    } else if (selectedOption === 'nextMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    } else if (selectedOption === 'nextYear') {
      startDate = new Date(today.getFullYear() + 1, 0, 1);
      endDate = new Date(today.getFullYear() + 1, 11, 31);
    } else if (selectedOption === 'nextQuarter') {
      const currentMonth = today.getMonth();
      const currentQuarter = Math.floor(currentMonth / 3) + 1;
      const nextQuarterStartMonth = (currentQuarter * 3) % 12; // Start month of the next quarter
      const nextQuarterEndMonth = nextQuarterStartMonth + 2; // End month of the next quarter

      // If the next quarter starts next year, adjust the year accordingly
      const nextQuarterYear = nextQuarterStartMonth === 0 ? today.getFullYear() + 1 : today.getFullYear();

      startDate = new Date(nextQuarterYear, nextQuarterStartMonth, 1);
      endDate = new Date(nextQuarterYear, nextQuarterEndMonth + 1, 0);
    } else if (selectedOption === 'currentQuarter') {
      const currentMonth = today.getMonth();
      const currentQuarter = Math.floor(currentMonth / 3) + 1;
      const currentQuarterStartMonth = (currentQuarter - 1) * 3; // Start month of the current quarter
      const currentQuarterEndMonth = currentQuarterStartMonth + 2; // End month of the current quarter

      startDate = new Date(today.getFullYear(), currentQuarterStartMonth, 1);
      endDate = new Date(today.getFullYear(), currentQuarterEndMonth + 1, 0);
    } else if (selectedOption === 'nextDay') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
    } else {
      // Handle other options if needed
    }

    setPoStartdate(startDate);
    setPoEnddate(endDate);

  };
  const fetchData = () => {
    // Simulating data fetching delay
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  return (
    <div className="MainPage_data">
      <div >
        <div class="Container">
          <div class="row">
            <div class="col-2">
              {/*<select
                type="select"
                class="form-control form-rounded"
                id="order"
                name="order"
                onChange={(e) => {
                  setsection(e.target.value);

                }}
              >
                <option >Supplies</option>
                <option > BOM</option>
              </select>*/}
              <br />
            </div>
            <div class="col-3"></div>

            <div class="col-2"><h2 id='title'>{section}</h2></div>
            <br />
          </div>
          <div class="row">
            <div class="col-3">
              <div class="row">
                <label>Choose ETA</label>
                <div class="col" id='moldng'>
                  {/* Grab the particular dates */}
                  <input type='date' class="form-control form-rounded" placeholder='ETA' defaultValue={Postartdate} onChange={(e) => {
                    setPoStartdate(e.target.value)
                  }} />
                  {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                  <span class="tooltiptext">Filter By ETA </span>
                </div>
                <div class="col">
                  <input type='date' class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                    setPoEnddate(e.target.value)
                  }} />
                </div>
                <div class="ROW">
                  <select class="form-control form-rounded" onChange={handleSelectChange}>
                    <option value="">Select</option>
                    <option value="lastWeek">Last Week</option>
                    <option value="thisWeek">This Week</option>
                    <option value="lastMonth">Last Month</option>
                    <option value="thisMonth">This Month</option>
                    <option value="nextWeek">Next Week</option>
                    <option value="nextMonth">Next Month</option>
                    <option value="currentQuarter">Current Quarter</option>
                    <option value="nextQuarter">Next Quarter</option>
                    <option value="nextDay">Next Day</option>

                  </select>

                </div>

              </div>
            </div>
            <div class="col-2">
              {isBOM ? <div>

                <label></label>
                <input type='text' class="form-control form-rounded" placeholder='Item No' list="ItemNo" onChange={(e) => {
                  setItem_No(e.target.value)
                }} />
                <datalist id="ItemNo">
                  <option></option>
                  {Bom.map(x => {
                    return <option>{x.Item_No}</option>
                  })}
                </datalist>
              </div> : null}
            </div>
            <div class="col-3">

            </div>
            <div class="col-3">

            </div>
            <div class="col-1">
              <button class="btn btn-success" onClick={downloadCSV}>
                Download As CSV
              </button>
            </div>
            {isBOM ? <BOM bom={Bom} /> : <DynamicTable postList={postList} section={section} />}

            <div class="col-7">

            </div>
            <div class="col-1">

            </div>
          </div>
          <br></br>
          <br></br>

        </div>
        {isLoading ?
          <div className='overlay'>
            <div className="loading-container">
              <div className="loading-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <p className='loading'>Loading</p>
            </div>

          </div> : null}
      </div>

    </div >
  )
}

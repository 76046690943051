import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import './MainPage.css'
import { host } from '../../App';
import jsPDF from 'jspdf';
var filteredData = 0;

function GetReport() {
  const [postList, setPostList] = useState([]);
  const [Operator, setOperator] = useState({});
  const [Date1, setDate1] = useState(new Date());
  const [startdate, setStartdate] = useState(Date1.toISOString().slice(0, 10));
  const [enddate, setEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [customer, setCustomer] = useState({});
  const [partInternalNo, setPartInternalNo] = useState({});
  var [partdetailedList, setPartdetailedList] = useState([]);
  const [operators, setoperators] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  var token = (window.localStorage.getItem("acessToken"));
  var [group, setGroup] = useState("Part_internalNo");
  const [showDiv, setShowDiv] = useState(false);
  const [showPartdata, setShowPartdata] = useState(true);
  const [showOperatordata, setShowOperatordata] = useState(false);



  const toggleDiv = () => {
    setShowDiv(!showDiv);
  }
  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const today = new Date();
    let startDate, endDate;

    if (selectedOption === 'lastWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    } else if (selectedOption === 'thisWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    } else if (selectedOption === 'lastMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (selectedOption === 'thisMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (selectedOption === 'lastYear') {
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today.getFullYear() - 1, 11, 31);
    } else if (selectedOption === 'thisYear') {
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
    } else if (selectedOption === 'nextWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (13 - today.getDay()));
    } else if (selectedOption === 'nextMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    } else if (selectedOption === 'nextYear') {
      startDate = new Date(today.getFullYear() + 1, 0, 1);
      endDate = new Date(today.getFullYear() + 1, 11, 31);
    } else {
      // Handle other options if needed
    }

    setStartdate(startDate);
    setEnddate(endDate);
  };
  const Targeted_part_data = (Part_internalNo) => {
    setShowDiv(true);
    Axios.get(`https://` + host + `/realapi/getreport/getpartdetails/${Part_internalNo},${token},${startdate},${enddate}`).then((data) => {
      setPartdetailedList(data.data)
    });
  }
  const Targeted_operator_data = (Operator) => {
    setShowDiv(true);
    Axios.get(`https://` + host + `/realapi/getreport/getoperatordetails/${Operator},${JSON.stringify(partInternalNo)},${JSON.stringify(customer)},${token},${startdate},${enddate}`).then((data) => {
      setPartdetailedList(data.data)
    });
  }
  useEffect(() => {

    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });
  // Default Data 
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/defaultData/${startdate},${token}`).then((data) => {
      setPostList(data.data)
    });
  }, [])

  // to retreive operators
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Operators/${token}`).then((data) => {

      setoperators(data.data)
    });
  }, [])

  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Customers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])


  // to retreive part dataset
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)

    });
  }, [])

  useEffect(() => {
    console.log(group);
    if (group === "Part_internalNo") {
      console.log(group + "part interanl no");
      Axios.get(`https://` + host + `/realapi/getreport/search/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${token},${group}`).then((data) => {
        setPostList(data.data)
      })
    } else if (group === "Operator") {
      console.log(group + "operator");
      Axios.get(`https://` + host + `/realapi/getreport_operator/search/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${token},${group}`).then((data) => {
        setPostList(data.data)
      })
    }
  }, [group, Operator, startdate, enddate, customer, partInternalNo])

  // method to retrieve data combinely
  const search = () => {
    {/*Axios.get(`https://` + host + `/realapi/getreport/search/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${token},${group}`).then((data) => {
      setPostList(data.data)
    })*/}


  }
  // method to retrieve data combinely
  const Delete = (date, operator, partnumber) => {
    Axios.delete(`https://` + host + `/realapi/delete/${date},${operator},${partnumber},${token}`).catch(function (error) {
      console.log(error.response);
    })
    window.location.reload(false);
  }
  // method to retrieve data combinely

  const generatePDF = () => {
    const headers = Object.keys(postList[0]);
    const filteredHeaders = headers.filter((header) => {
      return header !== 'PartNo';
    });

    filteredData = postList.map((post) => {
      const filteredPost = {};
      filteredHeaders.forEach((header) => {
        if (header === 'DATE') {
          // slice the PO_SHIP_Date column value to the first 10 characters
          filteredPost[header] = post[header].slice(0, 10);
        } else {
          filteredPost[header] = post[header];
        }
      });
      return filteredPost;
    });
    const doc = new jsPDF();
    const tableData = [];

    // Add title and date to the PDF
    doc.setFontSize(18);
    const title = "Realform Technology";
    doc.text(title, 70, 20);
    const today = new Date().toLocaleDateString();
    doc.text(today, 70, 30);

    // Loop through the filtered data and add it to the tableData array
    filteredData.forEach((post) => {
      const rowData = [];
      headers.forEach((header) => {
        if (header !== 'PO_NO' && header !== 'PartNo' && header !== 'PART_DESCRIPTION') {
          rowData.push(post[header]);
        }
      });
      tableData.push(rowData);
    });

    // Set the table headers
    const headersPDF = headers.filter((header) => header !== 'PO_NO' && header !== 'PartNo' && header !== 'PART_DESCRIPTION');

    // Add the table to the document
    doc.autoTable({
      head: [headersPDF],
      body: tableData,
    });

    // Download the PDF file
    doc.save('filtered_data.pdf');
  };

  return (
    <div className="MainPage_data">
      <div class="Container">
        <div class="row">
          <div class="col-md-auto">
            {/* Grab the particular Operator name */}
            <input type='text' class="form-control form-rounded" placeholder='Operator' list="operators" onChange={(e) => {
              setOperator(e.target.value)
            }} onSelect={search} />
            <datalist id="operators">
              <option></option>
              {operators.map(x => {
                return <option>{x.Morning_Operators}</option>
              })}
            </datalist>
            <div class="ROW">
              <select class="form-control form-rounded" onChange={handleSelectChange}>
                <option value="">Select</option>
                <option value="lastWeek">Last Week</option>
                <option value="thisWeek">This Week</option>
                <option value="lastMonth">Last Month</option>
                <option value="thisMonth">This Month</option>
                <option value="nextWeek">Next Week</option>
                <option value="nextMonth">Next Month</option>
              </select>

            </div>
          </div>
          <div class="col-md-auto">
            <div class="row" id='hidrow'>
              <div class="col-md-auto">
                {/* Grab the particular dates */}
                <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setStartdate(e.target.value)
                }} onSelect={search} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
              </div>
              <br />
              <br />
              <div class="col-md-auto">
                <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setEnddate(e.target.value)
                }} onSelect={search} />
              </div>
            </div>
          </div>
          <div class="col-md-auto">
            {/* Grab the particular Customer */}
            <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customers' onChange={(e) => {
              setCustomer(e.target.value)
            }} onSelect={search} />
            <datalist id="Customers">
              <option></option>
              {Customers.map(x => {
                return <option>{x.Customers}</option>
              })}
            </datalist>

          </div>
          <div class="col-md-auto">
            {/* Grab the particular partInternalNo */}
            <input type='text' class="form-control form-rounded" placeholder='Internal Part Number' list='InternalPartNo' onChange={(e) => {
              setPartInternalNo(e.target.value)
              console.log("svgdh");
            }} onSelect={search} />
            <datalist id="InternalPartNo">
              <option></option>
              {part_dataset.map(x => {
                return <option>{x.Part_internalNo}</option>
              })}
            </datalist>
          </div>
          <div class="col-md-auto">
            <label for="html">Group By</label>
          </div>
          <div class="col-md-auto">
            <label for="Operators">Operator</label><br></br>
            <input type="radio" name='shift' id='Operators' value="Operators" onClick={(e) => {
              setShowPartdata(false);
              setShowOperatordata(true);
              setGroup("Operator");
              search();
            }} />
            {/*<input type="radio" id="Morning" name="Shift" value="Morning"  />*/}
          </div>
          <div class="col-md-auto">
            <label for="Part No">Part No</label><br></br>
            <input type="radio" name='shift' id='Part No' onClick={(e) => {
              setShowOperatordata(false);
              setShowPartdata(true);
              setGroup("Part_internalNo");
              //group = "PartNo";
              search();
            }} defaultChecked />
          </div>
          <div class="col-lg-2">
            <button className="btn btn-primary" onClick={generatePDF}>Download PDF</button>
          </div>
        </div>

        <div>
          <Link to="/Morning">
            <button type='button' class="btn btn-success">
              <span class="glyphicon glyphicon-star" aria-hidden="true"></span> Back</button>
          </Link>
        </div>
        {showPartdata ? <table class="table table-bordered">
          <thead>
            <tr>
              <th>Part Internal No</th>
              <th>CUSTOMER</th>
              <th>Produced</th>
            </tr>
          </thead>
          {postList.map((val, key) => {
            return (
              <tbody id="tbody">
                <tr key={key}>
                  <td><p><a href='#' onClick={() => { Targeted_part_data(val.Part_internalNo) }}>{val.Part_internalNo}</a></p> </td>
                  <td>{val.Customer}</td>
                  <td>{val.Produced}</td>
                </tr>
              </tbody>
            )
          })}
          {showDiv ? <div class='overlay'>
            <div class='box'>
              <table class="table table-bordered" style={{ maxHeight: "400px", overflowY: "scroll" }}>
                <thead>

                  <tr>
                    <th>Date</th>
                    <th>Operator</th>
                    <th>Part Internal No</th>
                    <th>CUSTOMER</th>
                    <th>Target</th>
                    <th>Scrap</th>
                    <th>Produced</th>
                    <th>Supervisor Notes</th>
                  </tr>
                </thead>
                {partdetailedList.map((val, key) => {
                  return (
                    <tbody>
                      <tr>
                        <td>{val.Date.slice(0, 10)}</td>
                        <td>{val.Operator}</td>
                        <td>{(val.Part_internalNo)}</td>
                        <td>{val.Customer}</td>
                        <td>{val.Target}</td>
                        <td>{(val.Scrap)}</td>
                        <td>{val.Produced}</td>
                        <td>{val.Supervisor_notes}</td>
                      </tr>
                    </tbody>
                  )
                })}
              </table>
              <button type='button' class="form-control form-rounded" onClick={toggleDiv}>Close</button>
            </div>
          </div> : null}
        </table> : null}
        {showOperatordata ? <table class="table table-bordered">
          <thead>
            <tr>
              <th>Operator</th>
              <th>Seat</th>
              <th>Body</th>
              <th>Others</th>
              <th>Produced</th>
            </tr>
          </thead>
          {postList.map((val, key) => {
            return (
              <tbody id="tbody">
                <tr key={key}>
                  <td><p><a href='#' onClick={() => { Targeted_operator_data(val.Operator) }}>{val.Operator}</a></p> </td>
                  <td>{val.Seat}</td>
                  <td>{val.Body}</td>
                  <td>{val.Others}</td>
                  <td>{val.Produced}</td>
                </tr>
              </tbody>
            )
          })}
          {showDiv ? <div class='overlay'>
            <div class='box'>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Operator</th>
                    <th>Part Internal No</th>
                    <th>CUSTOMER</th>
                    <th>Target</th>
                    <th>Scrap</th>
                    <th>Produced</th>
                    <th>Supervisor Notes</th>
                  </tr>
                </thead>
                {partdetailedList.map((val, key) => {
                  return (
                    <tbody>
                      <tr>
                        <td>{val.Date.slice(0, 10)}</td>
                        <td>{val.Operator}</td>
                        <td>{(val.Part_internalNo)}</td>
                        <td>{val.Customer}</td>
                        <td>{val.Target}</td>
                        <td>{(val.Scrap)}</td>
                        <td>{val.Produced}</td>
                        <td>{val.Supervisor_notes}</td>
                      </tr>
                    </tbody>
                  )
                })}
              </table>
              <button type='button' class="form-control form-rounded" onClick={toggleDiv}>Close</button>
            </div>
          </div> : null}
        </table> : null}
      </div>
    </div >
  )
}

export default GetReport;
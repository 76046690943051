// src/Dashboard.js
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Sidebar from './Sidebar';
import './Admin_dashboard.css';
import { Host } from '../App';
import '../Responsive.css';
import { Link } from 'react-router-dom';
import HamburgerMenu from '../MobileView/HamburgerMenu';


ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f6fa;
  padding: 20px;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const HeaderTitle = styled.h1`
  font-size: 24px;
  color: #333;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
`;

const Card = styled.div`
  background: ${(props) => props.bgColor || '#fff'};
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h2`
  font-size: 12px; /* Default font size */
  margin-bottom: 10px;

  @media (min-width: 600px) {
    font-size: 14px; /* Font size for small devices (600px and up) */
  }

  @media (min-width: 768px) {
    font-size: 16px; /* Font size for tablets (768px and up) */
  }

  @media (min-width: 1024px) {
    font-size: 18px; /* Font size for desktops (1024px and up) */
  }

  @media (min-width: 1200px) {
    font-size: 20px; /* Font size for larger desktops (1200px and up) */
  }
`;

const CardValue = styled.p`
  font-size: 12px; /* Default font size */
  font-weight: bold;

  @media (min-width: 600px) {
    font-size: 16px; /* Font size for small devices (600px and up) */
  }

  @media (min-width: 768px) {
    font-size: 18px; /* Font size for tablets (768px and up) */
  }

  @media (min-width: 1024px) {
    font-size: 20px; /* Font size for desktops (1024px and up) */
  }

  @media (min-width: 1200px) {
    font-size: 24px; /* Font size for larger desktops (1200px and up) */
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  margin-top:5%;

`;





const Admin_dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [Postartdate, setPoStartdate] = useState('2023-01-01');
  const [Poenddate, setPoenddate] = useState('2030-01-01');
  const [startdate, setStartdate] = useState();
  const [enddate, setenddate] = useState();
  const [postList, setPostList] = useState([]);
  var token = (window.localStorage.getItem("acessToken"));
  const [totalQtyShipped, setTotalQtyShipped] = useState(0);
  const [Totalraw, setTotalraw] = useState(0);
  var [Totalsupplies, setTotalsupplies] = useState(0);
  var totalRec = 0;
  var [MonthlySpending, setMonthlySpending] = useState(0);
  const [biweeklyraw, setBiweeklyraw] = useState(0);
  var [biweeklysupplies, setBiweeklysupplies] = useState(0);


  // empty data const for receivables 
  var [PoNo, setPoNo] = useState({});
  var [Supplier, setSupplier] = useState({});
  var [ItemNo, setItemNo] = useState("");
  var [order, setOrder] = useState("1");
  var [Item_Desc, setItem_Desc] = useState("");
  var [Category, setCategory] = useState({});
  var [Part_Desc, setPart_Desc] = useState({});

  const queryParams = {
    PoNo: "",
    PartNo: "",
    Postartdate: Postartdate,
    Poenddate: Poenddate,
    customer: "",
    order: "",
    section: "",
    token: token,
    Type: ""
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const today = new Date();
    const sdate = (new Date(today.getFullYear(), today.getMonth(), today.getDate()));
    const edate = (new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14));

    const formattedStartDate = formatDate(sdate);
    const formattedEndDate = formatDate(edate);
    setStartdate(formattedStartDate);
    setenddate(formattedEndDate);
  }, [])

  useEffect(() => {

    Axios.get(`https://` + Host + `/Total_Receivables/${token}`).then((data) => {
      setPostList(data.data)
      const totalQty = data.data.reduce((acc, val) => acc + (val.Unit_price * val.Remained_parts), 0);
      setTotalQtyShipped(totalQty.toFixed(2));
    })

    {/*    Axios.get(`http://localhost:3002/api/openorders/search`, { params: queryParams })
      .then((data) => {
        setPostList(data.data);

        // Calculate total quantity shipped
        const totalQty = data.data.reduce((acc, val) => acc + (val.Unit_price * val.Remained_parts), 0);
        setTotalQtyShipped(totalQty.toFixed(2));
        console.log(totalQty);
      }) 
      .catch((error) => {
        console.error('Error fetching data: ', error);
      }); */}
  }, []);
  useEffect(() => {


    Axios.get(`https://` + Host + `/api/Received_Raw_Material/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)}`).then((data) => {
      const totalQty = data.data.reduce((acc, val) => acc + (val.COST * val.Remained_parts), 0);
      setTotalraw(totalQty.toFixed(2));
    })
    Axios.get(`https://` + Host + `/api/Received_Supplies/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)},${JSON.stringify(Category)}`).then((data) => {
      const totalQty2 = data.data.reduce((acc, val) => acc + (val.Unit_cost * val.Remained_parts), 0);
      setTotalsupplies(totalQty2.toFixed(2));
    })
    if (startdate) {
      Axios.get(`https://` + Host + `/api/Received_Raw_Material/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${startdate},${enddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)}`).then((data) => {
        const totalQty = data.data.reduce((acc, val) => acc + (val.COST * val.Remained_parts), 0);
        setBiweeklyraw(totalQty.toFixed(2));
      })
    }
    if (enddate) {
      Axios.get(`https://` + Host + `/api/Received_Supplies/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${startdate},${enddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)},${JSON.stringify(Category)}`).then((data) => {
        const totalQty3 = data.data.reduce((acc, val) => acc + (val.Unit_cost * val.Remained_parts), 0);
        setBiweeklysupplies(totalQty3.toFixed(2));
      })
    }
    Axios.get(`https://` + Host + `/Monthlyspending`, { params: queryParams }).then((data) => {
      setMonthlySpending((data.data.reduce((acc, val) => acc + (val.Total_Amount), 0)).toFixed(2));

    })

  }, [startdate, enddate]);
  const totalReceivables = totalQtyShipped;
  const totalPayables = (Number(Totalraw) + Number(Totalsupplies)).toFixed(2);


  const data = {
    labels: ['Total Receivables', 'Total Payables'],
    datasets: [
      {
        data: [totalReceivables, totalPayables],
        backgroundColor: ['#8DC73F', '#36A2EB'],
        hoverBackgroundColor: ['#8DC73F', '#36A2EB'],
      },
    ],
  };



  function getCurrentMonthName() {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const currentMonthIndex = new Date().getMonth(); // getMonth() returns 0-11
    return monthNames[currentMonthIndex];
  }
  const currentMonthName = getCurrentMonthName();
  return (
    <DashboardContainer>
      <Header><center>
        <HeaderTitle>Admin Dashboard</HeaderTitle>
      </center>
      </Header>
      <br></br>
      <div class="row">
        <div class="col-2" id="mobileView">
          <Sidebar />
        </div>
        <div class="col-10">
          <div class="row">

            <CardContainer>
              <Card bgColor="#8DC73F" >
                <CardTitle>Total Receivables</CardTitle>

                <CardValue>${totalQtyShipped}</CardValue>
              </Card>
              <Card bgColor="#1E90FF">
                <CardTitle>Total Payables</CardTitle>
                <CardValue>${totalPayables}</CardValue>
              </Card>
              <Card bgColor="darkgray">
                <CardTitle>{`${currentMonthName} Spending`}</CardTitle>
                <CardValue>${MonthlySpending}</CardValue>
              </Card>
            </CardContainer>
          </div>
          <div class="row">

            <div class="col-8">
              <CardContainer>
                <Card bgColor="#8DC73F">
                  <CardTitle>Biweekly Receivables</CardTitle>

                  <CardValue>${biweeklyraw}</CardValue>
                </Card>
                <Card bgColor="#1E90FF">
                  <CardTitle>Biweekly Payables</CardTitle>
                  <CardValue>${biweeklysupplies}</CardValue>
                </Card>

              </CardContainer>
            </div>
            <div class="col-4" id='mobileView'>
              <ChartContainer>
                <Doughnut data={data} />
              </ChartContainer>
            </div>

          </div>
        </div>
      </div>



    </DashboardContainer>
  );
};

export default Admin_dashboard;

import React, { useState } from "react";
import "./Header.css";
import "./Responsive.css";
import real_logo from "./real_logo.png";
import { Link } from "react-router-dom";
import UserNameBlock from "./UserNameBlock";

function Header() {

  return (
    <div className="body">
      <nav className="header">
        <table>
          <tr>
            <td >
              <Link to="/Admin_dashboard">
                <img className="header_logo_lg" src={real_logo} alt="" />
              </Link>
            </td>
            <td></td>
          </tr>
        </table>
      </nav>

    </div>
  );
}

export default Header;

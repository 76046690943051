import React, { useState, useEffect } from 'react';
import Chart from 'react-google-charts';
import Axios from 'axios';
import { Host } from '../App';
import { host } from '../App';
import './LineGraph.css';
import Button from '@mui/material/Button';
import { Link, Redirect, useParams } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const MultiLineChart = () => {
  const [filter, setFilter] = useState('weekly');
  const [partNo, setPartNo] = useState([]);

  const [weekPeriod, setWeekPeriod] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [postList, setPostList] = useState([]);
  const [Operator, setOperator] = useState({});
  const [Date1, setDate1] = useState(new Date());
  const [startdate, setStartdate] = useState('2023-01-01');
  const [enddate, setEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [customer, setCustomer] = useState({});
  const [partInternalNo, setPartInternalNo] = useState({});
  const [operators, setoperators] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  var token = (window.localStorage.getItem("acessToken"));
  var [group, setGroup] = useState("Part_internalNo");
  const [Station, setStation] = useState({});
  const [Machine_list, setMachine_list] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [showPartdata, setShowPartdata] = useState(true);
  const [showOperatordata, setShowOperatordata] = useState(false);


  const getFilteredData = () => {
    switch (filter) {
      default:
        return generateWeeklyData();
    }
  };

  const generateWeeklyData = () => {
    const data = [['x', 'SUM(Produced)']];
    const weekData = partNo.slice(-7 * weekPeriod);

    weekData.forEach((item) => {
      const date = new Date(item.Date);
      date.setDate(date.getDate() + 1); // Add 1 day to adjust the date
      const label = `${date.getDate()} ${getMonthName(date.getMonth())}`;
      data.push([label, item['SUM(Produced)']]);
    });

    return data;
  };


  const getMonthName = (monthIndex) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    return months[monthIndex];
  };
  const handleWeekPeriodChange = (e) => {
    setWeekPeriod(parseInt(e.target.value, 10));
  };
  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const today = new Date();
    let startDate, endDate;

    if (selectedOption === 'lastWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    } else if (selectedOption === 'thisWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    } else if (selectedOption === 'lastMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (selectedOption === 'thisMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (selectedOption === 'lastYear') {
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today.getFullYear() - 1, 11, 31);
    } else if (selectedOption === 'thisYear') {
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
    } else if (selectedOption === 'nextWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (13 - today.getDay()));
    } else if (selectedOption === 'nextMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    } else if (selectedOption === 'nextYear') {
      startDate = new Date(today.getFullYear() + 1, 0, 1);
      endDate = new Date(today.getFullYear() + 1, 11, 31);
    } else {
      // Handle other options if needed
    }

    setStartdate(startDate);
    setEnddate(endDate);
  };


  const renderWeekPeriodInput = () => {
    return (
      <div>
        <div id="linegraph" >
          <div class="row">
            <div class="col-md-auto">
              {/* Grab the particular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Operator' list="operators" onChange={(e) => {
                setOperator(e.target.value)
              }} onSelect={search} />
              <datalist id="operators">
                <option></option>
                {operators.map(x => {
                  return <option>{x.Morning_Operators}</option>
                })}
              </datalist>

            </div>
            <div class="col-md-auto">
              <select class="form-control form-rounded" onChange={handleSelectChange}>
                <option value="">Select week</option>
                <option value="lastWeek">Last Week</option>
                <option value="thisWeek">This Week</option>
                <option value="lastMonth">Last Month</option>
                <option value="thisMonth">This Month</option>
                <option value="nextWeek">Next Week</option>
                <option value="nextMonth">Next Month</option>
              </select>

            </div>
            <div class="col-md-auto">
              <div class="row" style={{ "width": "auto" }} id='hidrow'>
                <div class="col-md-auto">
                  {/* Grab the particular dates */}
                  <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={startdate} onChange={(e) => {
                    setStartdate(e.target.value)
                  }} onSelect={search} />
                  {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                </div>
                <br />
                <br />
                <div class="col-md-auto">
                  <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                    setEnddate(e.target.value)
                  }} onSelect={search} />
                </div>
              </div>
            </div>
            <div class="col-md-auto">
              <input
                type="number"
                id="weekPeriod"
                value={weekPeriod}
                class="form-control form-rounded"
                onChange={handleWeekPeriodChange}
                min="1"
              />
              <label>Week Period</label>
            </div>
            <div class="col-md-auto">

              {/* Grab the particular Customer */}
              <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customers' onChange={(e) => {
                setCustomer(e.target.value)
              }} onSelect={search} />
              <datalist id="Customers">
                <option></option>
                {Customers.map(x => {
                  return <option>{x.Customers}</option>
                })}
              </datalist>


            </div>
            <div class="col-md-auto">
              {/* Grab the particular partInternalNo */}
              <input type='text' class="form-control form-rounded" placeholder='Internal Part Number' list='InternalPartNo' onChange={(e) => {
                setPartInternalNo(e.target.value)
                console.log("svgdh");
              }} onSelect={search} />
              <datalist id="InternalPartNo">
                <option></option>
                {part_dataset.map(x => {
                  return <option>{x.Part_internalNo}</option>
                })}
              </datalist>
            </div>
            <div class="col-md-auto">
              {/* Grab the particular partInternalNo */}
              <input type='text' class="form-control form-rounded" placeholder='Work Station' list="Work_station" onChange={(e) => {
                setStation(e.target.value)

              }} onSelect={search} />
              <datalist id="Work_station">
                <option></option>
                {Machine_list.map(x => {
                  return <option>{x.Machines}</option>
                })}
              </datalist>
            </div>
            <div class="col-md-auto">
              <label for="Operators">Operator</label><br></br>
              <input type="radio" name='shift' id='Operators' value="Operators" onClick={(e) => {
                setShowPartdata(false);
                setShowOperatordata(true);
                setGroup("Operator");
                search();
              }} />
              {/*<input type="radio" id="Morning" name="Shift" value="Morning"  />*/}
            </div>
            <div class="col-md-auto">
              <label for="Part No">Part No</label><br></br>
              <input type="radio" name='shift' id='Part No' onClick={(e) => {
                setShowOperatordata(false);
                setShowPartdata(true);
                setGroup("Part_internalNo");
                //group = "PartNo";
                search();
              }} defaultChecked />
            </div>
          </div>
        </div>

      </div>
    );
  };
  // to retreive Machines
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Machines/${token}`).then((data) => {
      setMachine_list(data.data)
    });
  }, [])

  // to retreive operators
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Operators/${token}`).then((data) => {

      setoperators(data.data)
    });
  }, [])

  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Customers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])


  // to retreive part dataset
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)

    });
  }, [])

  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/getreport/searchgraph/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${JSON.stringify(Station)},${token}`).then((data) => {
      setPartNo(data.data)
    })
  }, [Station, Operator, startdate, enddate, customer, partInternalNo])
  const search = () => {
    Axios.get(`https://` + host + `/realapi/getreport/searchgraph/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${JSON.stringify(Station)},${token}`).then((data) => {
      setPartNo(data.data)
    })
    console.log(partNo);

  }

  return (
    <div id="linegraph">
      <h2>Daily Data Record Line Graph</h2><br />
      {renderWeekPeriodInput()}
      <center>
        <Chart
          width={'1500px'}
          height={'410px'}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={getFilteredData()}
          options={{
            hAxis: {
              title: 'Date',
            },
            vAxis: {
              title: 'SUM(Produced)',
            },
            series: {
              0: { curveType: 'function' },
            },
            interpolateNulls: true,
          }}
          rootProps={{ 'data-testid': '2' }}
        />
      </center>
      <Link to="/Morning">
        <Button variant="contained" size="large" endIcon={<KeyboardDoubleArrowRightIcon />}>
          Proceed To Enter Data
        </Button>
      </Link>
    </div>
  );
};

export default MultiLineChart;



import React, { useState, useEffect } from 'react'
import App, { host } from '../App';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DynamicTable from './Table_format';
import { saveAs } from 'file-saver';
import { Document, Paragraph, Table, Packer, Section } from 'docx';
import { TableRow, TableCell } from 'docx';
import jsPDF from 'jspdf';
import Axios from 'axios'
import moment from 'moment';
import './Pivot.css';
import { Host } from '../App';

const options = [
  { label: "  KEILHAUER  ", value: "KEILHAUER" },
  { label: "  SPEC  ", value: "SPEC" },
  { label: "  9 To 5  ", value: "9 To 5" },
  { label: "  STYLEX  ", value: "STYLEX" },
  { label: "  GEIGER  ", value: "GEIGER" },
  { label: "  JASPER  ", value: "JASPER" },
  { label: "  EXEMPLIS  ", value: "EXEMPLIS" },
  { label: "STUDIO TK", value: "STUDIO TK" },
  { label: "MARTIN BRATTRUD", value: "MARTIN BRATTRUD" },
  { label: "Borgo", value: "Borgo" },
  { label: "ALLSEATING", value: "ALLSEATING" },
  { label: "Compsit-HBF", value: "Compsit-HBF" },
  { label: "ARCONAS", value: "ARCONAS" },
  { label: "Davis Furniture", value: "Davis Furniture" },
  { label: "Nemchoff", value: "Nemchoff" },
  { label: "TEKNION", value: "TEKNION" },
  { label: "Leggett And Platt", value: "Leggett And Platt" },
  { label: "TUOHY", value: "TUOHY" },
  { label: "Kotobuki Seating", value: "Kotobuki Seating" },
  { label: "AUTOMATED ROUTING INC", value: "AUTOMATED ROUTING INC" },
  { label: "DEPENDABLE TRUCK AND TANK LIMITED", value: "DEPENDABLE TRUCK AND TANK LIMITED" },
];

const generateDocx = (data) => {
  const doc = new Document({
    creator: 'Your Name'
  });


  // Add a table to the document to display the data
  const table = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph('Name')],
          }),
          new TableCell({
            children: [new Paragraph('Email')],
          }),
          new TableCell({
            children: [new Paragraph('Phone')],
          }),
        ],
      }),
      ...data.map((item) =>
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(item.name)],
            }),
            new TableCell({
              children: [new Paragraph(item.email)],
            }),
            new TableCell({
              children: [new Paragraph(item.phone)],
            }),
          ],
        })
      ),
    ],
  });

  doc.addSection({
    children: [table],
  });

  // Download the document as a file
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, 'data.docx');
  });
};
var filteredData = 0;

export const Data = (props) => {
  const [options, setoptions] = useState([]);
  var [postList, setPostList] = useState([{
    "PO_NO": " ",
    "PART_NO": " ",
    "QTY": "",
    "Remained_parts": " ",
    "PO_SHIP_DATE": " ",
    "PART_DESCRIPTION": " ",
    "CUSTOMER": " ",
    "COMMENTS": "",
  }]);
  var [partdetailedList, setPartdetailedList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [Date1, setDate1] = useState(new Date());
  const [Postartdate, setPoStartdate] = useState(Date1.toISOString().slice(0, 10));
  const [Pofinaldatedate, setPoFinaldate] = useState(moment(Postartdate).add(6, 'days').format('YYYY-MM-DD'));
  const [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [isLoading, setIsLoading] = useState(false);
  var token = (window.localStorage.getItem("acessToken"));

  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  const queryParams = {
    start_date: Postartdate,
    end_date: Poenddate,
    selectedOptions: selectedOptions,
    token: token
  };
  useEffect(() => {
    //console.log(token);

  }, [])
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      Axios.get(`https://` + Host + `/pivot`, { params: queryParams }).then((data) => {
        if (data.data === "Error") {
          setIsLoading(false);
        }
        else {
          if (data.data[5].length > 0) {
            setPostList(data.data[5]);
          } else if (data.data[5].length <= 0) {
            window.alert("No Data Received!")
            setPostList([{
              "PO_NO": " ",
              "PART_NO": " ",
              "QTY": "",
              "Remained_parts": " ",
              "PO_SHIP_DATE": " ",
              "PART_DESCRIPTION": " ",
              "CUSTOMER": " ",
              "COMMENTS": "",
            }]);
          }
        }
        setIsLoading(false);
      }, 2000);
    });
  }, [selectedOptions, Poenddate, Postartdate, token])

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/Customers/${token}`).then((response) => {
      const formattedCustomers = response.data.map((customerObj) => ({
        label: `${customerObj.Customer}`,
        value: customerObj.Customer,
      }));
      const customerNames = response.data.map(item => item.Customer);
      setSelectedOptions(customerNames);
      setoptions(formattedCustomers);
    });
  }, []);

  const headers = Object.keys(postList[0]);

  const filteredHeaders = headers.filter((header) => {
    return header !== 'PO_NO' && header !== 'PART_NO' && header !== 'PART_DESCRIPTION';
  });

  filteredData = postList.map((post) => {
    const filteredPost = {};
    filteredHeaders.forEach((header) => {
      if (header === 'PO_SHIP_DATE') {
        // slice the PO_SHIP_Date column value to the first 10 characters
        filteredPost[header] = post[header];
      } else {
        filteredPost[header] = post[header];
      }
    });
    return filteredPost;
  });

  const downloadCSV = () => {
    const filename = "Pivot";
    // Convert data to CSV string
    const csv = XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(filteredData));
    // Create a Blob object and download the file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(blob, filename);
    //console.log(postList);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const tableData = [];

    // Add title and date to the PDF
    doc.setFontSize(18);
    const title = "Realform Technologies";
    doc.text(title, 70, 20);
    const today = new Date().toLocaleDateString();
    doc.text(today, 70, 30);

    // Loop through the filtered data and add it to the tableData array
    filteredData.forEach((post) => {
      const rowData = [];
      headers.forEach((header) => {
        if (header !== 'PO_NO' && header !== 'PART_NO' && header !== 'PART_DESCRIPTION') {
          rowData.push(post[header]);
        }
      });
      tableData.push(rowData);
    });

    // Set the table headers
    const headersPDF = headers.filter((header) => header !== 'PO_NO' && header !== 'PART_NO' && header !== 'PART_DESCRIPTION');

    // Add the table to the document
    doc.autoTable({
      head: [headersPDF],
      body: tableData,
    });

    // Download the PDF file
    doc.save('Pivot.pdf');
  };

  const handleSelectAll = () => {
    setSelectedOptions(options.map((option) => option.value));
  };

  const handleDeselectAll = () => {
    setSelectedOptions([]);

  };

  const handleOptionChange = (optionValue) => {
    if (selectedOptions.includes(optionValue)) {
      setSelectedOptions(selectedOptions.filter((value) => value !== optionValue));
    } else {
      setSelectedOptions([...selectedOptions, optionValue]);
    }


  };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const today = new Date();
    let startDate, endDate;

    if (selectedOption === 'lastWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    } else if (selectedOption === 'thisWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    } else if (selectedOption === 'lastMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (selectedOption === 'thisMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (selectedOption === 'lastYear') {
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today.getFullYear() - 1, 11, 31);
    } else if (selectedOption === 'thisYear') {
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
    } else if (selectedOption === 'nextWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (13 - today.getDay()));
    } else if (selectedOption === 'nextMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    } else if (selectedOption === 'nextYear') {
      startDate = new Date(today.getFullYear() + 1, 0, 1);
      endDate = new Date(today.getFullYear() + 1, 11, 31);
    } else if (selectedOption === 'nextQuarter') {
      const currentMonth = today.getMonth();
      const currentQuarter = Math.floor(currentMonth / 3) + 1;
      const nextQuarterStartMonth = (currentQuarter * 3) % 12; // Start month of the next quarter
      const nextQuarterEndMonth = nextQuarterStartMonth + 2; // End month of the next quarter

      // If the next quarter starts next year, adjust the year accordingly
      const nextQuarterYear = nextQuarterStartMonth === 0 ? today.getFullYear() + 1 : today.getFullYear();

      startDate = new Date(nextQuarterYear, nextQuarterStartMonth, 1);
      endDate = new Date(nextQuarterYear, nextQuarterEndMonth + 1, 0);
    } else if (selectedOption === 'currentQuarter') {
      const currentMonth = today.getMonth();
      const currentQuarter = Math.floor(currentMonth / 3) + 1;
      const currentQuarterStartMonth = (currentQuarter - 1) * 3; // Start month of the current quarter
      const currentQuarterEndMonth = currentQuarterStartMonth + 2; // End month of the current quarter

      startDate = new Date(today.getFullYear(), currentQuarterStartMonth, 1);
      endDate = new Date(today.getFullYear(), currentQuarterEndMonth + 1, 0);
    } else if (selectedOption === 'nextDay') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
    } else {
      // Handle other options if needed
    }

    setPoStartdate(startDate);
    setPoEnddate(endDate);

  };
  const print = () => {
    console.log(options);
  }
  return (
    <div className="MainPage_data">
      <div >
        <div class="Container">
          <div>
            <div>
              <br />
              <div className="container selection-container">
                <div className='row'>
                  <label className='selection-label'>
                    <input type="checkbox" checked={selectedOptions.length === options.length} onChange={handleSelectAll} />
                    Select all
                  </label>
                  <label className='selection-label'>
                    <input type="checkbox" checked={selectedOptions.length === 0} onChange={handleDeselectAll} />
                    Deselect all
                  </label>
                </div>
                <div className="row">
                  {options.map((option) => (
                    <div key={option.value} className="col-6 col-sm-4 col-md-3 col-lg-2 selection-item">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(option.value)}
                          onChange={() => handleOptionChange(option.value)}
                        />
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <br />
          </div>
          <div class="row">
            <div class="col-3">
              <div class="row">
                <label>Choose Ship Dates</label>
                <div class="col" id='moldng'>
                  {/* Grab the particular dates */}
                  <input type='date' class="form-control form-rounded" placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                    setPoStartdate(e.target.value)
                  }} />
                  {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                  <span class="tooltiptext">Filter By PO SHIP DATE </span>
                </div>
                <div class="col">
                  <input type='date' class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                    setPoEnddate(e.target.value)
                  }} />
                </div>
                <div class="ROW">
                  <select class="form-control form-rounded" onChange={handleSelectChange}>
                    <option value="">Select</option>
                    <option value="lastWeek">Last Week</option>
                    <option value="thisWeek">This Week</option>
                    <option value="lastMonth">Last Month</option>
                    <option value="thisMonth">This Month</option>
                    <option value="nextWeek">Next Week</option>
                    <option value="nextMonth">Next Month</option>
                    <option value="currentQuarter">Current Quarter</option>
                    <option value="nextQuarter">Next Quarter</option>
                    <option value="nextDay">Next Day</option>

                  </select>

                </div>

              </div>
            </div>
            <div class="col-5">
            </div>
            <div class="col-4">
              <button class="btn btn-success" onClick={downloadCSV} id='mobileView'>
                Download As CSV
              </button>
              <button className="btn btn-primary" onClick={generatePDF} id='mobileView'>Download PDF</button>
            </div>
            <DynamicTable postList={postList} />
            <div class="col-7"></div>
            <div class="col-1">

            </div>
          </div>
          <br></br>
          <br></br>

        </div>
        {isLoading ?
          <div className='overlay'>
            <div className="loading-container">
              <div className="loading-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <p className='loading'>Loading</p>
            </div>
          </div> : null}
      </div>

    </div >
  )
}

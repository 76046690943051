import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import Clipboard from 'clipboard';
import real_l from "./real_logo.png";
import { Host } from '../../App';
import './Pivot.css';
import { HotTubSharp } from '@mui/icons-material';

function DynamicTable({ postList, section }) {
  var token = (window.localStorage.getItem("acessToken"));
  var User = JSON.parse(window.localStorage.getItem("Name"));
  const [date, setDate] = useState(new Date().toISOString());
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();
  const headers = Object.keys(postList[0]);
  var [partdetailedList, setPartdetailedList] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [showUpholstery, setshowUpholstery] = useState(false);

  const Targeted_part_data = (Item_No) => {
    setShowDiv(true);
    Axios.get(`https://` + Host + `/supplies/pivot/getitemdetails`, {
      params: {
        Item_No, token
      }
    }).then((data) => {
      setPartdetailedList(data.data)
    });
  }
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    return `${day} ${month}`;
  };

  // Example usage

  const handlePrint = () => {
    const table = document.querySelector('#alldata');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          table tr:last-child td {
            font-weight: bold;
            background-color: lightgrey; /* Add light grey background color */
          }
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <table class="table table-striped">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };

  const uniqueSuppliers = [...new Set(postList.map((post) => (post.Supplier)))];
  var cust_length = uniqueSuppliers.length;
  const totalSum = postList.reduce((acc, post) => acc + post.Total, 0);
  const zeroColumns = headers.filter((header) => {
    const values = postList.map((post) => post[header]);
    return values.every((value) => value === 0);
  });

  // Remove the zero columns from the headers and postList arrays
  const filteredHeaders = headers.filter((header) => !zeroColumns.includes(header));
  const filteredPostList = postList.map((post) => {
    const filteredPost = {};
    Object.entries(post).forEach(([key, value]) => {
      if (!zeroColumns.includes(key)) {
        filteredPost[key] = value;
      }
    });
    return filteredPost;
  });
  const toggleUpholstery = () => {
    setshowUpholstery(!showUpholstery);
  }

  return (<div>
    <center>
      <button className="btn btn-primary" onClick={handlePrint}>Print Table</button>
      <button className="btn btn-light" onClick={toggleUpholstery}>{showUpholstery ? 'Hide Po No' : 'Show Po No'}</button>
      <div id="alldata">
        <p>
          <center>
          </center>
          <table id="tableData" class="table table-bordered">
            <thead>
              <tr>
                {filteredHeaders.map((header) => (
                  header === 'PO_No' || header === 'Item_No' || header === 'ETA' || header === 'Supplier' || header === 'Part_attached' || header === 'Item_Description' ? null : (
                    <th key={header} className='header_table'>{header}</th>
                  )
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredPostList.map((post) => (

                <tr key={post.PO_No + post.Item_No}>
                  {filteredHeaders.map((header) => (
                    header === 'PO_No' || header === 'Item_No' || header === 'ETA' || header === 'Supplier' || header === 'Part_attached' || header === 'Item_Description' ? null : (
                      <td key={`${post.PO_No}_${header}`}>
                        {header === 'Description' ? (
                          <span className='tooltip-container' id='pivotj'>
                            <p className='Description'>
                              <a href="#" className='description_link' style={{ "text-decoration": "none" }} onClick={() => { Targeted_part_data(post.Item_No) }}>{(post.Description)}</a> </p>{showUpholstery ? <span className='tooltiptext'> <div>{post.PO_No}</div> </span> : null}</span>

                        ) : (
                          header === 'ETA' ? (
                            post[header].slice(0, 8)

                          ) : (
                            post[header] === 0 ? '' : post[header]
                          )
                        )}
                      </td>
                    )))}
                </tr>

              ))}
              <tr class="Total">

                {filteredHeaders.map((header) => (
                  header === 'PO_No' || header === 'Item_No' || header === 'ETA' || header === 'Supplier' || header === 'Part_attached' || header === 'Item_Description' ? null : (
                    <td key={`total_${header}`}>
                      {header === 'Description' ? 'Total' : (
                        filteredPostList
                          .reduce((sum, post) => sum + parseFloat(post[header]), 0)
                      )}
                    </td>
                  )
                ))}</tr>
              {/* <tr class="Total">Total : {filteredPostList.filter((post) => post.Supplier === Supplier).reduce((sum, post) => sum + parseFloat(post.Total), 0)}</tr> */}

            </tbody>
            {showDiv ? <div class='overlay'>
              <div class='box' >
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>PO NO</th>
                      <th>ITEM NO</th>
                      <th>ITEM DESCRIPTION</th>
                      <th>SUPPLIER</th>
                      <th>ETA</th>
                      <th>REMAINED PARTS</th>
                      <th>COMMENTS</th>
                    </tr>
                  </thead>
                  {partdetailedList.map((val, key) => {
                    return (
                      <tbody>
                        <tr>
                          <td>{val.PO_No}</td>
                          <td>{val.Item_No}</td>
                          <td >{val.Item_Description}</td>
                          <td >{val.Supplier}</td>
                          <td>{(val.ETA).slice(0, 10)}</td>
                          <td>{val.Remained_parts} {val.Unit}</td>
                          <td>{val.COMMENTS}</td>
                        </tr>
                      </tbody>
                    )
                  })}
                </table>
                <button type='button' class="form-control form-rounded" onClick={toggleDiv}>Close</button>
              </div>
            </div> : null}
          </table></p>

      </div>
    </center>
  </div>
  );
}

export default DynamicTable;
<script src="dist/clipboard.min.js"></script>
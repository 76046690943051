import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"

import { host, Host } from '../App';
import { CheckBox } from '@mui/icons-material';

function Attendance_details() {
  const [postList, setPostList] = useState([]);
  const [Operator, setOperator] = useState({});
  const [Date1, setDate1] = useState(new Date());
  const [startdate, setStartdate] = useState(Date1.toISOString().slice(0, 10));
  const [enddate, setEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [Department, setDepartment] = useState({});
  const [Status, setStatus] = useState({});
  const [operators, setoperators] = useState([]);
  const [Departments, setDepartments] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  var token = (window.localStorage.getItem("acessToken"));
  const [selectedRows, setSelectedRows] = useState({});
  const [editable, seteditable] = useState(true);
  var Ranked = (window.localStorage.getItem("Rank"));
  const [rank, setrank] = useState(false);

  var [Newstatus, setNewstatus] = useState("");
  var [Newdetails, setNewdetails] = useState("");
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });
  useEffect(() => {
    if (Ranked === '"Admin"') {
      setrank(true);
    }
  }, [Ranked]);
  // Default Data 
  useEffect(() => {
    Axios.get(`https://` + Host + `/attendance_detail/search/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(Department)},${JSON.stringify(Status)},${token}`).then((data) => {
      setPostList(data.data)
    })
  }, [])

  // to retreive operators
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/employees_all/${token}`).then((data) => {
      setoperators(data.data)
    });
  }, [])

  // to retreive Departments
  useEffect(() => {
    Axios.get(`https://` + Host + `/get/Department/${token}`).then((data) => {
      setDepartments(data.data)
    });
  }, [])


  // method to retrieve data combinely
  const search = () => {
    Axios.get(`https://` + Host + `/attendance_detail/search/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(Department)},${JSON.stringify(Status)},${token}`).then((data) => {
      setPostList(data.data)
    })
  }
  // method to retrieve data combinely
  const Delete = (date, operator, Department) => {
    Axios.delete(`https://` + host + `/attendance_details/delete/${date},${operator},${Department},${token}`).catch(function (error) {
      console.log(error.response);
    })
    setTimeout(function () {
      search();
    }, 2000);

  }
  // method to update the attendance
  const Update = (date, operator, Department, Status, Details) => {

    if (Newstatus && !Newdetails) {
      Newdetails = Details
    }
    if (!Newstatus && Newdetails) {
      Newstatus = Status;
    }
    if (Newstatus && Newdetails) {
    }
    console.log(Newstatus + Newdetails);
    Axios.post(`https://` + host + `/attendance_details/update`, { date: date, operator: operator, Department: Department, Status: Newstatus, Details: Newdetails, token: token }).then((response) => {
      if (response.status === 200) {
        Newstatus = "";
        Newdetails = "";
        window.alert(response.data.message);
        setTimeout(function () {
          search();
          seteditable(!editable)
        }, 2000);
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });
  }
  return (
    <div className="MainPage_data">
      <br></br>

      <h2>Attendance Details</h2>

      <center></center>
      <div class="Container">
        <div class="row">
          <div class="col-md-auto">
            {/* Grab the particular Operator name */}
            <input type='text' class="form-control form-rounded" placeholder='Employee' list="operators" onChange={(e) => {
              setOperator(e.target.value)
            }} onSelect={search} />
            <datalist id="operators">
              <option></option>
              {operators.map(x => {
                return <option>{x.Name}</option>
              })}
            </datalist>
          </div>
          <div class="col-md-auto">
            <div class="row" id='hidrow'>
              <div class="col-md-auto">
                {/* Grab the particular dates */}
                <input type='date' class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setStartdate(e.target.value)
                }} onSelect={search} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
              </div>
              <div class="col-md-auto">
                <input type='date' class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setEnddate(e.target.value)
                }} onSelect={search} />
              </div>
            </div>
          </div>
          <div class="col-md-auto">
            {/* Grab the particular Department */}
            <input type='select' class="form-control form-rounded" placeholder='Department' list='Departments' onChange={(e) => {
              setDepartment(e.target.value)
            }} onSelect={search} />
            <datalist id="Departments">
              <option></option>
              {Departments.map(x => {
                return <option>{x.Department}</option>
              })}
            </datalist>

          </div>
          <div class="col-md-auto">
            {/* Grab the particular Status */}
            <input type='text' class="form-control form-rounded" placeholder='Status' list='Status' onChange={(e) => {
              setStatus(e.target.value)
            }} onSelect={search} />
            <datalist id="Status">
              <option>Present</option>
              <option>Absent</option>

            </datalist>
          </div>
        </div>
        <div id="Attendance_edit">
          <Link to="/attendance">
            <button type='button' class="btn btn-success">
              <span class="glyphicon glyphicon-star" aria-hidden="true"></span> Back</button>
          </Link>
          {rank ? <button type="button" class="btn btn-primary" onClick={() => { seteditable(!editable) }} >Edit</button> : null}
        </div>
        <div id='attendance_details'>
          <table class="table table-bordered" >
            <thead>
              <tr>
                <th>Date</th>
                <th>Employee</th>
                <th>Department</th>
                <th>Status</th>
                <th>Leave Type</th>
                <th>Details</th>
                <th>Posted By</th>
                {editable ? <div>{rank ? <th>Delete</th> : null}</div> : <div>{rank ? <th>Update</th> : null}</div>}
              </tr>
            </thead>
            {postList.map((val, key) => {
              return (
                <tbody id="tbody">
                  <tr key={key}>
                    <td>{val.Date.slice(0, 10)}</td>
                    <td>{val.Employee_Name}</td>
                    <td>{val.Department}</td>
                    {editable ? <td>{val.Status}</td> : <td><input type='text' class="form-control form-rounded" list='Status' Value={val.Status} onChange={(e) => { setNewstatus(e.target.value) }} /></td>}
                    <td>{val.LeaveType}</td>
                    {editable ? <td>{val.Details}</td> : <td><input type='text' class="form-control form-rounded" Value={val.Details} onChange={(e) => { setNewdetails(e.target.value) }} /></td>}
                    <td>{(val.User).replace(/[{""}]/g, '')}</td>
                    {editable ? <td>{rank ? <button class="btn btn-danger" onClick={() => { window.confirm('Are you sure you want to delete this Entry?',) && Delete((val.Date.slice(0, 10)), (val.Employee_Name), (val.Department)) }}> Delete</button> : null}</td> : <td>{rank ? <button class="btn btn-primary" onClick={() => { window.confirm('Are you sure you want to Update this Entry?',) && Update((val.Date.slice(0, 10)), (val.Employee_Name), (val.Department), (val.Status), (val.Details)) }}> Update</button> : null}</td>}
                  </tr>
                </tbody>
              )
            })}
          </table>
        </div>
      </div >
    </div >
  )
}

export default Attendance_details;
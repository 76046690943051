import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import "../src/ShippingData.css";
import { host, Host } from './App';
import { loadCustomers } from './Dashboard';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

//import { Customers } from './Dashboard';

function ShippingData() {
  var [del, setdel] = useState(1);
  var int = 4;
  var [postList, setPostList] = useState([]);
  var [PoNo, setPoNo] = useState({});
  var [poNo, setpoNo] = useState([]);
  var [PartNo, setPartNo] = useState({});
  var [Part_Desc, setPart_Desc] = useState({});
  var [Date1, setDate1] = useState(new Date());
  var [Postartdate, setPoStartdate] = useState('2023-01-01');
  var [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  var [customer, setCustomer] = useState({});
  const [comment, setComment] = useState({});
  var [ActualShipdate, setActualShipdate] = useState(Date1.toISOString().slice(0, 10));
  var [ShippedQty, setShippedQty] = useState("");
  var [COMENTS, setComments] = useState("Comment");
  const [openorder, setopenorder] = useState("1");
  var [Customers, setCustomers] = useState([]);
  var [partNo, setpartNo] = useState([]);
  var [partDataset, setpartDataset] = useState([]);

  var [order, setOrder] = useState("1");
  var [test, setTest] = useState(int);

  const [updateData, setUpdateData] = useState([]);
  const updateHandler = (PO_NO, PART_NO, PO_SHIP_DATE, PART_DESCRIPTION, CUSTOMER, COMMENTS) => {
    setUpdateData((prevupdateData) => {
      return [
        ...prevupdateData,
        { PO_NO, PART_NO, PO_SHIP_DATE, PART_DESCRIPTION, CUSTOMER, ActualShipdate, ShippedQty, COMMENTS, Part_Desc },
      ];
    });
  };
  //var token = sessionStorage.getItem("token");
  var token = (window.localStorage.getItem("acessToken"));
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });
  // quick refresh data
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/Shipping/search/${JSON.stringify(PoNo)},${JSON.stringify(PartNo)},${Postartdate},${Poenddate},${JSON.stringify(customer)},${order},${token},${JSON.stringify(Part_Desc)}`).then((data) => {
      setPostList(data.data)
    })
  }, [PoNo, PartNo, Postartdate, Poenddate, customer, order, del, Part_Desc])
  //to retreive po no
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/pono/${token}`).then((data) => {
      setpoNo(data.data)
    });
  }, [])
  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/Customers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/partNo/${token}`).then((data) => {
      setpartNo(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/part_dataset/${token}`).then((data) => {
      setpartDataset(data.data)
    });
  }, [])
  // method to retrieve data combinely
  const search = () => {
    Axios.get(`https://` + Host + `/api/Shipping/search/${JSON.stringify(PoNo)},${JSON.stringify(PartNo)},${Postartdate},${Poenddate},${JSON.stringify(customer)},${order},${token},${JSON.stringify(Part_Desc)}`).then((data) => {
      setPostList(data.data)
    })
  }
  const Update = () => {
    setdel(del = del + 1)
    Axios.post(`https://` + host + `/api/Shippeddata/Updatetry`, { updateData, token: token })
      .then((response) => {
        if (response.status === 200) {
          const numberInputs = document.querySelectorAll('input[type="number"]');
          numberInputs.forEach(input => {
            input.value = '';
          });
          toast.success(response.data.message, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
            style: { fontSize: '24px', borderRadius: '10px' },
          });
          search();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });

  };

  const Delete = (PO_NO, PART_NO) => {
    setdel(del = del + 1)
    Axios.delete(`https://` + Host + `/api/Shippeddata/Delete/${PO_NO},${PART_NO},${token}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message, {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
            style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
          });
          search();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });

  };
  const Order = () => {
    console.log("Order called")
  }
  return (
    <div className="MainPage_data">
      <div class="Container">
        <ToastContainer />
        <div class="row">
          <div class="col">
            <div class="row">
              {/* Grab the particular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='PO NO' list="PoNo" onChange={(e) => {
                setPoNo(e.target.value)
              }} onSelect={search} />
              <datalist id="PoNo">
                <option></option>
                {poNo.map(x => {
                  return <option>{x.PO_NO}</option>
                })}
              </datalist>
            </div>
            <div class="row">
              <br></br>
              <Link to="/UpdatePOData">
                <input
                  type="button"
                  style={{ "width": "100 %" }}
                  class="btn btn-success"
                  defaultValue="Update PO Data"
                  name="Section"
                  id="btn"
                />
              </Link>
            </div>
          </div>
          <div class="col">
            <div className='row'>
              {/* Grab the particular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='PART NO' list="PartNo" onChange={(e) => {
                setPartNo(e.target.value)
              }} onSelect={search} />
              <datalist id="PartNo">
                <option></option>
                {partNo.map(x => {
                  return <option>{x.Part_No}</option>
                })}
              </datalist>
            </div>
            <div className='row'>
              {/* Grab the particular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='PART DESCRIPTION' list="Partdesc" onChange={(e) => {
                setPart_Desc(e.target.value);
              }} onSelect={search} />
              <datalist id="Partdesc">
                <option></option>
                {partDataset.map(x => {
                  return <option>{x.Part_Description}</option>
                })}
              </datalist>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col" id='moldng'>
                {/* Grab the particular dates */}
                <input type='date' class="form-control form-rounded" placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                  setPoStartdate(e.target.value)
                }} onSelect={search} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                <span class="tooltiptext">Filter By PO SHIP DATE </span>

              </div>
              <div class="col">
                <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setPoEnddate(e.target.value)
                }} onSelect={search} />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              {/* Grab the particular Customer */}
              <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customers' onChange={(e) => {
                setCustomer(e.target.value)
              }} onSelect={search} />
              <datalist id="Customers">
                <option></option>
                {Customers.map(x => {
                  return <option>{x.Customer}</option>
                })}
              </datalist>
            </div>
            <div class="row">
              <select
                type="select"
                class="form-control form-rounded"
                id="order"
                name="order"
                onChange={(e) => {
                  setOrder(e.target.value);
                  order = e.target.value;
                  search();
                }}
              >
                <option value="1">Open Orders</option>
                <option value="0"> Open/Closed Orders</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <Link to="/shippedparts">
                <input
                  type="button"
                  class="btn btn-success"
                  defaultValue="Review Shipped Data"
                  name="Section"
                  id="btn"
                />
              </Link>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>PO NO</th>
              <th>PART NO</th>
              <th>PO SHIP DATE</th>
              <th>PART DESCRIPTION</th>
              <th>CUSTOMER</th>
              <th>PO QTY</th>
              <th>REMAINED QTY</th>
              <th>ORDER DATE</th>
              <th>ACTUAL SHIP DATE</th>
              <th>SHIPPED QTY</th>
              <th id="comment">COMMENTS </th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>

          {
            postList.map((val, key) => {
              return (
                <tbody>
                  <tr key={key}>
                    <td>{val.PO_NO}</td>
                    <td>{val.PART_NO}</td>
                    <td>{(val.PO_SHIP_DATE).slice(0, 10)}</td>
                    <td>{val.PART_DESCRIPTION}</td>
                    <td>{val.CUSTOMER}</td>
                    <td>{val.QTY}</td>
                    <td>{val.Remained_parts}</td>
                    <td>{(val.PO_DATE).slice(0, 10)}</td>
                    <td><input type="date" class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                      setActualShipdate(e.target.value)
                    }}
                    /></td>
                    <td><input type="number" class="form-control form-rounded" id="shippedqty" onChange={(e) => {
                      setShippedQty(e.target.value)
                    }} onBlur={() => {
                      updateHandler((val.PO_NO), (val.PART_NO), (val.PO_SHIP_DATE).slice(0, 10), (val.PART_DESCRIPTION), (val.CUSTOMER), (val.COMMENTS))
                    }}
                    /></td>
                    <td id="comment">{val.COMMENTS}</td>
                    <td><button class="btn btn-success" onClick={() => { Update((val.PO_NO), (val.PART_NO), (val.PO_SHIP_DATE), (val.PART_DESCRIPTION), (val.CUSTOMER), (val.COMMENTS), (val.PO_DATE), (val.ShippedQty)) }
                    }>Update</button></td>
                    <td><button class="btn btn-danger" onClick={() => { window.confirm('Are you sure you want to delete this Entry ' + val.PART_NO + '?',) && Delete((val.PO_NO), (val.PART_NO)) }}> Delete</button></td>
                  </tr>
                </tbody>
              )
            })
          }
        </table>
      </div>
    </div >
  )
}

export default ShippingData
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Host } from '../../../App'
import './Raw_Material_Dataset.css'


function Raw_Material_Dataset() {
  const [partDataset, setpartDataset] = useState([]);
  //search for data
  const [ItemNo, setItemNo] = useState({});
  const [ItemDesc, setItemDesc] = useState({});
  const [Supplier, setSupplier] = useState({});
  const [Category, setCategory] = useState({});
  const [editable, seteditable] = useState(false);

  var token = (window.localStorage.getItem("acessToken"));

  const [data, setData] = useState([]);
  const [newdata, setNewData] = useState([
    { ITEM_NO: '', ITEM_DESCRIPTION: '', SUPPLIER: '', CATEGORY: '', UNIT: '', COST: '', CURR: '', SHIPPING: '', CUSTOMS: '', LEAD_TIME: '', BLANKET_ORDER: '' }
  ]);

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/supplychain/part_dataset/search/${JSON.stringify(ItemNo)},${JSON.stringify(ItemDesc)},${JSON.stringify(Supplier)},${JSON.stringify(Category)},${token}`).then((data) => {
      setpartDataset(data.data);
      setData(data.data);
    });
  }, []);

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/supplychain/part_dataset/${token}`).then((data) => {
      //  setpartDataset(data.data);
      // setData(data.data);
    });

  }, []);
  const search = () => {
    Axios.get(`https://` + Host + `/api/supplychain/part_dataset/search/${JSON.stringify(ItemNo)},${JSON.stringify(ItemDesc)},${JSON.stringify(Supplier)},${JSON.stringify(Category)},${token}`).then((data) => {
      setpartDataset(data.data);
      setData(data.data);
    });
  }

  const [changedRows, setChangedRows] = useState(new Set());
  const handleInputChange = (e, index, key) => {
    const { value } = e.target;
    updateData(index, key, value);
  };

  const updateData = (index, key, value) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    setChangedRows(prevRows => new Set(prevRows).add(index));
  };

  const print = () => {
    console.log(newdata);
  }


  const submitpartdatasetdata = () => {
    Axios.post(`https://` + Host + `/api/submitnewrawmaterialdataset`, { newdata }).then((response) => {
      if (response.status === 200) {
        window.alert(response.data.message);
        window.location.reload();

      }
    })
      .catch(error => {
        window.alert("Error Occured")
      });

  }
  const Update = () => {
    const changedData = [...changedRows].map(index => {
      return data[index];
    });

    Axios.post(`https://` + Host + `/Raw_material_dataset/Update`, { changedData: JSON.stringify(changedData), token: token })
      .then((response) => {
        if (response.status === 200) {
          window.alert(response.data.message);
          window.location.reload();

        }
      })
      .catch(error => {
        window.alert("Error Occured")
      });

  }

  const Delete = (ITEM_NO, SUPPLIER) => {

    Axios.delete(`https://` + Host + `/api/RawMaterialData/Delete/${ITEM_NO},${SUPPLIER},${token}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message, {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
            style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
          });
          search();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });

  };
  return (
    <div className="MainPage_data">
      <div class="col-2">
        <Link to="/Suppluchain">
          <input type="button" class="btn btn-success" defaultValue="Back" />
        </Link>
      </div>
      <div class="Container">
        <br></br>
        <div>
          <div class="add_section">
            <center>
              {/*Add new raw material dataset */}
              <h2 style={{ padding: 25 }}>Add New Raw Material Dataset</h2>
              <div class="row" id="Part_dataset_add">
                <tr>
                  <td style={{ padding: 10 }}>
                    <label>Item No</label>
                    <input
                      type="text"
                      name="Part_No"
                      class="form-control form-rounded"
                      list='InternalPartNo'
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].ITEM_NO = e.target.value;
                        setNewData(latestData);
                      }}
                    />
                    <div class="scrollbar">
                      <datalist id="InternalPartNo">
                        <option></option>
                        {partDataset.map((x) => {
                          return <option>{x.ITEM_NO}</option>;
                        })}
                      </datalist>
                    </div>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Item Description</label>
                    <input
                      type="text"
                      name="Part_Description"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].ITEM_DESCRIPTION = e.target.value;
                        setNewData(latestData);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Supplier</label>
                    <input
                      type="text"
                      name="Customer"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].SUPPLIER = e.target.value;
                        setNewData(latestData);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>CATEGORY</label>
                    <input
                      type="text"
                      name="CATEGORY"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].CATEGORY = e.target.value;
                        setNewData(latestData);
                      }}
                    />

                    <div class="scrollbar">
                      <datalist id="category">
                        <option></option>
                        {partDataset.map((x) => {
                          return <option>{x.CATEGORY}</option>;
                        })}
                      </datalist>
                    </div>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>UNIT</label>
                    <input
                      type="text"
                      name="Unit_price"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].UNIT = e.target.value;
                        setNewData(latestData);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>COST</label>
                    <input
                      type="text"
                      name="Unit_price"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].COST = e.target.value;
                        setNewData(latestData);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>CURRENCY</label>
                    <input
                      type="text"
                      name="CURRENCY"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].CURR = e.target.value;
                        setNewData(latestData);
                      }}
                      list='currency'
                    />
                    <div class="scrollbar">
                      <datalist id="currency">
                        <option></option>
                        {partDataset.map((x) => {
                          return <option>{x.CURR}</option>;
                        })}
                      </datalist>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: 10 }}>
                    <label>Shipping</label>
                    <input
                      type="text"
                      name="Shipping"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].SHIPPING = e.target.value;
                        setNewData(latestData);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Customs</label>
                    <input
                      type="text"
                      name="BASE_STYLE_TUHOY"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].CUSTOMS = e.target.value;
                        setNewData(latestData);
                      }}
                      list="base"
                    />
                    <div class="scrollbar">
                      <datalist id="base">
                        <option>Yes</option>
                        <option>No</option>

                      </datalist>
                    </div>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>LEAD TIME</label>
                    <input
                      type="text"
                      name="LEAD_TIME"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].LEAD_TIME = e.target.value;
                        setNewData(latestData);
                      }}
                      list="LEAD_TIME"
                    />
                    <div class="scrollbar">
                      <datalist id="LEAD_TIME">
                        <option></option>
                        {partDataset.map((x) => {
                          return <option>{x.LEAD_TIME}</option>;
                        })}
                      </datalist>
                    </div>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>BLANKET ORDER</label>
                    <input
                      type="text"
                      name="BLANKET_ORDER"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        const latestData = [...newdata];
                        latestData[0].BLANKET_ORDER = e.target.value;
                        setNewData(latestData);
                      }}
                      list="base"
                    />

                  </td>

                </tr>

                <div>
                  <input
                    type="button"
                    class="btn btn-success"
                    defaultValue="Submit"
                    style={{ width: "auto" }}
                    name="Submit"
                    onClick={submitpartdatasetdata}
                  />
                </div>
              </div>
              <br></br>
              <br></br>
            </center>
          </div>
          <div class="datatable-wide_part">

            <h2 style={{ padding: 10 }}>Raw Material Dataset</h2><button type="button" class="btn btn-primary" onClick={() => { seteditable(!editable) }} >Edit</button>
            <div class="row">
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Item No' onChange={(e) => {
                  setItemNo(e.target.value)
                }} onSelect={search} />
              </div>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Item Description' onChange={(e) => {
                  setItemDesc(e.target.value)
                }} onSelect={search} />
              </div><div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Supplier' onChange={(e) => {
                  setSupplier(e.target.value)
                }} onSelect={search} />
              </div><div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Category' onChange={(e) => {
                  setCategory(e.target.value)
                }} onSelect={search} />
              </div>
            </div >
            <br></br>
            <table class="table table-bordered table-striped">
              <thead style={{}}>
                <tr>
                  <th>ITEM NO</th>
                  <th>ITEM DESCRIPTION</th>
                  <th>SUPPLIER</th>
                  <th>UNIT</th>
                  <th>CURRENCY</th>
                  <th>COST</th>
                  <th>SHIPPING</th>
                  <th>CUSTOMS</th>
                  <th>LEAD TIME</th>
                  <th>BLANKET_ORDER</th>
                  <th>CATEGORY</th>
                  {editable ? <th>Update</th> : null}
                  {editable ? <th>Delete</th> : null}
                </tr>
              </thead>
              <tbody >
                {partDataset.map((val, index) => {
                  return (
                    <tr>
                      <td>{val.ITEM_NO}</td>
                      <td>{editable ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.ITEM_DESCRIPTION}
                          onChange={(e) => handleInputChange(e, index, 'ITEM_DESCRIPTION')}
                        /> : val.ITEM_DESCRIPTION}</td>
                      <td>{val.SUPPLIER}</td>
                      <td>{editable ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.UNIT}
                          onChange={(e) => handleInputChange(e, index, 'UNIT')}
                        /> : val.UNIT}</td>
                      <td>{editable ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.CURR}
                          onChange={(e) => handleInputChange(e, index, 'CURR')}
                        /> : val.CURR}</td>
                      <td>{editable ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.COST}
                          onChange={(e) => handleInputChange(e, index, 'COST')}
                        /> : val.COST}</td>
                      <td>{editable ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.SHIPPING}
                          onChange={(e) => handleInputChange(e, index, 'SHIPPING')}
                        /> : val.SHIPPING}</td>
                      <td>{editable ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.CUSTOMS}
                          onChange={(e) => handleInputChange(e, index, 'CUSTOMS')}
                        /> : val.CUSTOMS}</td>
                      <td>{editable ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.LEAD_TIME}
                          onChange={(e) => handleInputChange(e, index, 'LEAD_TIME')}
                        /> : val.LEAD_TIME}</td>
                      <td>{editable ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.BLANKET_ORDER}
                          onChange={(e) => handleInputChange(e, index, 'BLANKET_ORDER')}
                        /> : val.BLANKET_ORDER}</td>
                      <td>{editable ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.CATEGORY}
                          onChange={(e) => handleInputChange(e, index, 'CATEGORY')}
                        /> : val.CATEGORY}</td>
                      {editable ? <td><button class="btn btn-primary" onClick={Update}>Update</button></td> : null}
                      {editable ? <td><button class="btn btn-danger" onClick={() =>
                        window.confirm('Are you sure you want to delete this Entry ?') &&
                        Delete(val.ITEM_NO, val.SUPPLIER)
                      }>Delete</button></td> : null}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div>



        </div>
        <br></br>
        <br></br>
      </div>
    </div >
  )
}

export default Raw_Material_Dataset
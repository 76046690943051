import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import './Attendance_dashboard.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { host, Host } from '../App';


function Attendance_dashboard() {
  const [shift, setshift] = useState({});
  const [Data, setData] = useState([]);
  const [PT, setPT] = useState([]);
  const [PY, setPY] = useState([]);
  const [AT, setAT] = useState([]);
  const [AY, setAY] = useState([]);
  const [UV, setUV] = useState([]);
  const [EV, setEV] = useState([]);



  const [Date1, setDate1] = useState(new Date());
  const [Today, setToday] = useState("curdate()");
  const [yesterday, setYesterday] = useState('DATE_SUB(CURDATE(), INTERVAL 1 DAY)');
  var token = (window.localStorage.getItem("acessToken"));
  var user = (window.localStorage.getItem("Name"));
  const trial_status = "present";

  const Present_today = {
    Status: trial_status,
    token: token,
    Date: Today,
    user: user
  };

  const Absent_today = {
    Status: "Absent",
    token: token,
    Date: Today,
    user: user
  };

  const Present_yesterday = {
    Status: "Present",
    token: token,
    Date: yesterday,
    user: user
  };

  const Absent_yesterday = {
    Status: "Absent",
    token: token,
    Date: yesterday,
    user: user
  };

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/attendance/dashboard`, { params: Present_today }).then((data) => {
      setPT(data.data[0].count);
    });
    Axios.get(`https://` + Host + `/api/attendance/dashboard`, { params: Present_yesterday }).then((data) => {
      setPY(data.data[0].count);
    });

    Axios.get(`https://` + Host + `/api/attendance/dashboard`, { params: Absent_today }).then((data) => {
      setAT(data.data[0].count);
    });

    Axios.get(`https://` + Host + `/api/attendance/dashboard`, { params: Absent_yesterday }).then((data) => {
      setAY(data.data[0].count);
    });
    Axios.get(`https://` + host + `/api/upcoming_vaccation`, { params: Absent_yesterday }).then((data) => {
      setUV(data.data);
    });
    Axios.get(`https://` + host + `/api/ending_vaccation`, { params: Absent_yesterday }).then((data) => {
      setEV(data.data);
    });

  }, []);

  return (
    <div className="dashboard-container">
      <br></br><br></br>

      {/*     {PT.length > 0 && PY.length > 0 && AT.length > 0 && AY.length > 0 ?*/}


      <div className="container">
        <div className="row">
          {/* First section */}
          <div className="col-md-12 col-sm-12">
            <Link to='/attendanceDetails' style={{ 'text-decoration': 'none' }} id="attendance_dashboard">
              <div className="row">
                <div className="col">
                  <div className="block">
                    <h2 style={{ textDecoration: 'none' }}>Employees Present Today</h2>
                    <h2 id="midblock_col_no">{PT}</h2>
                  </div>
                </div>
                <div className="col">
                  <div className="block">
                    <h2 style={{ textDecoration: 'none' }}>Employees Present Yesterday</h2>
                    <h2 id="midblock_col_no">{PY}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="block">
                    <h2 style={{ textDecoration: 'none' }}>Employees Absent Today</h2>
                    <h2 id="midblock_col_no">{AT}</h2>
                  </div>
                </div>
                <div className="col">
                  <div className="block">
                    <h2 style={{ textDecoration: 'none' }}>Employees Absent Yesterday</h2>
                    <h2 id="midblock_col_no">{AY}</h2>
                  </div>
                </div>
              </div>
            </Link>
            <div className="row" id="attendance_dashboard">
              <div className="col">
                <div className="vaccation_highlight">
                  <h2 style={{ textDecoration: 'none' }}>Upcoming Vacation</h2>
                  {UV ?
                    <table class="table table-bordered" id='vac_highlight_table'>
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>Start Date</th>
                        </tr>
                      </thead>
                      {UV.map((val, key) => {
                        return (
                          <tbody>
                            <tr key={key}>
                              <td>{val.Name}</td>
                              <td>{(val.Start_Date).slice(0, 10)}</td>
                            </tr>
                          </tbody>
                        )
                      })}
                      <thead>
                      </thead>
                    </table> : null}
                </div>
              </div>
              <div className="col">
                <div className="vaccation_highlight">
                  <h2 style={{ textDecoration: 'none' }}>Ending Vacation</h2>
                  {EV ?
                    <table class="table table-bordered" id='vac_highlight_table'>
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>End Date</th>
                        </tr>
                      </thead>
                      {EV.map((val, key) => {
                        return (
                          <tbody>
                            <tr key={key}>
                              <td>{val.Name}</td>
                              <td>{(val.End_Date).slice(0, 10)}</td>
                            </tr>
                          </tbody>
                        )
                      })}
                      <thead>
                      </thead>
                    </table> : null}
                </div>
              </div>
            </div>
          </div>
          {/* Second section */}

        </div>
      </div>



    </div>
  )
}

export default Attendance_dashboard
// src/Sidebar.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Axios from 'axios'
import { Host } from '../App';
import "./Sidebar.css";

const SidebarContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    max-width: 50px;
    padding: 10px;
  }
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const Name = styled.h2`
  font-size: 16px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Role = styled.p`
  font-size: 12px;
  color: #777;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`;

const MenuItem = styled.li`
  width: 100%;
  margin: 10px 0;

  a {
    display: flex;
    align-items: center;
    padding: 10px;
    color: #333;
    text-decoration: none;
    font-size: 14px;

    &:hover {
      background-color: #e9ecef;
      border-radius: 5px;
    }

    @media (max-width: 768px) {
      justify-content: center;
      font-size: 12px;
      padding: 8px;
    }

    span {
      margin-left: 10px;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;


const Sidebar = (props) => {
  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("Rank");
    window.localStorage.removeItem("Stage");

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    Axios.get(`https://` + Host + `/logout`).then((data) => {
      console.log(data);
      props.history.push('/');
    })
  }
  const user = window.localStorage.getItem("Name");
  return (
    <SidebarContainer>
      <Profile>
        <Avatar src="https://via.placeholder.com/60" alt="Profile" />
        <h2>{user.replace(/[{""}]/g, "")}</h2>

      </Profile>
      <Menu>
        <MenuItem><Link to="/NewDashboard"><i className="fas fa-home"></i><span>Dashboard</span></Link></MenuItem>
        <MenuItem><Link to="/openorder"><i className="fas fa-eye"></i><span>Open Orders</span></Link></MenuItem>
        <MenuItem><Link to="/Pivot"><i className="fas fa-list"></i><span>Pivot</span></Link></MenuItem>
        <MenuItem><Link to="/suppluchain"><i className="fas fa-table"></i><span>Suppy Chain</span></Link></MenuItem>
        <MenuItem><Link to="/attendance"><i className="fas fa-icons"></i><span>Attendance Portal</span></Link></MenuItem>
        <MenuItem><Link to="/Scheduling"><i className="fas fa-chart-bar"></i><span>Scheduling</span></Link></MenuItem>
        <MenuItem><Link to="/BOM"><i className="fas fa-chart-bar"></i><span>BOM</span></Link></MenuItem>
        <MenuItem><a onClick={handleLogout} href="/"><i className="fas fa-book"></i><span>Logout</span></a></MenuItem>
      </Menu>
    </SidebarContainer >
  );
};

export default Sidebar;

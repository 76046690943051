import React, { useState, useEffect } from 'react'
import { Link, Redirect, useParams } from "react-router-dom"
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { host, Host } from '../../App';
import Axios from 'axios'
import real_l from "../real_logo.png";

function Mold_list() {
  var [Date1, setDate1] = useState(new Date());
  var [Part_No, setPart_No] = useState("");
  var [PART_NAME, setPART_NAME] = useState("");
  var [RATIO, setRATIO] = useState("");
  var [Machine, setMachine] = useState("");
  var [TYPE, setTYPE] = useState("");
  var [CUSTOMER, setCUSTOMER] = useState("");
  var [MOLD, setMOLD] = useState("");
  const [data, setData] = useState([]);
  var token = (window.localStorage.getItem("acessToken"));
  var User = JSON.parse(window.localStorage.getItem("Name"));
  const [Edit, setEdit] = useState(false);
  const [NewPart, setNewPart] = useState(false);

  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();

  // to get unique list of all the columns
  const uniqueCUSTOMER = [...new Set(data.map(x => x.CUSTOMER))];
  const uniquePart_No = [...new Set(data.map(x => x.Part_No))];
  const uniquePART_NAME = [...new Set(data.map(x => x.PART_NAME))];
  const uniquePart_Description = [...new Set(data.map(x => x.Part_Description))];
  const uniqueTYPE = [...new Set(data.map(x => x.TYPE))];
  const uniqueMOLD = [...new Set(data.map(x => x.MOLD))];
  const uniqueSHOT_TIME = [...new Set(data.map(x => x.SHOT_TIME))];
  const uniqueRATIO = [...new Set(data.map(x => x.RATIO))];
  const uniqueMachine = [...new Set(data.map(x => x.Machine))];
  const [changedRows, setChangedRows] = useState(new Set());

  const [Newdata, setnewData] = useState([{ Customer: "", Part_No: "", Part_Name: "", Part_Description: "", Type: "", Mold: "", Shot_Time: "", Ratio: "", Machine: "" },]);
  const [rank, setrank] = useState(false);
  var Ranked = (window.localStorage.getItem("Rank"));
  var Stage = (window.localStorage.getItem("Stage"));

  useEffect(() => {
    if (Ranked === '"Admin"' && Stage === '"2"') {
      setrank(true);
    }
  }, [Ranked]);

  const queryParams = {
    Part_No: Part_No,
    PART_NAME: PART_NAME,
    token: token,
    RATIO: RATIO,
    Machine: Machine,
    TYPE: TYPE,
    CUSTOMER: CUSTOMER,
    MOLD: MOLD,
  };

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/Mold_list`, { params: queryParams }).then((data) => {
      setData(data.data);

    })
  }, [CUSTOMER, Part_No, PART_NAME, TYPE, MOLD, RATIO, Machine]);

  const handleInputChange = (e, index, key) => {
    const { value } = e.target;
    updateData(index, key, value);
  };

  const updateData = (index, key, value) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    setChangedRows(prevRows => new Set(prevRows).add(index));
  }
  const addnew = (e, key) => {
    const { value } = e.target;
    updatenewData(key, value);
  };

  const updatenewData = (key, value) => {
    setnewData(prevData => {
      const newData = [...prevData];
      newData[0][key] = value;
      return newData;
    });
  }

  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  const editable = () => {
    setEdit(!Edit)
  }
  const Addnew = () => {
    setNewPart(!NewPart)
  }

  const print = () => {
    //  const changedData = [...changedRows].map(index => {
    //    return data[index];
    //  });
    //   console.log(JSON.stringify(changedData));
    console.log(uniqueCUSTOMER);
  }

  const handlePrint = () => {

    const table = document.querySelector('#alldata');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
      width: 100 %; /* Occupy the full width */
      font - family: Arial, sans - serif; /* Set the font family */
    }
          
          img {
      display: inline - block;
      height: 50px; /* Set the height of the image as required */
      margin - right: 10px; /* Set the spacing between the image and title as required */
    }
          
          table {
      border: 1px solid black;
      border - collapse: collapse;
      width: 100 %;
      font - family: Arial, sans - serif; /* Set the font family */
    }

    th, td {
      border: 1px solid black;
      padding: 5px;
    }

    tr: nth - child(even) {
      background - color: #f2f2f2; /* Apply the table-striped style */
    }
          
          .bottom - right {
    position: relative;
    bottom: 10px;
    right: 10px;
    font - family: Arial, sans - serif; /* Set the font family */
  }
          </style >
        </head >
    <body>

      <h1>${img.outerHTML} </h1>
      <table class="table table-bordered">${(table.innerHTML)}</table>
      <p class="bottom-right">Printed By: ${User}</p>
      <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
    </body>
      </html > `
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `
    `);
    printWindow.document.close();
    printWindow.focus();
  };

  const AddNewPart = () => {
    Axios.post(`https://` + Host + `/api/post/addnewdata`, {
      Newdata,
      token,
      date,
      User
    }).then((response) => {
      if (response.status === 200) {
        window.alert(response.data.message);
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }
  const Update = () => {
    const changedData = [...changedRows].map(index => {
      return data[index];
    });
    console.log(changedData);
    Axios.post(`https://` + Host + `/Moldlist/Update`, { changedData: JSON.stringify(changedData), token: token, Last_Updated: date, Updated_by: User })
      .then((response) => {
        if (response.status === 200) {
          window.alert(response.data.message);
          setEdit(false);
        }
      })
      .catch(error => {
        window.alert("Error Occured")
      });
  }
  return (
    <div className="MainPage_data"> <center>
      <br></br>
      <br></br>
    </center>

      <div class="Container">
        <ToastContainer />
        <div class="row">
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='CUSTOMER' list="CUSTOMER" onChange={(e) => {
                setCUSTOMER((e.target.value).replace(/[{""}]/g, ""))
              }} />
              <datalist id="CUSTOMER">
                <option></option>
                {uniqueCUSTOMER.map(x => {
                  return <option>{x}</option>
                })}
              </datalist>
            </div>

          </div >
          <div class="col">
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='PART NO' list='Part_No' onChange={(e) => {
                setPart_No(e.target.value)
              }} />
              <datalist id="Part_No">
                <option></option>
                {uniquePart_No.map(x => {
                  return <option>{x}</option>
                })}
              </datalist>
            </div>
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='PART NAME' list="PART_NAME" onChange={(e) => {
                setPART_NAME(e.target.value.replace(/[{"''"}]/g, ''));
              }} />
              <datalist id="PART_NAME">
                <option></option>
                {uniquePART_NAME.map(x => {
                  return <option>{x}</option>
                })}
              </datalist>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col" id='moldng'>
                {/* Grab the Itemicular dates */}
                <input type='text' class="form-control form-rounded" placeholder='MOLD' onChange={(e) => {
                  setMOLD(e.target.value)
                }} list='MOLD' />
                <datalist id="MOLD">
                  <option></option>
                  {uniqueMOLD.map(x => {
                    return <option>{x}</option>
                  })}
                </datalist>
              </div>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='MACHINE' onChange={(e) => {
                  setMachine(e.target.value)
                }} list='MACHINE' />
                <datalist id="MACHINE">
                  <option></option>
                  {uniqueMachine.map(x => {
                    return <option>{x}</option>
                  })}
                </datalist>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col" id='moldng'>
                {/* Grab the Itemicular dates */}
                <input type='text' class="form-control form-rounded" placeholder='TYPE' onChange={(e) => {
                  setTYPE(e.target.value)
                }} list='TYPE' />
                <datalist id="TYPE">
                  <option></option>
                  {uniqueTYPE.map(x => {
                    return <option>{x}</option>
                  })}
                </datalist>
              </div>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='RATIO' onChange={(e) => {
                  setRATIO(e.target.value)
                }} list='RATIO' />
                <datalist id="RATIO">
                  <option></option>
                  {uniqueRATIO.map(x => {
                    return <option>{x}</option>
                  })}
                </datalist>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <button className='btn btn-primary' onClick={handlePrint}>Print</button>
              </div>
              <div class="col"> {rank ?
                <button className='btn btn-primary' onClick={editable}>Edit</button> : null}
              </div>
              <div class="col"> {rank ?
                <button className='btn btn-primary' onClick={Addnew}>Add Part</button> : null}
              </div>
            </div>
          </div>
        </div >
        <br></br>
        <br></br>
        {NewPart ?
          <div class="add_section">
            <center>
              <h2 style={{ padding: 25 }}>Add New Mold Data</h2>
              <div class="row" id="Part_dataset_add">
                <tr>
                  <td style={{ padding: 10 }}>
                    <label>Customer</label>
                    <input
                      type="text"
                      name="Customer"
                      class="form-control form-rounded"
                      list="CUSTOMER"
                      onChange={(e) => addnew(e, 'Customer')}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>PART NO</label>
                    <input
                      type="text"
                      name="Part_No"
                      class="form-control form-rounded"
                      list='Part_No'
                      onChange={(e) => addnew(e, 'Part_No')}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Part Name</label>
                    <input
                      type="text"
                      name="Part Name"
                      class="form-control form-rounded"
                      onChange={(e) => addnew(e, 'Part_Name')}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Part Description</label>
                    <input
                      type="text"
                      name="Part_Description"
                      class="form-control form-rounded"
                      onChange={(e) => addnew(e, 'Part_Description')}
                    />
                    <div class="scrollbar">

                    </div>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Type</label>
                    <input
                      type="text"
                      name="Type"
                      class="form-control form-rounded"
                      onChange={(e) => addnew(e, 'Type')}
                      list='Type'
                    />
                    <datalist id='Type'>
                      <option>F</option>
                      <option>R</option>
                    </datalist>
                  </td>

                </tr>
                <tr>
                  <td style={{ padding: 10 }}>
                    <label>MOLD</label>
                    <input
                      type="text"
                      name="Mold"
                      class="form-control form-rounded"
                      onChange={(e) => addnew(e, 'Mold')}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Shot Time</label>
                    <input
                      type="number"
                      name="Shot_Time"
                      class="form-control form-rounded"
                      onChange={(e) => addnew(e, 'Shot_Time')}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Ratio</label>
                    <input
                      type="text"
                      name="Ratio"
                      class="form-control form-rounded"
                      onChange={(e) => addnew(e, 'Ratio')}
                      list='RATIO'
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Machine</label>
                    <input
                      type="text"
                      name="Machine"
                      class="form-control form-rounded"
                      onChange={(e) => addnew(e, 'Machine')}
                      list="MACHINE"
                    />
                  </td>
                </tr>
                <div>
                  <input
                    type="button"
                    class="btn btn-success"
                    defaultValue="Submit"
                    style={{ width: "auto" }}
                    name="Submit"
                    onClick={AddNewPart}
                  />
                </div>
              </div>
              <br></br>
              <br></br>
            </center>
          </div> :
          <table class="table table-bordered" id='alldata'>
            <thead>
              <tr>
                <th>CUSTOMER</th>
                <th>PART NO</th>
                <th>PART NAME</th>
                <th>PART DESCRIPTION</th>
                <th>TYPE</th>
                <th>MOLD</th>
                <th>SHOT TIME</th>
                <th>RATIO</th>
                <th>MACHINE</th>
                <th>Last Updated</th>

                {Edit ? <th>Update</th> : null}
              </tr>
            </thead>
            {data.map((val, index) => {
              return (

                <tbody key={index}>
                  <tr key={val.SrNo}>
                    <td>{Edit ?
                      <input
                        type="text"
                        className="form-control form-rounded"
                        value={val.CUSTOMER}
                        onChange={(e) => handleInputChange(e, index, 'CUSTOMER')}
                      /> : val.CUSTOMER}</td>
                    <td>{Edit ?
                      <input
                        type="text"
                        className="form-control form-rounded"
                        value={val.Part_No}
                        onChange={(e) => handleInputChange(e, index, 'Part_No')}
                      /> : val.Part_No}</td>
                    <td>{Edit ?
                      <input
                        type="text"
                        className="form-control form-rounded"
                        value={val.PART_NAME}
                        onChange={(e) => handleInputChange(e, index, 'PART_NAME')}
                      /> : val.PART_NAME}</td>
                    <td> {Edit ?
                      <input
                        type="text"
                        className="form-control form-rounded"
                        value={val.Part_Description}
                        onChange={(e) => handleInputChange(e, index, 'Part_Description')}
                      /> : val.Part_Description}</td>
                    <td>{Edit ?
                      <input
                        type="text"
                        className="form-control form-rounded"
                        value={val.TYPE}
                        onChange={(e) => handleInputChange(e, index, 'TYPE')}
                      /> : val.TYPE}</td>
                    <td>
                      {Edit ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.MOLD}
                          onChange={(e) => handleInputChange(e, index, 'MOLD')}
                        /> : val.MOLD}</td>
                    <td>
                      {Edit ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.SHOT_TIME}
                          onChange={(e) => handleInputChange(e, index, 'SHOT_TIME')}
                        /> : val.SHOT_TIME}</td>
                    <td>
                      {Edit ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.RATIO}
                          onChange={(e) => handleInputChange(e, index, 'RATIO')}
                        /> : val.RATIO}</td>


                    <td>
                      {Edit ?
                        <input
                          type="text"
                          className="form-control form-rounded"
                          value={val.Machine}
                          onChange={(e) => handleInputChange(e, index, 'Machine')}
                        /> : val.Machine}</td>
                    {val.Last_Updated ? <td>{(val.Last_Updated).slice(0, 10)}</td> : val.Last_Updated}
                    {Edit ? <td><button className='btn btn-success' onClick={Update} >Update</button>
                    </td> : null}
                  </tr>
                </tbody>
              )
            }
            )}
          </table>
        }
      </div >
    </div >
  )
}

export default Mold_list;
import React, { useState, useEffect } from "react";
import { FaHome, FaUsers, FaBuilding, FaUser, FaCog, FaSignInAlt, FaUserPlus, FaExclamationTriangle } from 'react-icons/fa';
import './Dashboard.css';
import '../Responsive.css'

import PO_RM from './PO_Raw_material/PO_raw_material'
import PO_Supplies from './PO_Supplies/PO_Supplies'
import Other_Expenses from './Other_Expenses.js/Other_Expenses'
import Received_raw_material from "./PO_Raw_material/Received_raw_material";
import Received_PO_Supplies from "./PO_Supplies/Received_PO_Supplies";
import History_Received_raw_Material from "./PO_Raw_material/History_Received_raw_Material.js"
import History_Received_Supplies from "./PO_Supplies/History_Received_Supples.js";
import History_OtherExpenses from "./Other_Expenses.js/History_OtherExpenses.js";
import { Link, Redirect, useParams } from "react-router-dom"
import { Data_Supply } from "./Pivot_supply_chain/Pivot.js";
import Open_orders_supplychain from "./Open_orders_supplychain/Open_orders_supplychain.js";


const Sidebar_SuppluChain = () => {
  const [RM, setRM] = useState(false);
  const [Supplies, setSupplies] = useState(false);
  const [Expenses, setExpenses] = useState(false);
  const [R_RM, setR_RM] = useState(false);
  const [HR_RM, setHR_RM] = useState(false);
  const [HR_supplies, setHR_supplies] = useState(false);
  const [HExpenses, setHExpenses] = useState(false);
  const [R_supplies, setR_supplies] = useState(false);
  const [animation, setAnimation] = useState(true);
  var userName = (window.localStorage.getItem("Name").replace(/[{""}]/g, ""));
  const [Area, setArea] = useState("");
  var Stage = (window.localStorage.getItem("Stage"));
  const [rank_stage, setrank_stage] = useState(false);
  var Ranked = (window.localStorage.getItem("Rank"));

  const [supply_pivot, setsupply_pivot] = useState(false);
  const [Open_order, setOpen_order] = useState(false);

  useEffect(() => {
    if (Area == "PO Raw Material") {
      setRM(true);
      setSupplies(false);
      setExpenses(false);
      setR_RM(false);
      setR_supplies(false);
      setAnimation(false);
      setHExpenses(false);
      setsupply_pivot(false);
      setOpen_order(false);

    }
    if (Area == "PO Supplies") {
      setRM(false);
      setSupplies(true);
      setExpenses(false);
      setR_RM(false);
      setR_supplies(false);
      setAnimation(false);
      setHExpenses(false);
      setsupply_pivot(false);
      setOpen_order(false);

    }
    if (Area == "Other Expenses") {
      setRM(false);
      setSupplies(false);
      setExpenses(true);
      setR_RM(false);
      setR_supplies(false);
      setAnimation(false);
      setHExpenses(false);
      setsupply_pivot(false);
      setOpen_order(false);

    }
    if (Area == "Received Raw Material") {
      setRM(false);
      setSupplies(false);
      setExpenses(false);
      setR_RM(true);
      setR_supplies(false);
      setAnimation(false);
      setHExpenses(false);
      setsupply_pivot(false);
      setOpen_order(false);

    } if (Area == "Received Supplies") {
      setRM(false);
      setSupplies(false);
      setExpenses(false);
      setR_RM(false);
      setR_supplies(true);
      setAnimation(false);
      setHExpenses(false);
      setsupply_pivot(false);
      setOpen_order(false);

    }
    if (Area == "History Other Expenses") {
      setRM(false);
      setSupplies(false);
      setExpenses(false);
      setR_RM(false);
      setR_supplies(false);
      setAnimation(false);
      setHExpenses(true);
      setsupply_pivot(false);
      setOpen_order(false);

    } if (Area == "Welcome") {
      setRM(false);
      setSupplies(false);
      setExpenses(false);
      setR_RM(false);
      setR_supplies(false);
      setAnimation(true);
      setHExpenses(false);
      setsupply_pivot(false);
      setOpen_order(false);
    }
    if (Area == "Pivot Table") {
      setRM(false);
      setSupplies(false);
      setExpenses(false);
      setR_RM(false);
      setR_supplies(false);
      setAnimation(false);
      setHExpenses(false);
      setsupply_pivot(true);
      setOpen_order(false);
    }
    if (Area == "Open Orders") {
      setRM(false);
      setSupplies(false);
      setExpenses(false);
      setR_RM(false);
      setR_supplies(false);
      setAnimation(false);
      setHExpenses(false);
      setsupply_pivot(false);
      setOpen_order(true);
    }

  }, [Area]);
  const handleSelect = (selectedArea) => {
    setArea(selectedArea);

  };

  useEffect(() => {
    if (Ranked === '"Admin"' && Stage === '"2"') {
      setrank_stage(true);
    }
  }, [Ranked]);

  return (
    <div>
      <div class="row">
        <div >
          <div className="sidebar" id="supplychain-sidebar">
            <ul className="sidebar-nav">
              <li className="nav-item" id="mobileView" onClick={() => handleSelect('PO Raw Material')}>
                <span>PO Raw Material</span>
              </li>
              <li className="nav-item" id="mobileView" onClick={() => handleSelect('PO Supplies')}>
                <span>PO Supplies</span>
              </li>
              <li className="nav-item" id="mobileView" onClick={() => handleSelect('Other Expenses')}>
                <span>Other Expenses</span>
              </li>
              <li className="nav-item" onClick={() => handleSelect('Received Raw Material')}>
                <span>Received Raw Material</span>
              </li>
              <li className="nav-item" onClick={() => handleSelect('Received Supplies')}>
                <span>Received Supplies</span>
              </li>
              <li className="nav-item" onClick={() => handleSelect('History Other Expenses')}>
                <span>History Other Expenses</span>
              </li>
              <li className="nav-item" onClick={() => handleSelect('Pivot Table')}>
                <span>Pivot Table</span>
              </li>
              <li className="nav-item" onClick={() => handleSelect('Open Orders')}>
                <span>Open Orders</span>
              </li>

            </ul>

          </div>
        </div>

        <div class="col" id='supply-section'>
          <br></br>
          <div class="row" >
            <div class="col-2" id="mobileView">
              <Link to="/rawmaterial">
                <input type="button" class="btn btn-primary" defaultValue="Raw Material Dataset" />
              </Link>
            </div>
            <div class="col-2">
            </div>
            <div class="col-2">
            </div>
            <div class="col-4">
            </div>
            <div id="user-name-block" class="col-2">
              <span className="user-name">{userName}</span>
            </div>
            <h1 id="Supply_chain_title">{Area}</h1>
            {animation ? <div id="title_supplychain_box">
              <p id='supply_chain_title'>Realform supply Chain</p>
            </div> : null}
            {RM ? <PO_RM /> : null}
            {Supplies ? <PO_Supplies /> : null}
            {Expenses ? <Other_Expenses /> : null}
            {R_RM ? <Received_raw_material /> : null}
            {R_supplies ? <Received_PO_Supplies /> : null}
            {HR_RM ? <History_Received_raw_Material /> : null}
            {HR_supplies ? <History_Received_Supplies /> : null}
            {HExpenses ? <History_OtherExpenses /> : null}
            {supply_pivot ? <Data_Supply /> : null}
            {Open_order ? <Open_orders_supplychain /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar_SuppluChain;

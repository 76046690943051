import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import './Footer.css';
import real_logo from "../real_logo.png";

export default function Footer() {
  return (
    <MDBFooter className='footer text-center text-lg-start text-muted'>
      <section className='footer' style={{ width: '100%' }}>
        <MDBContainer className='text-center text-md-start'>
          <MDBRow className='mt-1'>
            <MDBCol md="3" lg="4" xl="3" className='mb-3'>
              <h6 className='text-uppercase fw-bold mb-3'>
                <img src={real_logo} alt="Company Logo" />
              </h6>
              <p>Experts in processing cold cured flexible and rigid polyurethane foams.</p>
            </MDBCol>
            <MDBCol md="1"></MDBCol>
            <MDBCol md="2" lg="2" xl="2" className='mb-3'>
              <h6 className='text-uppercase fw-bold mb-3'>Shortcuts</h6>
              <p><a href='/NewDashboard' className='text-reset'>Dashboard</a></p>
              <p><a href='/openorder' className='text-reset'>PO Open Orders</a></p>
              <p><a href='/pivot' className='text-reset'>Pivot</a></p>
              <p><a href='/Supply_Pivot' className='text-reset'>Supply Chain</a></p>
            </MDBCol>
            <MDBCol md="1"></MDBCol>
            <MDBCol md="2" lg="2" xl="2" className='mb-3'>
              <h6 className='text-uppercase fw-bold mb-3'>Daily Links</h6>
              <p><a href='/attendance' className='text-reset'>Attendance</a></p>
              <p><a href='/attendance' className='text-reset'>Vacation</a></p>
              <p><a href='/attendanceDetails' className='text-reset'>Attendance Details</a></p>
              <p><a href='/Scheduling' className='text-reset'>Scheduling</a></p>
            </MDBCol>
            <MDBCol md="1"></MDBCol>
            <MDBCol md="2" lg="2" xl="2" className='mb-3'>
              <h6 className='text-uppercase fw-bold mb-3'>Other Links</h6>
              <p><a href='/BOM' className='text-reset'>BOM</a></p>
              <p><a href='/BOMPivot' className='text-reset'>BOM Pivot</a></p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </MDBFooter>
  );
}

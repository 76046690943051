import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import './Registration.css';
import { host } from '../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown, Row } from 'antd';
import { Link } from "react-router-dom";

function Production_entry(props) {
  var token = (window.localStorage.getItem("acessToken"));
  const user = JSON.parse(window.localStorage.getItem("Name"));

  // declare all variables in order to send to js file
  var [shift, setShift] = useState("Morning Shift");
  var [Date1, setDate1] = useState(new Date());
  const [Date2, setDate2] = useState(Date1.toISOString().slice(0, 10));
  const [Morning_operators, setMorning_operators] = useState([]);
  const [Machine_list, setMachine_list] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  const [Night_operators, setNight_operators] = useState([]);
  const [Area, setArea] = useState("");


  const [data, setData] = useState([
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' },
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' },
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' },
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' },
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' }
  ]);


  // Hook to update the date as today's date
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });


  // to retreive morning operators
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/MorningOperators/${token}`).then((data) => {
      setMorning_operators(data.data)

    });
  }, [])
  // to retreive Night operators
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/NightOperators/${token}`).then((data) => {
      setNight_operators(data.data)

    });
  }, [])
  // to retreive Machines
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Machines/${token}`).then((data) => {
      setMachine_list(data.data)
    });
  }, [])
  // to retreive Machines
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)

    });
  }, [])
  var Noperators = () => {
    Axios.get(`https://` + host + `/realapi/get/NightOperators/${token}`).then((data) => {
      setMorning_operators(data.data)
      setShift("Night Shift")

    });
  }
  // Retreive morning operators
  var Moperators = () => {
    Axios.get(`https://` + host + `/realapi/get/MorningOperators/${token}`).then((data) => {
      setMorning_operators(data.data)
      setShift("Morning Shift")
    });
  }
  // select rigid/flex or all parts
  const area = () => {

    if (Area == "Rigid") {
      console.log("rigid called");
      Axios.get(`https://` + host + `/realapi/get/Rigidpart_dataset/${token}`).then((data) => {
        setpart_dataset(data.data)
      });
      Axios.get(`https://` + host + `/realapi/get/RigidMachines/${token}`).then((data) => {
        setMachine_list(data.data)
      });
    }
    if (Area == "Flex") {
      Axios.get(`https://` + host + `/realapi/get/Flexpart_dataset/${token}`).then((data) => {
        setpart_dataset(data.data)
      });
      Axios.get(`https://` + host + `/realapi/get/FlexMachines/${token}`).then((data) => {
        setMachine_list(data.data)
      });
    }
    if (Area == "Others") {
      Axios.get(`https://` + host + `/realapi/get/part_dataset/${token}`).then((data) => {
        setpart_dataset(data.data)
      });
      Axios.get(`https://` + host + `/realapi/get/Machines/${token}`).then((data) => {
        setMachine_list(data.data)
      });
    }
  }


  useEffect(() => {
    const newData = data.map((line) => ({ ...line, Date: Date2 }));
    setData(newData);
  }, [Date2])

  const submitValue = (e, index) => {
    const newData = [...data];
    newData[index].Part_internalNo = e.target.value;
    newData[index].Customer = (part_dataset.find((x) => x.Part_internalNo === e.target.value).Customer);
    newData[index].PartNo = part_dataset.find((x) => x.Part_internalNo === e.target.value).PartNo;
    newData[index].Part_description = part_dataset.find((x) => x.Part_internalNo === e.target.value).Part_description;
    setData(newData);
  }

  const addLines = () => {
    if (data.length < 15) {
      const newData = [...data];
      for (let i = 0; i < 5; i++) {
        newData.push({ Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' });
      }
      setData(newData);
    } else {
      window.alert("Lines Reached maximum limit")
    }

  }
  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    props.history.push('/');
  }
  const userMenu = (
    <Menu>
      <Menu.Item key="logout">
        <a onClick={handleLogout} href="/">
          <LogoutOutlined />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const submitdata = () => {
    const filteredData = [];

    data.forEach(item => {
      if (
        item.Operator &&
        item.Part_internalNo &&
        item.PartNo &&
        item.Customer &&
        item.Part_description &&
        item.Produced
      ) {
        const newData = [
          item.Date,
          item.Operator,
          item.Part_internalNo,
          item.PartNo,
          item.Customer,
          item.Part_description,
          item.Target,
          item.work_station,
          item.Produced,
          item.Scrap,
          item.Supervisor_notes,
          item.Operator_2
        ];
        filteredData.push(newData);
      }
    });

    Axios.post(`https://` + host + `/realapi/production_entry`, { filteredData, token })
    window.location.reload(false);
  }
  return (
    <div>
      <br></br>
      <div class="row">
        <div class="col-3" id='shift'>
          <label><b>
            <h3> Select Shift</h3>
          </b></label>
          <div class="row">
            <div class="col-md-auto">
              <input type="radio" name='shift' id='MorningcheckBox' defaultChecked onClick={Moperators} />
              {/*<input type="radio" id="Morning" name="Shift" value="Morning"  />*/}
            </div>
            <div class="col-md-auto">
              <label for="html">Morning Shift</label>
            </div>
            <div class="col-md-auto">
              <input type="radio" name='shift' id='Nights' onClick={Noperators} />
            </div>
            <div class="col-md-auto">
              <label for="css">Night Shift</label>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="row">
            <div class="col">
              <label><b>
                <h4>Date</h4>
              </b></label>
              <input type="date" class="form-control form-rounded" placeholder="Date" name="Date" id='date' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                setDate2(e.target.value)
              }} required />
            </div>
          </div>
        </div>

        <div class="col-1">
          <label><b>
            <h4> </h4>
          </b></label>
          <Link to="/openorder">
            <input type="button" class="btn btn-success" value="Open Orders" />
          </Link>
        </div>
        <div class="col-2">
          <label><b>
            <h4></h4>
          </b></label>
          <Link to="/Getreport">
            <input type="button" class="btn btn-success" value="Production Report" />
          </Link>
        </div>
        <div class="col-2">
          <Link to="/graph">
            <input type="button" class="btn btn-primary" value="Production Graph" />
          </Link>
        </div>
        <div class="col-2">

          <div className="top-bar">
            {/* Render the logo */}
            {/* Render the user dropdown */}
            <div className="user-dropdown">
              <Dropdown overlay={userMenu} trigger={['click']}>

                <Button icon={<UserOutlined />} size="large">
                  Welcome {user.replace(/[{""}]/g, "")}!
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      {data.map((line, index) => (<div>
        <div key={index} className="row">
          <div className="col">
            <label>
              <h4></h4>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                if (index === 0) {
                  for (let i = 0; i < newData.length; i++) {
                    newData[i].Operator = e.target.value;
                  }
                }
                else {
                  newData[index].Operator = e.target.value;
                }
                setData(newData);
              }}
              list="operatorList"
              id="operators"
              value={data[index].Operator}
              placeholder="Enter Operator"
            />
            <datalist id="operatorList">
              <option></option>
              {Morning_operators.map(x => {
                return <option>{x.Operators}</option>
              })}
            </datalist>
          </div>
          <div className="col">
            <br />
            <select type="select" class="form-control form-rounded" id='Area' placeholder="Area" onChange={(e) => {
              setArea(e.target.value)
            }} onClick={area} onKeyDown={area} name="area"
            > <option value="Others">Others</option>
              <option value="Flex">Flex</option>
              <option value="Rigid">Rigid</option>
            </select>
          </div>
          <div className="col-2">
            <br />
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Part_internalNo = e.target.value;
                setData(newData);
              }}
              onBlur={(e) => submitValue(e, index)} // Call the submitValue function on blur
              list="InternalPartNo"
              id="operator"
              placeholder="Enter Internal Part No"
            />
            <div className="scrollbar">
              <datalist id="InternalPartNo">
                <option></option>
                {part_dataset.map((x) => {
                  return <option>{x.Part_internalNo}</option>;
                })}
              </datalist>
            </div>
          </div>

          <div className="col" id='Hide'>
            <br />
            <input
              type="text"
              className="form-control form-rounded"
              value={data[index].Customer}
              placeholder="Enter Customer"
              readOnly
              required

            />
          </div>
          <div className="col" id='Hide'>
            <br />
            <input
              type="text"
              className="form-control form-rounded"
              value={data[index].PartNo}
              placeholder="Enter Part No"
              readOnly

            />
          </div>
          <div className="col" id='Hide'>
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              value={data[index].Part_description}
              readOnly
              placeholder="Enter Part Description"

            />
          </div>
          <div className="col-1">
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Target = e.target.value;
                newData[index].Produced = e.target.value;
                setData(newData);
              }}
              placeholder="Target"
              name="target"
              required
            />
          </div>
          <div className="col">
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                if (index === 0) {
                  for (let i = 0; i < newData.length; i++) {
                    newData[i].work_station = e.target.value;
                  }
                }
                else {
                  newData[index].work_station = e.target.value;
                }
                setData(newData);
              }}
              placeholder="Work Station"
              value={data[index].work_station}
              list="Work_station"
            />
            <datalist id="Work_station">
              <option></option>
              {Machine_list.map(x => {
                return <option>{x.Machines}</option>
              })}

            </datalist>
          </div>
          <div className="col-1">
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Produced = e.target.value;
                setData(newData);
              }}
              placeholder="Produced"
              name="produced"
              value={data[index].Produced}
              required
            />
          </div>
          <div className="col-sm">
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Scrap = e.target.value;
                setData(newData);
              }}
              placeholder="Scrap"
              name="scrap"
              required
            />
          </div>
          <div className="col-1">
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Operator_2 = e.target.value;
                setData(newData);
              }}
              placeholder="Operator-2 (Optional)"
              name="operator_2"
              list="operatorList"
              required
            />
          </div>
          <div className="col-2">
            <label>
              <b>
                <h3>Supervisor Notes</h3>
              </b>
            </label>
            <textarea
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Supervisor_notes = e.target.value;
                setData(newData);
              }}
              placeholder="Supervisor Notes"
              name="Supervisor_notes"
            />
          </div>
        </div>
      </div>
      ))}
      <button onClick={submitdata} class="btn btn-primary">Submit</button>
      <button onClick={addLines} class="btn btn-success">Add Row</button>
      <Link to="/RetrieveData">
        <button type="button" class="btn btn-success" >Read Table </button>
      </Link><Link to="/Scheduling">
        <button type="button" class="btn btn-secondary" >Scheduling </button>
      </Link>


    </div>
  )
}

export default Production_entry
import React, { useState, useEffect } from 'react'
import { Link, Redirect, useParams } from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { host, Host } from '../../App';
import real_l from "../../real_logo.png";
import Axios from 'axios'
import '../../OpenOrders.css'
import '../../Responsive.css'

function Open_orders_supplychain() {
  var [postList, setPostList] = useState([]);
  var [PoNo, setPoNo] = useState({});
  var [poNo, setpoNo] = useState([]);
  var [Item_Desc, setItem_Desc] = useState("");
  var [Date1, setDate1] = useState(new Date());
  var [Postartdate, setPoStartdate] = useState('2023-01-01');
  var [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  var [Supplier, setSupplier] = useState({});
  var [ItemNo, setItemNo] = useState("");
  var [Customers, setCustomers] = useState([]);
  var [partNo, setpartNo] = useState([]);
  var [order, setOrder] = useState("1");
  const [selectedButton, setSelectedButton] = useState('Received_Raw_Material');
  const [supplies, setsupplies] = useState(false);
  var [Category, setCategory] = useState({});
  var User = JSON.parse(window.localStorage.getItem("Name"));
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();
  var [page, setPage] = useState("Raw Material");

  const [data, setData] = useState([{ PO_No: "", Item_No: "", ReceivedQty: "", ActualReceiveddate: "", Invoice_cost: "", Unit_cost: "", PO_CURRENCY: "", Supplier: "", Item_Description: "", QTY: "", Unit_price_po: "", ETA: "", GRN_No: "" },]);

  //var token = sessionStorage.getItem("token");
  var token = (window.localStorage.getItem("acessToken"));

  useEffect(() => {
    Axios.get(`https://` + host + `/api/${selectedButton}/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)}${supplies ? `,${JSON.stringify(Category)}` : ''}`).then((data) => {
      const transformedData = data.data.map((item) => ({
        SrNo: item.SrNo || "",
        PO_No: item.PO_No || "",
        PO_Date: item.PO_Date || "",
        Item_No: item.Item_No || "",
        ReceivedQty: item.ReceivedQty || "",
        ActualReceiveddate: item.ActualReceiveddate || "",
        Invoice_cost: item.Invoice_cost || "",
        Item_Description: item.Item_Description || "",
        Supplier: item.Supplier || "",
        Comments: item.Comments || "",
        Unit_cost: item.COST || "",
        Unit_price_po: item.Unit_cost || "",
        PO_CURRENCY: item.PO_CURRENCY || "",
        QTY: item.Remained_parts || "0",
        PO_QTY: item.QTY || "0",
        ETA: item.ETA || "",
        GRN_No: item.GRN_No || "",
        Currency: item.CURRENCY || ""
      }));
      setData(transformedData);
    })
  }, [selectedButton]);
  useEffect(() => {
    Axios.get(`https://` + host + `/api/${selectedButton}/pono/${token}`).then((data) => {
      setpoNo(data.data)
    });
  }, [selectedButton])
  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + host + `/api/${selectedButton}/suppliers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [selectedButton])
  useEffect(() => {
    Axios.get(`https://` + host + `/api/${selectedButton}/itemno/${token}`).then((data) => {
      setpartNo(data.data)
    });
  }, [selectedButton])
  const search = () => {
    Axios.get(`https://` + host + `/api/${selectedButton}/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)}${supplies ? `,${JSON.stringify(Category)}` : ''}`).then((data) => {
      const transformedData = data.data.map((item) => ({
        SrNo: item.SrNo || "",
        PO_No: item.PO_No || "",
        PO_Date: item.PO_Date || "",
        Item_No: item.Item_No || "",
        ReceivedQty: item.ReceivedQty || "",
        ActualReceiveddate: item.ActualReceiveddate || "",
        Invoice_cost: item.Invoice_cost || "",
        Item_Description: item.Item_Description || "",
        Supplier: item.Supplier || "",
        Comments: item.Comments || "",
        Unit_cost: item.COST || "",
        Unit_price_po: item.Unit_cost || "",
        PO_CURRENCY: item.PO_CURRENCY || "",
        QTY: item.Remained_parts || "0",
        PO_QTY: item.QTY || "0",
        ETA: item.ETA || "",
        GRN_No: item.GRN_No || "",
        Currency: item.CURRENCY || ""
      }));
      setData(transformedData);
    })
  }
  const handleButtonClick = (buttonName) => {
    if (buttonName === "Received_Supplies") {
      setsupplies(true);
      setPage("Supplies");
    } else {
      setsupplies(false);
      setPage("Raw Material");
    }
    setSelectedButton(buttonName);
  };
  const totalQty = data.reduce((acc, item) => acc + (item.QTY || 0), 0);
  const totalAmountPO = data.reduce((acc, item) => acc + (parseFloat(item.QTY || 0) * parseFloat(item.Unit_price_po || 0)), 0).toFixed(2);
  const totalUnitCost = supplies ? null : data.reduce((acc, item) => acc + (item.Unit_cost || 0), 0).toFixed(2);
  const totalUnitPo = data.reduce((acc, item) => acc + parseFloat(item.Unit_price_po || 0), 0).toFixed(2);
  const handlePrint = () => {

    const table = document.querySelector('#alldata');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <table class="table table-striped">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };

  return (
    <div className="MainPage_data">
      <div class="Container">
        <div id='Openorder-option'>


          <div class="row">
            <div class="col-1"></div>
            <div class="col-3" ><button class="button" id='btn' name='Received_Raw_Material' onClick={() => handleButtonClick('Received_Raw_Material')} >Raw Material</button></div>
            <div class="col-3"></div>
            <div class="col-3"><button class="button" id='btn' name='Received_Supplies' onClick={() => handleButtonClick('Received_Supplies')}>Supplies</button></div>
            <div class="col-2"></div>

          </div>
        </div>
        <ToastContainer />
        <br></br>
        <div class="row">
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='PO NO' list="PoNo" onChange={(e) => {
                setPoNo(e.target.value)
              }} onSelect={search} />
              <datalist id="PoNo">
                <option></option>
                {poNo.map(x => {
                  return <option>{x.PO_No}</option>
                })}
              </datalist>
            </div >
            <div class="row">
              <div class="col">
                <Link to="/Historyrawmaterial"></Link>
              </div >
              <div class="col"></div >
            </div>
          </div >
          <div class="col">
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Item No' list='Itemno' onChange={(e) => {
                setItemNo(e.target.value)
              }} onSelect={search} />
              <datalist id="Itemno">
                <option></option>
                {partNo.map(x => {
                  return <option>{x.Item_No}</option>
                })}
              </datalist>
            </div>
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Item DESCRIPTION' list="Itemdesc" onChange={(e) => {
                setItem_Desc(e.target.value.replace(/[{"''"}]/g, ''));
              }} onSelect={search} />
              <datalist id="Itemdesc">
                <option></option>
                {postList.map(x => {
                  return <option>{x.Item_Description}</option>
                })}
              </datalist>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col" id='moldng'>
                {/* Grab the Itemicular dates */}
                <input type='date' class="form-control form-rounded" placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                  setPoStartdate(e.target.value)
                }} onSelect={search} id='openorder-button' />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                <span class="tooltiptext">Filter By ETA </span>

              </div>
              <div class="col">
                <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setPoEnddate(e.target.value)
                }} onSelect={search} id='openorder-button' />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Supplier */}
              <input type='text' class="form-control form-rounded" placeholder='Supplier' list='Suppliers' onChange={(e) => {
                setSupplier(e.target.value)
              }} onSelect={search} />
              <datalist id="Suppliers">
                <option></option>
                {Customers.map(x => {
                  return <option>{x.Supplier}</option>
                })}
              </datalist>
            </div>

            <div class="row">
              <div class="col">

              </div>
              <div class="col">
                <button className="btn btn-primary" onClick={handlePrint}>Print Table</button>
              </div>

            </div>
          </div>
        </div >


        <div class="datatable-wide" id='alldata'>
          <center><h1>{page}</h1></center>

          <br></br>
          <table class="table table-bordered table-condensed">
            <thead>
              <tr>
                <th>PO NO</th>
                <th>ITEM NO</th>
                <th>PO DATE</th>
                <th>ITEM DESCRIPTION</th>
                <th>SUPPLIER</th>
                <th>Remained QTY</th>
                <th>ETA</th>
                <th>CURRECNCY</th>
                <th>Unit Cost(PO)</th>
                <th>Amount(PO)</th>
                {supplies ? null : <th>Unit Price-Database</th>}
                <th id="comment">COMMENTS </th>
              </tr>
            </thead>
            {data.map((val, index) => (
              <tbody key={index}>
                <tr key={`${val.PO_No}-${val.Item_No}`}>
                  <td>{val.PO_No}</td>
                  <td>{val.Item_No}</td>
                  <td>{val.PO_Date ? val.PO_Date.slice(0, 10) : ''}</td>
                  <td>{val.Item_Description}</td>
                  <td>{val.Supplier}</td>
                  <td>{val.QTY}</td>
                  <td>{val.ETA ? val.ETA.slice(0, 10) : ''}</td>
                  {supplies ? <td>{val.Currency}</td> : <td>{val.PO_CURRENCY}</td>}
                  <td>${val.Unit_price_po}</td>
                  <td>${(val.QTY * val.Unit_price_po).toFixed(2)}</td>
                  {supplies ? null : <td>${val.Unit_cost}</td>}
                  <td>{val.Comments}</td>
                </tr>
              </tbody>
            )
            )}
            <tr>
              <td colSpan="5">Total</td>
              <td>{totalQty}</td>
              <td></td>
              <td></td>
              <td>${totalUnitPo}</td>
              <td>${totalAmountPO}</td>
              {totalUnitCost && supplies ? <td></td> : <td>${totalUnitCost}</td>}
              <td></td>
            </tr>
          </table>
        </div>
      </div >
    </div >
  )
}

export default Open_orders_supplychain;
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import './MainPage.css'
import { host } from '../../App';
import { CheckBox } from '@mui/icons-material';

function Main() {
  const [postList, setPostList] = useState([]);
  const [Operator, setOperator] = useState({});
  const [Date1, setDate1] = useState(new Date());
  const [startdate, setStartdate] = useState(Date1.toISOString().slice(0, 10));
  const [enddate, setEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [customer, setCustomer] = useState({});
  const [partInternalNo, setPartInternalNo] = useState({});
  const [operators, setoperators] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  var token = (window.localStorage.getItem("acessToken"));
  const [selectedRows, setSelectedRows] = useState({});
  var [del, setdel] = useState(1);


  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });
  // Default Data 
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/search/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${token}`).then((data) => {

      setPostList(data.data)
    });
  }, [del])

  // to retreive operators
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Operators/${token}`).then((data) => {
      setoperators(data.data)
    });
  }, [])

  //store values to delete data
  const handleCheckboxChange = (key) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [key]: !prevSelectedRows[key],
    }));
    console.log(selectedRows);
  };

  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Customers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])

  // to retreive part dataset
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)
    });
  }, [])

  // method to retrieve data combinely
  const search = () => {
    console.log("search called!");
    Axios.get(`https://` + host + `/realapi/search/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${token}`).then((data) => {

      setPostList(data.data)
    })
  }
  // method to retrieve data combinely
  const Delete = (date, operator, partnumber) => {
    Axios.delete(`https://` + host + `/realapi/delete/${date},${operator},${partnumber},${token}`).catch(function (error) {
      console.log(error.response);
      setdel(del + 1);
    });
    setTimeout(() => {
      search();
    }, 2000);
  }
  // method to retrieve data combinely

  return (
    <div className="MainPage_data">
      <div class="Container">
        <div class="row">
          <div class="col-md-auto">
            {/* Grab the particular Operator name */}
            <input type='text' class="form-control form-rounded" placeholder='Operator' list="operators" onChange={(e) => {
              setOperator(e.target.value)
            }} onSelect={search} />
            <datalist id="operators">
              <option></option>
              {operators.map(x => {
                return <option>{x.Morning_Operators}</option>
              })}
            </datalist>
          </div>
          <div class="col-md-auto">
            <div class="row" id='hidrow'>
              <div class="col-md-auto">
                {/* Grab the particular dates */}
                <input type='date' class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setStartdate(e.target.value)
                }} onSelect={search} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
              </div>
              <div class="col-md-auto">
                <input type='date' class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setEnddate(e.target.value)
                }} onSelect={search} />
              </div>
            </div>
          </div>
          <div class="col-md-auto">
            {/* Grab the particular Customer */}
            <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customers' onChange={(e) => {
              setCustomer(e.target.value)
            }} onSelect={search} />
            <datalist id="Customers">
              <option></option>
              {Customers.map(x => {
                return <option>{x.Customers}</option>
              })}
            </datalist>

          </div>
          <div class="col-md-auto">
            {/* Grab the particular partInternalNo */}
            <input type='text' class="form-control form-rounded" placeholder='Internal Part Number' list='InternalPartNo' onChange={(e) => {
              setPartInternalNo(e.target.value)
            }} onSelect={search} />
            <datalist id="InternalPartNo">
              <option></option>
              {part_dataset.map(x => {
                return <option>{x.Part_internalNo}</option>
              })}
            </datalist>
          </div>
        </div>
        <div>
          <Link to="/Morning">
            <button type='button' class="btn btn-success">
              <span class="glyphicon glyphicon-star" aria-hidden="true"></span> Back</button>
          </Link>
        </div>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Select</th>
              <th>Date</th>
              <th>Operator</th>
              <th>Part Internal No</th>
              <th>PART No</th>
              <th>CUSTOMER</th>
              <th>Part Description</th>
              <th>Target</th>
              <th>Work Station</th>
              <th>Produced</th>
              <th>Scrap</th>
              <th>Operator-2 </th>
              <th>Supervisor Notes</th>
              <th>Delete</th>
            </tr>
          </thead>
          {postList.map((val, key) => {
            return (
              <tbody id="tbody">
                <tr key={key}>
                  <td><input
                    type="checkbox"
                    checked={selectedRows[((val.Date.slice(0, 10)) + (val.Operator) + (val.PartNo))]}
                    onChange={() => handleCheckboxChange(((val.Date.slice(0, 10)), (val.Operator), (val.PartNo)))}
                  /></td>
                  <td>{val.Date.slice(0, 10)}</td>
                  <td>{val.Operator}</td>
                  <td>{val.Part_internalNo}</td>
                  <td>{val.PartNo}</td>
                  <td>{val.Customer}</td>
                  <td>{val.Part_description}</td>
                  <td>{val.Target}</td>
                  <td>{val.work_station}</td>
                  <td>{val.Produced}</td>
                  <td> {val.Scrap}</td>
                  <td>{val.Operator_2}</td>
                  <td>{val.Supervisor_notes}</td>
                  <td><button class="btn btn-danger" onClick={() => { window.confirm('Are you sure you want to delete this Entry?',) && Delete((val.Date.slice(0, 10)), (val.Operator), (val.PartNo)) }}> Delete</button></td>
                </tr>
              </tbody>
            )
          })}
        </table>
      </div>
    </div >
  )
}

export default Main;
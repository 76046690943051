// Import required modules
import React, { useState, useEffect, useRef } from 'react';
import './Dashboardnew.css'
import { Button, Menu, Dropdown, Row } from 'antd';
import { Link, Redirect, useParams } from "react-router-dom";
import Axios from 'axios'
import { Host } from '../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import AutoScrollBox from './Notes';
import Midblocks from './Midblocks';
import LineGraph from './LineGraph';
import Dashboard_line_graph from './Dashboard_line_graph';
import "../Responsive.css";
var User = "";

// Define the Dashboard component
const NewDashboard = (props) => {
  // Define the menu for the user dropdown
  User = window.localStorage.getItem("Name");
  const [Date1, setDate1] = useState(new Date());
  var [DailyData, setDailyData] = useState({});
  var [DailyDatasection, setDailyDatasection] = useState(false);

  var [LaskWeekNonUs, setlaskWeekNonUs] = useState([]);
  var [LaskWeekUs, setlaskWeekUs] = useState([]);
  var [NextWeekNonUs, setNextWeekNonUs] = useState([]);
  var [NextWeekUs, setNextWeekUs] = useState([]);
  const [showMidblock, setShowMidblock] = useState(1);
  var token = (window.localStorage.getItem("acessToken"));
  var [Switch, setSwitch] = useState(false);
  var Ranked = (window.localStorage.getItem("Rank"));
  var Stage = (window.localStorage.getItem("Stage"));

  const [rank, setrank] = useState(false);
  const queryParams = {
    start_date: Date1.toISOString().slice(0, 10),
    token: token
  };

  useEffect(() => {
    Axios.get(`https://${Host}/ship_Today`, { params: queryParams }).then((data) => {
      if (data.data.length > 0) {
        setDailyData(data.data);
        DailyData = data.data;
        setDailyDatasection(true);
      } else if (data.data.length <= 0) {
        setDailyDatasection(false);
        setDailyData("No data");
      }
    });

  }, [])

  useEffect(() => {
    if (Ranked === '"Admin"') {
      setrank(true);
    }
  }, [Ranked]);

  useEffect(() => {
    Axios.get(`https://${Host}/shipped_lastweek_nonUs`, { params: queryParams }).then((data) => {
      if (data.data.length > 0) {
        setlaskWeekNonUs(data.data[0].QTY);
        LaskWeekNonUs = data.data[0].QTY;
        setShowMidblock(prevShowMidblock => prevShowMidblock + 1);
      } else if (data.data.length <= 0) {
        LaskWeekNonUs = "No data";
      }
    });

  }, [])
  useEffect(() => {
    Axios.get(`https://${Host}/shipped_lastweek_Us`, { params: queryParams }).then((data) => {
      if (data.data.length > 0) {
        setlaskWeekUs(data.data[0].QTY);
        LaskWeekUs = data.data[0].QTY;
        setShowMidblock(prevShowMidblock => prevShowMidblock + 1);
      } else if (data.data.length <= 0) {
        LaskWeekUs = "No data";
      }
    });

  }, [])
  useEffect(() => {
    Axios.get(`https://${Host}/ship_nextweek_nonUs`, { params: queryParams }).then((data) => {
      if (data.data.length > 0) {
        setNextWeekNonUs(data.data[0].QTY);
        NextWeekNonUs = data.data[0].QTY;
        setShowMidblock(prevShowMidblock => prevShowMidblock + 1);
      } else if (data.data.length <= 0) {
        NextWeekNonUs = "No data";
      }
    });

  }, [])
  useEffect(() => {
    Axios.get(`https://${Host}/ship_nextweek_Us`, { params: queryParams }).then((data) => {
      if (data.data.length > 0) {
        setNextWeekUs(data.data[0].QTY);
        NextWeekUs = data.data[0].QTY;
        setShowMidblock(prevShowMidblock => prevShowMidblock + 1);
      } else if (data.data.length <= 0) {
        NextWeekUs = "No data";
      }
    });
  }, [])
  const midblock = {
    LaskWeekNonUs: LaskWeekNonUs,
    LaskWeekUs: LaskWeekUs,
    NextWeekNonUs: NextWeekNonUs,
    NextWeekUs: NextWeekUs
  };

  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("Rank");
    window.localStorage.removeItem("Stage");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    Axios.get(`https://` + Host + `/logout`).then((data) => {
      console.log(data);
      props.history.push('/');
    })
  }

  const toggleswitch = () => {
    setSwitch(!Switch);
  }
  const userMenu = (
    <Menu>
      <Menu.Item key="logout">
        <a onClick={handleLogout} href="/">
          <LogoutOutlined />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );
  // Render the component
  return (
    <div class="dashboard_body">
      {/* Render the top bar */}
      <div className="top-bar">
        {/* Render the logo */}
        {/* Render the user dropdown */}
        <div className="user-dropdown">
          <Dropdown overlay={userMenu} trigger={['click']}>

            <Button icon={<UserOutlined />} size="large" id='logout_button'>
              Welcome {User.replace(/[{""}]/g, "")}!
            </Button>
          </Dropdown>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-2">
            <td class="col-2" id='block-1'>

              <div className="block">
                <Link to="/adddata">
                  <Button type="primary" size="large">
                    PO Data
                  </Button>
                </Link>
                <p>Add new PO</p>
              </div>
              <div className="block">
                <Link to="/reaminedparts">
                  <Button type="primary" size="large">
                    Open Orders
                  </Button>
                </Link>
                <p>Check Open Orders</p>
              </div>
              <div className="block">
                <Link to="/pivot">
                  <Button type="primary" size="large">
                    Pivot
                  </Button>
                </Link>
                <p>Get Pivot table</p>
              </div>
              <div className="block">
                <Link to="/attendance">
                  <Button type="primary" size="large">
                    Attendance
                  </Button>
                </Link>
                <p>Attendance Portal</p>
              </div>
              <div className="block">
                <Button type="primary" size="large" onClick={toggleswitch}>
                  Daily Data Record
                </Button>
                <p>Add Daily Production</p>
              </div>
              <div className="block">
                <Link to="/Production">
                  <Button type="primary" size="large">
                    Production
                  </Button>
                </Link>
                <p>Production data</p>
              </div>
              {rank ?
                <div className="block">
                  <Link to="/suppluchain">
                    <Button type="primary" size="large">
                      Supply Chain
                    </Button>
                  </Link>
                  <p>supply Chain Data</p>
                </div> : null}
              <div className="block">
                <Link to="/">
                  <Button type="primary" onClick={handleLogout} size="large">
                    Logout
                  </Button>
                </Link>
                <p>{User.replace(/[{""}]/g, "")}</p>
              </div>
            </td>
          </div>

          {!Switch ?
            <div class="col-sm-6">
              <h1>Today's Shipment Consignees</h1>

              <a href="/pivot" style={{ "text-decoration": "none", "background": "none" }}>
                <div className="table-container">
                  <table className="table" id='ship_consignee' style={{ "--bs-table-bg": "none" }}>
                    {DailyDatasection ?
                      <tbody>
                        {[...Array(Math.ceil(DailyData.length / 3))].map((_, i) => (
                          <tr key={i}>
                            {[...Array(3)].map((_, j) => {
                              const index = i * 3 + j;
                              if (index >= DailyData.length) {
                                return <td key={j}></td>;
                              }
                              const { CUSTOMER, Remained_parts } = DailyData[index];
                              return (
                                <td key={j} class="table_td" >
                                  <button id="cus_btn" class="btn btn-lg btn-primary">
                                    {CUSTOMER} : {Remained_parts}
                                  </button>
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                      : <h2 style={{ "color": "blue" }}>Nothing to Ship</h2>
                    }
                  </table>
                </div>
              </a>

              <br />
            </div> : <div class="col-sm-6"><Dashboard_line_graph /></div>}
          <div class="col-sm-4">
            <div class="dash_notes">
              <AutoScrollBox />

            </div>

          </div>
        </div>
      </div>
    </div >
  );
};

export default NewDashboard;

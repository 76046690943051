import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { host } from '../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import "./MainPage.css";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { Button, Menu, Dropdown, Row } from 'antd';
import { Link } from "react-router-dom";
function Moulding_entry(props) {
  var token = (window.localStorage.getItem("acessToken"));
  const user = JSON.parse(window.localStorage.getItem("Name"));
  var [Date1, setDate1] = useState(new Date());
  const [Date2, setDate2] = useState(Date1.toISOString().slice(0, 10));
  const [part_dataset, setpart_dataset] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Upholstery_, setUpholstery_] = useState(true);
  const [Cushions, setCushions] = useState(false);
  const [Buds, setBuds] = useState(false);
  const [Pleat, setPleat] = useState(false);
  const [Drape, setDrape] = useState(false);
  const [Haru, setHaru] = useState(false);
  const [Nora, setNora] = useState(false);
  var length = 0;
  const [Area, setArea] = useState("");

  const [data, setData] = useState([
    { Po_No: '', Part_No: '', Po_Ship_Date: Date2, Part_Description: '', Customer: '', Qty: '', Po_Date: '', FabricStatus: '', FabricStyle: '', FabricColor: '', Orientation: '', SalesOrderNo: '', BaseColor: '', FabricInsideBack: '', FabricOutsideBack: '', FabricSeat: '', Piping: '', FabricBack: '', WoodOutsideBack: '', UnitPrice: '', Comments: '', Wood_Seat_And_Back_Species: '', Wood_Seat_And_Back_Stain_Color: '', Seat_Pad_Fabric: '' }
    , { Po_No: '', Part_No: '', Po_Ship_Date: Date2, Part_Description: '', Customer: '', Qty: '', Po_Date: '', FabricStatus: '', FabricStyle: '', FabricColor: '', Orientation: '', SalesOrderNo: '', BaseColor: '', FabricInsideBack: '', FabricOutsideBack: '', FabricSeat: '', Piping: '', FabricBack: '', WoodOutsideBack: '', UnitPrice: '', Comments: '', Wood_Seat_And_Back_Species: '', Wood_Seat_And_Back_Stain_Color: '', Seat_Pad_Fabric: '' },
    { Po_No: '', Part_No: '', Po_Ship_Date: Date2, Part_Description: '', Customer: '', Qty: '', Po_Date: '', FabricStatus: '', FabricStyle: '', FabricColor: '', Orientation: '', SalesOrderNo: '', BaseColor: '', FabricInsideBack: '', FabricOutsideBack: '', FabricSeat: '', Piping: '', FabricBack: '', WoodOutsideBack: '', UnitPrice: '', Comments: '', Wood_Seat_And_Back_Species: '', Wood_Seat_And_Back_Stain_Color: '', Seat_Pad_Fabric: '' },
    { Po_No: '', Part_No: '', Po_Ship_Date: Date2, Part_Description: '', Customer: '', Qty: '', Po_Date: '', FabricStatus: '', FabricStyle: '', FabricColor: '', Orientation: '', SalesOrderNo: '', BaseColor: '', FabricInsideBack: '', FabricOutsideBack: '', FabricSeat: '', Piping: '', FabricBack: '', WoodOutsideBack: '', UnitPrice: '', Comments: '', Wood_Seat_And_Back_Species: '', Wood_Seat_And_Back_Stain_Color: '', Seat_Pad_Fabric: '' },
    { Po_No: '', Part_No: '', Po_Ship_Date: Date2, Part_Description: '', Customer: '', Qty: '', Po_Date: '', FabricStatus: '', FabricStyle: '', FabricColor: '', Orientation: '', SalesOrderNo: '', BaseColor: '', FabricInsideBack: '', FabricOutsideBack: '', FabricSeat: '', Piping: '', FabricBack: '', WoodOutsideBack: '', UnitPrice: '', Comments: '', Wood_Seat_And_Back_Species: '', Wood_Seat_And_Back_Stain_Color: '', Seat_Pad_Fabric: '' }
  ]);


  // Hook to update the date as today's date
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  useEffect(() => {
    Axios.get(`https://` + host + `/api/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data);
    });
  }, []);

  useEffect(() => {
    const newData = data.map((line) => ({ ...line, Date: Date2 }));
    setData(newData);
  }, [Date2])

  const area = () => {
    if (Area == "Upholstery") {
      setUpholstery_(true);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setNora(false);
      setPleat(false);
    }
    if (Area == "Cushions") {
      setUpholstery_(false);
      setCushions(true);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setNora(false);
      setPleat(false);
    }
    if (Area == "Buds") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(true);
      setDrape(false);
      setHaru(false);
      setNora(false);
      setPleat(false);
    }
    if (Area == "Drape") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(true);
      setHaru(false);
      setNora(false);
      setPleat(false);
    }

    if (Area == "Haru") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(true);
      setNora(false);
      setPleat(false);
    }

    if (Area == "Pleat") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setPleat(true);
      setNora(false);
    }
    if (Area == "Nora") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setPleat(false);
      setNora(true);
    }
  };

  const submitValue = (e, index) => {
    const newData = [...data];
    newData[index].Part_No = e.target.value;
    newData[index].Customer = (part_dataset.find((x) => x.Part_No === e.target.value).Customer);
    newData[index].Part_Description = part_dataset.find((x) => x.Part_No === e.target.value).Part_Description;
    setData(newData);
  }

  const addLines = () => {
    if (data.length < 15) {
      const newData = [...data];
      for (let i = 0; i < 5; i++) {
        newData.push({ Po_No: '', Part_No: '', Po_Ship_Date: Date2, Part_Description: '', Customer: '', Qty: '', Po_Date: '', FabricStatus: '', FabricStyle: '', FabricColor: '', Orientation: '', SalesOrderNo: '', BaseColor: '', FabricInsideBack: '', FabricOutsideBack: '', FabricSeat: '', Piping: '', FabricBack: '', WoodOutsideBack: '', UnitPrice: '', Comments: '', Wood_Seat_And_Back_Species: '', Wood_Seat_And_Back_Stain_Color: '', Seat_Pad_Fabric: '' });
      }
      setData(newData);
    } else {
      window.alert("Lines Reached maximum limit")
    }

  }
  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    props.history.push('/');
  }
  const userMenu = (
    <Menu>
      <Menu.Item key="logout">
        <a onClick={handleLogout} href="/">
          <LogoutOutlined />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const submitdata = () => {
    const filteredData = [];

    data.forEach(item => {
      // Check if either Po_No or Part_No is entered but Po_Ship_Date or Po_Date is missing
      if ((item.Po_No || item.Part_No) && (!item.Po_Ship_Date || !item.Po_Date)) {
        /* eslint-disable no-restricted-globals */
        const proceed = confirm("Some fields are missing (Po Ship Date or Po Date). Do you want to continue?");
        /* eslint-enable no-restricted-globals */

        if (!proceed) {
          // If the user chooses to fill in the missing values, skipthe rest of the loop
          return;
        }
      }
      if (
        item.Po_No &&
        item.Part_No &&
        item.Customer &&
        item.Part_Description &&
        item.Po_Ship_Date &&
        item.Po_Date
      ) {
        const newData = [
          item.Po_No,
          item.Part_No,
          item.Po_Ship_Date,
          item.Part_Description,
          item.Customer,
          item.Qty,
          item.Po_Date,
          item.Comments,
          item.FabricStatus,
          item.FabricStyle,
          item.FabricColor,
          item.Orientation,
          item.SalesOrderNo,
          item.BaseColor,
          item.FabricInsideBack,
          item.FabricOutsideBack,
          item.FabricSeat,
          item.Piping,
          item.FabricBack,
          item.WoodOutsideBack,
          item.UnitPrice,
          item.Wood_Seat_And_Back_Species,
          item.Wood_Seat_And_Back_Stain_Color,
          item.Seat_Pad_Fabric,
          user,
        ];
        filteredData.push(newData);
      }
      length = filteredData.length;
    });
    console.log(filteredData);
    setIsLoading(true);
    if (
      filteredData.length > 0
    ) {
      setTimeout(() => {
        Axios.post(`https://` + host + `/api/Upholstery_entry`, { filteredData, user, token })
          .then((response) => {
            if (response.status === 200) {
              window.alert(response.data.message);
              setIsLoading(false);
              window.location.reload();

            }
          })
          .catch(error => {
            window.alert("Error Occured")
            setIsLoading(false);
          });
      }, 2000);

    }

    else {
      window.alert("Please fill all sections!");
      setIsLoading(false);
    }

    // window.location.reload(false);
  }
  const print = () => {
    console.log(data);
  }
  return (
    <div>
      <br></br>
      <div class="row">
        <div class="col">
          <select
            type="select"
            class="form-control form-rounded"
            id="Area"
            placeholder="Area"
            name="area"
            onKeyDown={area}
            onTouchMove={area}
            onSelect={area}
            onChange={(e) => {
              setArea(e.target.value);
            }}
            onClick={area}
          >
            <option value="Upholstery">Upholstery</option>
            <option value="Cushions">Cushions/Panels</option>
            <option value="Buds">Buds</option>
            <option value="Pleat">Pleat/Melvin</option>
            <option value="Drape">Drape</option>
            <option value="Haru">Haru</option>
            <option value="Nora">Nora</option>
          </select>
        </div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
      <br></br>
      <button onClick={addLines} class="btn btn-success">Add Row</button>
      <br></br>
      <br></br>

      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>PO NO</th>
            <th>PART NO</th>
            <th>PO SHIP DATE</th>
            <th>PART DESCRIPTION</th>
            <th>CUSTOMER</th>
            <th>QTY</th>
            <th>PO DATE</th>
            {Buds || Cushions || Upholstery_ ? <th>FABRIC STATUS</th> : null}
            {Cushions || Buds || Haru ? <th> FABRIC STYLE / FABRIC BODY</th> : null}
            {Cushions || Buds ? <th>FABRIC COLOR / FABRIC TOP</th> : null}
            {Cushions ? <th>ORIENTATION</th> : null}
            {Cushions ? <th>SALES ORDER NO</th> : null}
            {Pleat || Drape || Nora ? <th>BASE COLOR</th> : null}
            {Nora ? <th>WOOD SEAT AND BACK SPECIES</th> : null}
            {Nora ? <th>WOOD SEAT AND BACK STAIN COLOUR</th> : null}
            {Nora ? <th>SEAT PAD FABRIC</th> : null}
            {Pleat || Drape ? <th>FABRIC INSIDE BACK</th> : null}
            {Pleat || Drape ? <th>FABRIC OUTSIDE BACK</th> : null}
            {Pleat || Drape ? <th>FABRIC SEAT</th> : null}
            {Pleat ? <th>PIPING</th> : null}
            {Drape ? <th>FABRIC BACK</th> : null}
            {Haru ? <th>WOOD OUTSIDE BACK</th> : null}
            <th>UNIT PRICE</th>
            <th>COMMENTS</th>
          </tr>
          {data.map((line, index) => (
            <tr>
              <td>
                <input type="text" class="form-control form-rounded" name="PoNo"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Po_No = e.target.value;
                    setData(newData);
                  }}
                  Value={data[index].Po_No} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="PartNo" Value={data[index].Part_No} onBlur={(e) => {
                  const newData = [...data];
                  newData[index].Part_No = e.target.value;
                  setData(newData);
                  submitValue(e, index)
                }} list="PartNo" />
                <div className="scrollbar">
                  <datalist id="PartNo">
                    <option></option>
                    {part_dataset.map((x) => {
                      return <option>{x.Part_No}</option>;
                    })}
                  </datalist>
                </div>
              </td>
              <td>
                <input type="date" class="form-control form-rounded" name="PoShipDate" defaultValue={Date1.toISOString().slice(0, 10)} onBlur={(e) => {
                  const newData = [...data];
                  newData[index].Po_Ship_Date = e.target.value;
                  setData(newData);
                }} Value={data[index].Po_Ship_Date} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="PartDescription" value={data[index].Part_Description} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Customer" value={data[index].Customer} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Qty" onBlur={(e) => {
                  const newData = [...data];
                  newData[index].Qty = e.target.value;
                  setData(newData);
                }} Value={data[index].Qty} />
              </td>
              <td>
                <input type="date" class="form-control form-rounded" name="PoDate" onBlur={(e) => {
                  const newData = [...data];
                  newData[index].Po_Date = e.target.value;
                  setData(newData);
                }} Value={data[index].Po_Date} />
              </td>
              {Buds || Cushions || Upholstery_ ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricStatus" list="FabricStatus" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].FabricStatus = e.target.value;
                    setData(newData);
                  }} Value={data[index].FabricStatus} />
                  <div class="scrollbar">
                    <datalist id="FabricStatus">
                      <option></option>
                      <option>COM</option>
                      <option>PUR</option>
                      <option>IN HOUSE</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Cushions || Buds || Haru ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricStyle" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].FabricStyle = e.target.value;
                    setData(newData);
                  }} Value={data[index].FabricStyle} />
                </td>
              ) : (
                null
              )}
              {Cushions || Buds ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricColor" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].FabricColor = e.target.value;
                    setData(newData);
                  }} Value={data[index].FabricColor} />
                </td>
              ) : (
                null
              )}
              {Cushions ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="Orientation" list="orientation" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].Orientation = e.target.value;
                    setData(newData);
                  }} Value={data[index].Orientation} />
                  <div class="scrollbar">
                    <datalist id="orientation">
                      <option></option>
                      <option>RR</option>
                      <option>OTB</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Cushions ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="SalesOrderNo" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].SalesOrderNo = e.target.value;
                    setData(newData);
                  }} Value={data[index].SalesOrderNo} />
                </td>) : (null)}
              {Pleat || Drape || Nora ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="BaseColor" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].BaseColor = e.target.value;
                    setData(newData);
                  }} Value={data[index].BaseColor} />
                </td>
              ) : (
                null
              )}
              {/* added for Noru */}

              {Nora ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="Wood Seat And Back Species" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].Wood_Seat_And_Back_Species = e.target.value;
                    setData(newData);
                  }} Value={data[index].Wood_Seat_And_Back_Species} />
                </td>
              ) : (
                null
              )}
              {Nora ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="Wood Seat And Back Stain Color" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].Wood_Seat_And_Back_Stain_Color = e.target.value;
                    setData(newData);
                  }} Value={data[index].Wood_Seat_And_Back_Stain_Color} />
                </td>
              ) : (
                null
              )}{Nora ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="Seat Pad Fabric" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].Seat_Pad_Fabric = e.target.value;
                    setData(newData);
                  }} Value={data[index].Seat_Pad_Fabric} />
                </td>
              ) : (
                null
              )}
              {/* added for Noru */}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricInsideBack" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].FabricInsideBack = e.target.value;
                    setData(newData);
                  }} Value={data[index].FabricInsideBack} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricOutsideBack" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].FabricOutsideBack = e.target.value;
                    setData(newData);
                  }} Value={data[index].FabricOutsideBack} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricSeat" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].FabricSeat = e.target.value;
                    setData(newData);
                  }} Value={data[index].FabricSeat} />
                </td>
              ) : (
                null
              )}
              {Pleat ? (
                <td>
                  <input type="boolean" class="form-control form-rounded" name="Piping" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].Piping = e.target.value;
                    setData(newData);
                  }} Value={data[index].Piping} list="PIPING" />
                  <div class="scrollbar">
                    <datalist id="PIPING">
                      <option></option>
                      <option>YES</option>
                      <option>NO</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricBack" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].FabricBack = e.target.value;
                    setData(newData);
                  }} Value={data[index].FabricBack} />
                </td>
              ) : (
                null
              )}
              {Haru ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="WoodOutsideBack" onBlur={(e) => {
                    const newData = [...data];
                    newData[index].WoodOutsideBack = e.target.value;
                    setData(newData);
                  }} Value={data[index].WoodOutsideBack} />
                </td>
              ) : (
                null
              )}
              <td>
                <input type="text" class="form-control form-rounded" name="UnitPrice" onBlur={(e) => {
                  const newData = [...data];
                  newData[index].UnitPrice = e.target.value;
                  setData(newData);
                }} Value={data[index].UnitPrice} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Comments" onBlur={(e) => {
                  const newData = [...data];
                  newData[index].Comments = e.target.value;
                  setData(newData);
                }} Value={data[index].Comments} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={submitdata} class="btn btn-primary">Submit</button>
      {isLoading ?
        <div className='overlay'>
          <div className="loading-container">
            <div className="loading-dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            <p className='loading'>Submitting</p>
          </div>

        </div> : null}
    </div>
  )
}

export default Moulding_entry
import React, { useState, useEffect } from 'react';
import Axios from 'axios';

function Trial() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [vacationData, setVacationData] = useState([]);
  const [date, setDate] = useState('');
  const [person, setPerson] = useState('');
  const [shift, setShift] = useState('');

  // Handle file change
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    Axios.get(`http://localhost:3002/get-urls`).then((response) => {
      setVacationData(response.data);
    });
  }, []);

  // Handle file upload
  const uploadFile = () => {
    if (!selectedFile || !date || !person || !shift) {
      window.alert('Please fill in all fields and select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('date', date);
    formData.append('person', person);
    formData.append('shift', shift);

    Axios.post('http://localhost:3002/api/upload', formData)
      .then((response) => {
        if (response.status === 200) {
          window.alert(response.data.message);
          setUploadedFiles([...uploadedFiles, response.data.url]);
        }
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        window.alert('Error occurred while uploading the file');
      });
  };

  return (
    <div>
      <h2>File Upload with Metadata</h2>
      <div>
        <input type="file" onChange={handleFileChange} />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          placeholder="Select Date"
        />
        <input
          type="text"
          value={person}
          onChange={(e) => setPerson(e.target.value)}
          placeholder="Enter Person"
        />
        <select value={shift} onChange={(e) => setShift(e.target.value)}>
          <option value="">Select Shift</option>
          <option value="Morning">Morning</option>
          <option value="Evening">Evening</option>
        </select>
        <button onClick={uploadFile}>Upload File</button>
      </div>

      {uploadedFiles.map((url, index) => (
        <li key={index}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
          <img
            src={url}
            alt={`Uploaded file ${index + 1}`}
            style={{ width: '200px', marginTop: '10px' }}
          />
        </li>
      ))}

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>URL</th>
            <th>Date</th>
            <th>Person</th>
            <th>Shift</th>
          </tr>
        </thead>
        {vacationData.map((val, key) => (
          <tbody key={key}>
            <tr>
              <td>{val.url}</td>
              <td>{val.date}</td>
              <td>{val.person}</td>
              <td>{val.shift}</td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}

export default Trial;

import React, { useState, useEffect } from 'react'
import { Link, Redirect, useParams } from "react-router-dom"
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { host, Host } from '../App';
import Axios from 'axios'
import '../Responsive.css'
import real_l from "../real_logo.png";


function Upholstery_Schedule() {
  var [Date1, setDate1] = useState(new Date());
  /*created for Upholstery schedule*/
  var [PoNo, setPoNo] = useState({});
  const [Area, setArea] = useState("");
  const [PartNo, setPartNo] = useState({});
  var [Postartdate, setPoStartdate] = useState('2024-01-01');
  var [Poenddate, setPoEnddate] = useState(Date1);
  const [customer, setCustomer] = useState({});
  var [order, setOrder] = useState("1");
  var User = JSON.parse(window.localStorage.getItem("Name"));
  var [del, setdel] = useState(1);
  var [poNo, setpoNo] = useState([]);
  const [Edit, setEdit] = useState(false);
  const [date, setDate] = useState(new Date().toISOString());
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();
  const [All_columns, setAll_columns] = useState(false);
  const [data, setData] = useState([{ PO_No: "", Item_No: "", ReceivedQty: "", ActualReceiveddate: "", Invoice_cost: "", Unit_cost: "", PO_CURRENCY: "", Supplier: "", Item_Description: "", QTY: "", Unit_price_po: "", ETA: "", GRN_No: "" },]);
  const [isLoading, setIsLoading] = useState(false);
  var [orderHis, setOrderHis] = useState("1");



  const queryParams = {
    token: token,
    User: User,
    PoNo: PoNo,
    PartNo: PartNo,
    Postartdate: Postartdate,
    Poenddate: Poenddate,
    customer: customer,
    order: orderHis
  }
  //var token = sessionStorage.getItem("token");
  var token = (window.localStorage.getItem("acessToken"));


  useEffect(() => {
    setIsLoading(true);
    Axios.get(`https://` + host + `/api/Upholstery_Schedule/search`, { params: queryParams }).then((data) => {
      const transformedData = data.data.map((item) => ({
        Part_No: item.PART_NO || "",
        Part_Description: item.Part_Description || "",
        Customer: item.Customer || "",
        Size: item.Size || "",
        Unit_Price: item.Unit_Price || "",
        BASE_STYLE_TUOHY: item.BASE_STYLE_TUOHY || "",
        Type: item.Type || "",
        Woodshop: item.Woodshop || "",
        Molding: item.Molding || "",
        Gluing: item.Gluing || "",
        Fabric_Cutting: item.Fabric_Cutting || "",
        Single_Stitching: item.Single_Stitching || "",
        Top_Stitching: item.Top_Stitching || "",
        Upholstery: item.Upholstery || "",
        Assembly: item.Assembly || "",
        Finishing: item.Finishing || "",
        Packing: item.Packing || "",
        Size_of_Bag: item.Size_of_Bag || "",
        Box: item.Box || "",
        Bo: item.Bo || "",
        Woodshop2: item.Woodshop2 || "",
        PO_NO: item.PO_NO || "",
        PO_SHIP_DATE: item.PO_SHIP_DATE || "",
        PO_DATE: item.PO_DATE || "",
        QTY: item.QTY || "0",
        Remained_parts: item.Remained_parts || "0",
        FABRIC_STATUS: item.FABRIC_STATUS || "",
        FABRIC_STYLE: item.FABRIC_STYLE || "",
        FABRIC_COLOR: item.FABRIC_COLOR || "",
        ORIENTATION: item.ORIENTATION || "",
        BASE_COLOR: item.BASE_COLOR || "",
        FABRIC_INSIDE_BACK: item.FABRIC_INSIDE_BACK || "",
        FABRIC_OUTSIDE_BACK: item.FABRIC_OUTSIDE_BACK || "",
        FABRIC_SEAT: item.FABRIC_SEAT || "",
        PIPING: item.PIPING || "",
        FABRIC_BACK: item.FABRIC_BACK || "",
        WOOD_OUTSIDE_BACK: item.WOOD_OUTSIDE_BACK || "",
        SALES_ORDER_NO: item.SALES_ORDER_NO || "",
        Woodshop_status: item.Woodshop_status || 0,
        Molding_status: item.Molding_status || 0,
        Fabric_Cutting_status: item.Fabric_Cutting_status || 0,
        Gluing_status: item.Gluing_status || 0,
        Single_Stitching_status: item.Single_Stitching_status || 0,
        Top_Stitching_status: item.Top_Stitching_status || 0,
        Upholstery_status: item.Upholstery_status || 0,
        Assembly_status: item.Assembly_status || 0,
        Finishing_status: item.Finishing_status || 0,
        Packing_status: item.Packing_status || 0,
        User: User,
      }
      ));

      const filteredOrders = transformedData.filter(val => {
        // Check if all required steps are completed (based on the 'Yes' value)
        const allStepsCompleted = (
          (val.Woodshop === "No" || Number(val.Woodshop_status) === Number(val.QTY)) &&
          (val.Molding === "No" || Number(val.Molding_status) === Number(val.QTY)) &&
          (val.Gluing === "No" || Number(val.Gluing_status) === Number(val.QTY)) &&
          (val.Fabric_Cutting === "No" || Number(val.Fabric_Cutting_status) === Number(val.QTY)) &&
          (val.Single_Stitching === "No" || Number(val.Single_Stitching_status) === Number(val.QTY)) &&
          (val.Top_Stitching === "No" || Number(val.Top_Stitching_status) === Number(val.QTY)) &&
          (val.Upholstery === "No" || Number(val.Upholstery_status) === Number(val.QTY)) &&
          (val.Assembly === "No" || Number(val.Assembly_status) === Number(val.QTY)) &&
          (val.Finishing === "No" || Number(val.Finishing_status) === Number(val.QTY)) &&
          (val.Packing === "No" || Number(val.Packing_status) === Number(val.QTY))
        );

        if (order === "1") {
          // Show open orders only (not fully completed)
          return !allStepsCompleted;
        } else {
          // Show closed orders only (fully completed)
          return allStepsCompleted;
        }
      });


      setData(filteredOrders);
      setIsLoading(false);
    })
  }, [customer, PoNo, PartNo, Postartdate, Poenddate, del, order, orderHis]);

  const [changedRows, setChangedRows] = useState(new Set());
  const handleUpdate = (e, index, key) => {
    const { value } = e.target;
    updateData(index, key, value);
  };


  const updateData = (index, key, value) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      // Check if both ReceivedQty and ActualReceiveddate are set and valid
      // const isValid = (row) => row.ReceivedQty && row.ActualReceiveddate && row.ActualReceiveddate !== '0000-00-00' && row.Invoice_cost;

      const isValid = (row) => {
        // Helper function to check if a date is valid
        const isValidDate = (date) => {
          return date && date !== '0000-00-00' && !isNaN(new Date(date).getTime());
        };

        return (
          row.ReceivedQty &&
          row.Invoice_cost &&
          isValidDate(row.ActualReceiveddate)
        );
      };

      if (isValid(newData[index])) {
        setChangedRows(prevRows => {
          const newSet = new Set(prevRows);
          newSet.add(index);
          return newSet;
        });
      }

      setChangedRows(prevRows => {
        const newSet = new Set(prevRows);
        newSet.add(index);
        return newSet;
      });
      return newData;
    });
  };

  const ToggleEdit = () => {
    setEdit(!Edit);
  }
  const ToggleAllColumns = () => {
    setAll_columns(!All_columns);
  }
  const handlePrint = () => {
    setEdit(false);
    const table = document.querySelector('#RRM');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 70%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
            width:20px
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          table tr:last-child td {
            font-weight: bold;
            
          }
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <table class="table table-bordered">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };
  const Update = () => {
    const changedData = [...changedRows].map(index => {
      return data[index];
    });
    setIsLoading(true);
    console.log(changedData);

    Axios.post(`https://` + host + `/Upholstery_Schedule/Update`, { changedData: JSON.stringify(changedData), token: token })
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          toast.success(response.data.message, {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
            style: { fontSize: '24px', borderRadius: '10px' },
          });

        }
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error.response);
      });
    setTimeout(() => {
      setdel(del = del + 1)
    }, 1000);
  };


  return (
    <div className="MainPage_data">
      <div class="Container">
        <ToastContainer />
        <div className="responsive-form-group">
          <div class="row" id='openorder_filter'>
            <div class="col">
              <div class="row">
                {/* Grab the particular Operator name */}
                <input type='text' class="form-control form-rounded" placeholder='PO NO' list="PoNo" onChange={(e) => {
                  setPoNo(e.target.value)
                }} />
                <datalist id="PoNo">
                  <option></option>
                  {data.map(x => {
                    return <option>{x.PO_NO}</option>
                  })}
                </datalist>
              </div>

            </div>
            <div class="col">
              <div class="row">
                {/* Grab the particular Operator name */}
                <input type='text' class="form-control form-rounded" placeholder='PART NO' list="PartNo" onChange={(e) => {
                  setPartNo(e.target.value)
                }} />
                <datalist id="PartNo">
                  <option></option>
                  {data.map(x => {
                    return <option>{x.Part_No}</option>
                  })}
                </datalist>
              </div>
              <div class="row">
                <Link to="/Upholstery_Schedule">
                  <button className="btn btn-primary">Upholstery Schedule</button>
                </Link>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col" id="moldng">
                  {/* Grab the particular dates */}
                  <input type='date' class="form-control form-rounded" placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                    setPoStartdate(e.target.value)
                  }} />
                  {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                  <span class="tooltiptext">Filter By PO SHIP DATE </span>

                </div>
                <div class="col">
                  <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                    setPoEnddate(e.target.value)
                  }} />
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                {/* Grab the particular Customer */}
                <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customers' onChange={(e) => {
                  setCustomer(e.target.value)
                }} />
                <datalist id="Customers">
                  <option></option>
                  {[...new Set(data.map(x => x.Customer))].map((customer, index) => (
                    <option key={index}>{customer}</option>
                  ))}
                </datalist>
              </div>
              <div class="row" >
                <select
                  type="select"
                  class="form-control form-rounded"
                  id="order"
                  name="order"
                  onChange={(e) => {
                    setOrder(e.target.value);
                    order = e.target.value;  // trigger filter logic based on the value
                  }}
                >
                  <option value="1">Pending Processes</option>
                  <option value="0">Finished Processes</option>
                </select>

              </div>

            </div>
            <div class="col">
              <div class="row">
                {/* Grab the particular Customer */}
                <select
                  type="select"
                  class="form-control form-rounded"
                  id="order"
                  name="order"
                  onChange={(e) => {
                    setOrderHis(e.target.value);
                    orderHis = e.target.value;  // trigger filter logic based on the value
                  }}
                >
                  <option value="1">Open Orders</option>
                  <option value="0">Open/Closed Orders</option>
                </select>
              </div>


            </div>
            <div class="col">
              <button className="btn btn-success" onClick={ToggleEdit} hidden>Edit Schedule</button>
            </div>
            <div class="col">
              <button className="btn btn-primary" onClick={handlePrint} >Print Table</button>
            </div>
            <div class="col">
              <button class="form-control form-rounded" onClick={ToggleAllColumns} >Show All Columns</button>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div id="Upholstery_Schedule_table">
          <table class="table table-bordered" id='RRM'>
            <thead>
              <tr>
                <th>Po No</th>
                <th>Part No</th>
                <th>Internal Part No</th>
                <th>Part Description</th>
                <th>Customer</th>
                <th>PO SHIP DATE</th>
                <th>Remained Qty</th>
                <th>Order QTY</th>
                {All_columns ? <th>FABRIC STATUS</th> : null}
                {All_columns ? <th>FABRIC STYLE / FABRIC BODY</th> : null}
                {All_columns ? <th>FABRIC COLOR / FABRIC TOP</th> : null}
                {All_columns ? <th>ORIENTATION</th> : null}
                {All_columns ? <th>BASE COLOR</th> : null}
                {All_columns ? <th>FABRIC INSIDE BACK</th> : null}
                {All_columns ? <th>FABRIC OUTSIDE BACK</th> : null}
                {All_columns ? <th>FABRIC SEAT</th> : null}
                {All_columns ? <th>PIPING</th> : null}
                {All_columns ? <th>FABRIC BACK</th> : null}
                {All_columns ? <th>WOOD OUTSIDE BACK</th> : null}
                {All_columns ? <th>PIPING</th> : null}
                {All_columns ? <th>SALES ORDER NO</th> : null}
                {All_columns ? <th>Size</th> : null}
                {All_columns ? <th>Unit Price</th> : null}
                {All_columns ? <th>BASE STYLE(TUOHY)</th> : null}
                {All_columns ? <th>Type</th> : null}

                <th>Woodshop</th>
                <th>Molding</th>
                <th>Gluing</th>
                <th>Fabric Cutting</th>
                <th>Single Stitching</th>
                <th>Top Stitching</th>
                <th>Upholstery</th>
                <th>Assembly</th>
                <th>Finishing</th>
                <th>Packing</th>

                {All_columns ? <th>Size of Bag</th> : null}
                {All_columns ? <th>Box</th> : null}
                {All_columns ? <th>Bo</th> : null}
                {All_columns ? <th>Woodshop 2</th> : null}
                {All_columns ? <th>BOM</th> : null}
              </tr>

            </thead>
            {data.map((val, index) => {
              const allStepsCompleted = (
                (val.Woodshop === "No" || Number(val.Woodshop_status) === Number(val.QTY)) &&
                (val.Molding === "No" || Number(val.Molding_status) === Number(val.QTY)) &&
                (val.Gluing === "No" || Number(val.Gluing_status) === Number(val.QTY)) &&
                (val.Fabric_Cutting === "No" || Number(val.Fabric_Cutting_status) === Number(val.QTY)) &&
                (val.Single_Stitching === "No" || Number(val.Single_Stitching_status) === Number(val.QTY)) &&
                (val.Top_Stitching === "No" || Number(val.Top_Stitching_status) === Number(val.QTY)) &&
                (val.Upholstery === "No" || Number(val.Upholstery_status) === Number(val.QTY)) &&
                (val.Assembly === "No" || Number(val.Assembly_status) === Number(val.QTY)) &&
                (val.Finishing === "No" || Number(val.Finishing_status) === Number(val.QTY)) &&
                (val.Packing === "No" || Number(val.Packing_status) === Number(val.QTY))
              );
              return (
                <tbody key={index} className={allStepsCompleted ? "green-background" : ""}>
                  <tr>
                    <td>{val.PO_NO}</td>
                    <td>{val.Part_No}</td>
                    <td>{val.Part_internalNo}</td>
                    <td>{val.Part_Description}</td>
                    <td>{val.Customer}</td>
                    <td>{val.PO_SHIP_DATE ? (val.PO_SHIP_DATE).slice(0, 10) : null}</td>
                    <td>{val.Remained_parts}</td>
                    <td>{val.QTY}</td>
                    {All_columns ? <td>{val.FABRIC_STATUS}</td> : null}
                    {All_columns ? <td>{val.FABRIC_STYLE}</td> : null}
                    {All_columns ? <td>{val.FABRIC_COLOR}</td> : null}
                    {All_columns ? <td>{val.ORIENTATION}</td> : null}
                    {All_columns ? <td>{val.BASE_COLOR}</td> : null}
                    {All_columns ? <td>{val.FABRIC_INSIDE_BACK}</td> : null}
                    {All_columns ? <td>{val.FABRIC_OUTSIDE_BACK}</td> : null}
                    {All_columns ? <td>{val.FABRIC_SEAT}</td> : null}
                    {All_columns ? <td>{val.PIPING}</td> : null}
                    {All_columns ? <td>{val.FABRIC_BACK}</td> : null}
                    {All_columns ? <td>{val.WOOD_OUTSIDE_BACK}</td> : null}
                    {All_columns ? <td>{val.PIPING}</td> : null}
                    {All_columns ? <td>{val.SALES_ORDER_NO}</td> : null}
                    {All_columns ? <td>{val.Size}</td> : null}
                    {All_columns ? <td>{val.Unit_Price}</td> : null}
                    {All_columns ? <td>{val.BASE_STYLE_TUOHY}</td> : null}
                    {All_columns ? <td>{val.Type}</td> : null}

                    <td><input
                      type="number"
                      value={val.Woodshop_status}
                      onChange={(e) => handleUpdate(e, index, 'Woodshop_status')}
                      className={`${val.Woodshop === "No" ? "lightgrey-background" :
                        Number(val.Woodshop_status) === 0 ? "red-background" :
                          Number(val.QTY) > Number(val.Woodshop_status) ? "orange-background" :
                            Number(val.QTY) === Number(val.Woodshop_status) ? "green-background" : "white-background"}`}
                      disabled={val.Woodshop === "No"}
                      onBlur={Update}
                    />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={val.Molding_status}
                        onChange={(e) => handleUpdate(e, index, 'Molding_status')}
                        className={`${val.Molding === "No" ? "lightgrey-background" :
                          Number(val.Molding_status) === 0 ? "red-background" :
                            Number(val.QTY) > Number(val.Molding_status) ? "orange-background" :
                              Number(val.QTY) === Number(val.Molding_status) ? "green-background" : "white-background"}`}
                        disabled={val.Molding === "No"}
                        onBlur={Update}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={val.Gluing_status}
                        onChange={(e) => handleUpdate(e, index, 'Gluing_status')}
                        className={`${val.Gluing === "No" ? "lightgrey-background" :
                          Number(val.Gluing_status) === 0 ? "red-background" :
                            Number(val.QTY) > Number(val.Gluing_status) ? "orange-background" :
                              Number(val.QTY) === Number(val.Gluing_status) ? "green-background" : "white-background"}`}
                        disabled={val.Gluing === "No"}
                        onBlur={Update}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={val.Fabric_Cutting_status}
                        onChange={(e) => handleUpdate(e, index, 'Fabric_Cutting_status')}
                        className={`${val.Fabric_Cutting === "No" ? "lightgrey-background" :
                          Number(val.Fabric_Cutting_status) === 0 ? "red-background" :
                            Number(val.QTY) > Number(val.Fabric_Cutting_status) ? "orange-background" :
                              Number(val.QTY) === Number(val.Fabric_Cutting_status) ? "green-background" : "white-background"}`}
                        disabled={val.Fabric_Cutting === "No"}
                        onBlur={Update}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={val.Single_Stitching_status}
                        onChange={(e) => handleUpdate(e, index, 'Single_Stitching_status')}
                        className={`${val.Single_Stitching === "No" ? "lightgrey-background" :
                          Number(val.Single_Stitching_status) === 0 ? "red-background" :
                            Number(val.QTY) > Number(val.Single_Stitching_status) ? "orange-background" :
                              Number(val.QTY) === Number(val.Single_Stitching_status) ? "green-background" : "white-background"}`}
                        disabled={val.Single_Stitching === "No"}
                        onBlur={Update}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={val.Top_Stitching_status}
                        onChange={(e) => handleUpdate(e, index, 'Top_Stitching_status')}
                        className={`${val.Top_Stitching === "No" ? "lightgrey-background" :
                          Number(val.Top_Stitching_status) === 0 ? "red-background" :
                            Number(val.QTY) > Number(val.Top_Stitching_status) ? "orange-background" :
                              Number(val.QTY) === Number(val.Top_Stitching_status) ? "green-background" : "white-background"}`}
                        disabled={val.Top_Stitching === "No"}
                        onBlur={Update}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={val.Upholstery_status}
                        onChange={(e) => handleUpdate(e, index, 'Upholstery_status')}
                        className={`${val.Upholstery === "No" ? "lightgrey-background" :
                          Number(val.Upholstery_status) === 0 ? "red-background" :
                            Number(val.QTY) > Number(val.Upholstery_status) ? "orange-background" :
                              Number(val.QTY) === Number(val.Upholstery_status) ? "green-background" : "white-background"}`}
                        disabled={val.Upholstery === "No"}
                        onBlur={Update}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={val.Assembly_status}
                        onChange={(e) => handleUpdate(e, index, 'Assembly_status')}
                        className={`${val.Assembly === "No" ? "lightgrey-background" :
                          Number(val.Assembly_status) === 0 ? "red-background" :
                            Number(val.QTY) > Number(val.Assembly_status) ? "orange-background" :
                              Number(val.QTY) === Number(val.Assembly_status) ? "green-background" : "white-background"}`}
                        disabled={val.Assembly === "No"}
                        onBlur={Update}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={val.Finishing_status}
                        onChange={(e) => handleUpdate(e, index, 'Finishing_status')}
                        className={`${val.Finishing === "No" ? "lightgrey-background" :
                          Number(val.Finishing_status) === 0 ? "red-background" :
                            Number(val.QTY) > Number(val.Finishing_status) ? "orange-background" :
                              Number(val.QTY) === Number(val.Finishing_status) ? "green-background" : "white-background"}`}
                        disabled={val.Finishing === "No"}
                        onBlur={Update}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={val.Packing_status}
                        onChange={(e) => handleUpdate(e, index, 'Packing_status')}
                        className={`${val.Packing === "No" ? "lightgrey-background" :
                          Number(val.Packing_status) === 0 ? "red-background" :
                            Number(val.QTY) > Number(val.Packing_status) ? "orange-background" :
                              Number(val.QTY) === Number(val.Packing_status) ? "green-background" : "white-background"}`}
                        disabled={val.Packing === "No"}
                        onBlur={Update}
                      />
                    </td>
                    {All_columns ? <td>{val.Size_of_Bag}</td> : null}
                    {All_columns ? <td>{val.Box}</td> : null}
                    {All_columns ? <td>{val.Bo}</td> : null}
                    {All_columns ? <td>{val.Woodshop2}</td> : null}
                    {All_columns ? <td>{val.BOM}</td> : null}
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
        {isLoading ?
          <div className='overlay'>
            <div className="loading-container">
              <div className="loading-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <p className='loading'>Loading</p>
            </div>
          </div> : null}
      </div >
    </div >
  )
}

export default Upholstery_Schedule;
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import "./ShippingData.css";
import { Host } from './App';
import { loadCustomers } from './Dashboard';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

//import { Customers } from './Dashboard';

function UpdatePOData() {

  var int = 4;
  var [postList, setPostList] = useState([]);
  var [PoNo, setPoNo] = useState({});
  var [PartNo, setPartNo] = useState({});
  var [Date1, setDate1] = useState(new Date());
  var [Postartdate, setPoStartdate] = useState('2023-01-01');
  var [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  var [customer, setCustomer] = useState({});
  var [NewPoQty, setNewPoQty] = useState("");
  var [NewPoShipdate, setNewPoShipdate] = useState("");
  const [update, setUpdate] = useState(false);
  const [del, setdel] = useState(0);
  var [ActualShipdate, setActualShipdate] = useState(Date1.toISOString().slice(0, 10));
  var [ShippedQty, setShippedQty] = useState("");
  var [NewPo_No, setnewPo_No] = useState("");

  var [NewComment, setNewComment] = useState("");
  const [openorder, setopenorder] = useState("1");
  var [Customers, setCustomers] = useState([]);
  var [partNo, setpartNo] = useState([]);
  var [order, setOrder] = useState("1");
  var [test, setTest] = useState(int);
  var token = (window.localStorage.getItem("acessToken"));

  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  // Default Data 
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/Shipping/searchupdatepo/${JSON.stringify(PoNo)},${JSON.stringify(PartNo)},${Postartdate},${Poenddate},${JSON.stringify(customer)},${order},${token}`).then((data) => {
      setPostList(data.data)
    })
  }, [PoNo, PartNo, Postartdate, Poenddate, customer, order, NewPoQty, del]);

  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/Customers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/partNo/${token}`).then((data) => {
      setpartNo(data.data)
    });
  }, [])

  const [changedLine, setChangedLine] = useState(new Set());
  const handleInputChange = (e, index, key) => {
    const { value } = e.target;
    updateDatas(index, key, value);
  };

  const updateDatas = (index, key, value) => {
    setPostList(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    setChangedLine(prevRows => new Set(prevRows).add(index));
  };

  const Update = (PO_NO, PART_NO, Po_Ship_Date, QTY, comments) => {
    ////console.log(COMMeNTS);
    setUpdate(true);
    const changedData_ = [...changedLine].map(index => {
      return postList[index];

    });

    console.log(changedData_);
    Axios.post(`https://` + Host + `/api/po/Update`, { changedData: JSON.stringify(changedData_), token: token }).then((response) => {
      if (response.status === 200) {
        NewComment = "";
        NewPoQty = "";
        NewPoShipdate = "";
        window.alert(response.data.message);
        setdel(del + 1);
        //search();
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });
    {/*
    if (NewPoQty && !NewPoShipdate && !NewComment) {
      NewPoShipdate = Po_Ship_Date;
      NewComment = comments
    }
    if (!NewPoQty && NewPoShipdate && !NewComment) {
      NewPoQty = QTY;
      NewComment = comments
    }
    if (!NewPoQty && !NewPoShipdate && NewComment) {
      NewPoShipdate = Po_Ship_Date;
      NewPoQty = QTY;
    }
    if (NewPoQty && NewPoShipdate && !NewComment) {
      NewComment = comments;

    } if (NewPoQty && !NewPoShipdate && NewComment) {
      NewPoShipdate = Po_Ship_Date;

    } if (!NewPoQty && NewPoShipdate && NewComment) {
      NewPoQty = QTY;
    }
    if (NewPoQty && NewPoShipdate && NewComment) {

    }


*/}
  };
  const Delete = (PO_NO, PART_NO) => {
    //console.log("COMMeNTS");
    Axios.delete(`https://` + Host + `/api/Shippeddata/Delete/${PO_NO},${PART_NO},${token}`).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
        });
        setdel(del + 1);
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });

  };

  const Order = () => {
    //console.log(order);
    Axios.get(`https://` + Host + `/api/get/default/${order}`).then((data) => {
      setPostList(data.data)
    });
  }

  return (
    <div className="MainPage_data">
      <div class="Container">
        <ToastContainer />
        <div class="row">
          <div class="col">
            {/* Grab the particular Operator name */}
            <input type='text' class="form-control form-rounded" placeholder='PO NO' list="operators" onChange={(e) => {
              setPoNo(e.target.value)
            }} />

          </div>
          <div class="col">
            {/* Grab the particular Operator name */}
            <input type='text' class="form-control form-rounded" placeholder='PART NO' list="PartNo" onChange={(e) => {
              setPartNo(e.target.value)
            }} />
            <datalist id="PartNo">
              <option></option>
              {partNo.map(x => {
                return <option>{x.Part_No}</option>
              })}
            </datalist>
          </div>
          <div class="col">
            <div class="row">
              <div class="col" id='moldng'>
                {/* Grab the particular dates */}
                <input type='date' class="form-control form-rounded" placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                  setPoStartdate(e.target.value)
                }} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                <span class="tooltiptext">Filter By PO SHIP DATE </span>

              </div>
              <div class="col">
                <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setPoEnddate(e.target.value)
                }} />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              {/* Grab the particular Customer */}
              <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customers' onChange={(e) => {
                setCustomer(e.target.value)
              }} />
              <datalist id="Customers">
                <option></option>
                {Customers.map(x => {
                  return <option>{x.Customer}</option>
                })}
              </datalist>
            </div>
            <div class="row">
              <select
                type="select"
                class="form-control form-rounded"
                id="order"
                name="order"
                onChange={(e) => {
                  setOrder(e.target.value);
                  order = e.target.value;
                }}
              >
                <option value="1">Open Orders</option>
                <option value="0"> Open/Closed Orders</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <Link to="/shippedparts">
                <input
                  type="button"
                  class="btn btn-success"
                  defaultValue="Review Shipped Data"
                  name="Section"
                  id="btn_type"
                />
              </Link>
            </div>

            <div class="row">
              <Link to="/addpartdataset">
                <input
                  type="button"
                  class="btn btn-success"
                  defaultValue="Add new Part Dataset"
                  name="Section"
                  id="btn_type"
                />
              </Link>
            </div>

          </div>

        </div>
        <br></br>
        <br></br>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>PO NO</th>
              <th>PART NO</th>
              <th>PO SHIP DATE</th>
              <th>PART DESCRIPTION</th>
              <th>CUSTOMER</th>
              <th>PO QTY</th>
              <th>REMAINED QTY</th>
              <th>ORDER DATE</th>
              <th>UNIT PRICE</th>
              <th id="comment">COMMENTS </th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          {postList.map((val, index) => {
            return (
              <tbody>
                <tr key={index} >
                  <td>{val.PO_NO}</td>
                  <td>{val.PART_NO}</td>
                  <td><input type="date" class="form-control form-rounded" Value={(val.PO_SHIP_DATE).slice(0, 10)}
                    onChange={(e) => handleInputChange(e, index, 'PO_SHIP_DATE')}
                  /></td>
                  <td>{val.PART_DESCRIPTION}</td>
                  <td>{val.CUSTOMER}</td>
                  <td id={val.SR_NO}><input type="text" id={val.SR_NO} name='qty' class="form-control form-rounded" Value={val.QTY} onChange={(e) => handleInputChange(e, index, 'QTY')}
                  />
                  </td>
                  <td>{val.Remained_parts}</td>
                  <td>{(val.PO_DATE).slice(0, 10)}</td>
                  <td id={val.SR_NO}><input type="text" id={val.SR_NO} name='Unit_Price' class="form-control form-rounded" Value={val.UNIT_PRICE} onChange={(e) => handleInputChange(e, index, 'UNIT_PRICE')}
                  />
                  </td>
                  <td >
                    <input type="textbox" class="form-control form-rounded" Value={val.COMMENTS} onChange={(e) => handleInputChange(e, index, 'COMMENTS')} /></td>
                  <td><button class="btn btn-success" onClick={() => { Update((val.PO_NO), (val.PART_NO), (val.PO_SHIP_DATE).slice(0, 10), (val.QTY), (val.COMMENTS)) }}>Update</button></td>

                  <td><button class="btn btn-danger" onClick={() => { window.confirm('Are you sure you want to delete this Entry?',) && Delete((val.PO_NO), (val.PART_NO)) }}> Delete</button></td>
                </tr>
              </tbody>
            )
          })}
        </table>
      </div>
    </div >
  )
}

export default UpdatePOData
import React, { useState, useEffect } from 'react'
import './Dashboard.css'
import { Link, Redirect, useParams } from "react-router-dom";
import Axios from 'axios'
import { Data } from "./Pivot/Pivot";
import { Host } from './App';
import App from './App';
export var partNo = '';
export var poNo = '';
export var [part_dataset] = '';
var User = "";

const Dashboard = (props) => {
  var [Customers, setCustomers] = useState([]);
  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    window.localStorage.removeItem("Rank");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('Rank');

    const USER = "";
    Axios.get(`https://` + Host + `/logout`).then((data) => {
      console.log(data);
      props.history.push('/');
    })
  }
  const token = window.localStorage.getItem("acessToken");
  const testt = () => {
    Axios.get(`https://` + Host + `/api/get/test`).then((data) => {
      setCustomers(data.data)
    });
    console.log(Customers);
  }
  User = window.localStorage.getItem("Name");

  return (
    <div>
      <div className="content">
        <div class="Userdata">
          <br></br>
          <br></br>
          <h4>Welcome <br></br>{User.replace(/[{""}]/g, "")} !</h4>
          <br></br>
          <Link to="/">
            <input type="button" class="btn btn-success" onClick={handleLogout} value="Logout" />
          </Link>
        </div>
        <center>
          <div class="Container">
            {/*<input type="button" class="btn btn-success" onClick={testt} value="test" />*/}

            <div class="row">
              <center id="center">
                <div class="column" id="clm">
                  <Link to="/adddata">
                    <input
                      type="button"
                      class="button"
                      defaultValue="Add PO/Shipping Data"
                      id="btn"
                      name="Section"
                    />
                  </Link>
                </div>
                <div class="column" id="clm">
                  <br></br>
                  <Link to="/reaminedparts">
                    <input
                      type="button"
                      class="button"
                      defaultValue="Open orders"
                      name="Section"
                      id="btn"
                    />
                  </Link>
                </div>
                <div class="column" id="clm">
                  <br />

                  <Link to="/pivot">
                    <input
                      type="button"
                      class="button"
                      defaultValue="Pivot Table"
                      id="btn"
                      name="Section"
                    />
                  </Link>
                  <br />
                  <Link to="/Morning">
                    <input
                      type="button"
                      class="button"
                      defaultValue="Daily Data Record"
                      id="btn"
                      name="Section"
                    />
                  </Link>
                </div>
              </center>
            </div>
          </div>
        </center>
      </div>
    </div>
  )
}

export default Dashboard;
import React, { useState } from 'react';
import './HamburgerMenu.css';
import { Link } from "react-router-dom";
import Axios from 'axios'
import { Host } from '../App';

function HamburgerMenu(props) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const hide = () => {
    setIsSidebarOpen(false)
  }

  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("Rank");
    window.localStorage.removeItem("Stage");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    Axios.get(`https://` + Host + `/logout`).then((data) => {
      console.log(data);
      props.history.push('/');
    })
    setIsSidebarOpen(false)
  }
  return (
    <div className="ThreelinesApp">
      <div className="hamburger" onClick={toggleSidebar}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      <nav className={`sidebar_hamburger ${isSidebarOpen ? 'open' : ''}`}>
        <ul className="menu">
          <li className="menu-item">
            <Link to="/NewDashboard" onClick={hide}>Dashboard</Link>
          </li>
          <li className="menu-item">
            <Link to="/openorder" onClick={hide}>Open Orders</Link>
          </li>
          <li className="menu-item">
            <Link to="/Pivot" onClick={hide}>Pivot</Link>
          </li>
          <li className="menu-item">
            <Link to="/suppluchain" onClick={hide}>Supply Chain</Link>
          </li>
          <li className="menu-item">
            <Link to="/attendance" onClick={hide}>Attendance Portal</Link>
          </li>
          <li className="menu-item">
            <Link to="/Scheduling" onClick={hide}>Scheduling</Link>
          </li>
          <li className="menu-item">
            <Link to="/BOM" onClick={hide}>BOM</Link>
          </li>
          <li className="menu-item">
            <a href="/" onClick={handleLogout}>
              Logout
            </a>
          </li>
        </ul>
      </nav>

      {/* Add the rest of your app content here */}
    </div>
  );
}


export default HamburgerMenu;

import React, { useState, useEffect } from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import 'draft-js/dist/Draft.css';
import axios from 'axios';
import { Host } from '../../App'; // Adjust according to your project structure
import { format, parseISO } from 'date-fns';
import real_l from "../../real_logo.png";
import './Report.css'

const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

const ShiftReport_All = () => {
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [filterDate, setFilterDate] = useState(new Date().toISOString().slice(0, 10));
  const [filterShift, setFilterShift] = useState('');
  const [filterSupervisor, setFilterSupervisor] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  var stage = (window.localStorage.getItem("Stage").replace(/[{""}]/g, ""));
  var token = (window.localStorage.getItem("acessToken"));
  const [rank, setRank] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  var [Enddate, setEnddate] = useState(new Date().toISOString().slice(0, 10));
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();
  var User = JSON.parse(window.localStorage.getItem("Name"));

  useEffect(() => {
    if (stage <= 4) {
      setRank(true);
    }
  }, [stage]);

  const queryParams = {
    token: token,
    date: date,
    EndDate: Enddate
  };

  useEffect(() => {
    axios
      .get(`https://` + Host + `/get-reports`, { params: queryParams })
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          const fetchedReports = response.data
            .map((report) => {
              try {
                let parsedContent;
                let contentState;

                // Parse the JSON content safely
                try {
                  parsedContent = JSON.parse(report.content);
                } catch (error) {
                  console.error('Error parsing JSON content:', error);
                  parsedContent = null; // Handle parsing error gracefully
                }

                // Check if parsed content is an array with headings or a single content state
                if (Array.isArray(parsedContent)) {
                  // If content is an array, process each section with headings
                  contentState = parsedContent.map((section) => ({
                    heading: section.heading,
                    content: EditorState.createWithContent(
                      convertFromRaw(section.content)
                    ),
                  }));
                } else if (parsedContent) {
                  // If content is not an array, treat it as a single EditorState
                  const rawContentState = convertFromRaw(parsedContent);
                  contentState = [{ heading: 'Default Heading', content: EditorState.createWithContent(rawContentState) }];
                }

                return {
                  ...report,
                  contentState,
                };
              } catch (error) {
                console.error('Error converting report content:', error);
                return null; // Skip reports with invalid content state
              }
            })
            .filter(Boolean); // Remove null reports

          setReports(fetchedReports);
          setFilteredReports(fetchedReports); // Initially show all reports
        } else {
          window.alert('No reports found!');

        }
      })
      .catch((error) => {
        console.error('Error fetching reports:', error);
      });
  }, [date, Enddate]);
  const handlePrint = (reportId) => {
    const selectedReport = document.querySelector(`#report-${reportId}`); // Select the clicked report by its id
    const printWindow = window.open("", "", "height=600,width=800");

    // Create logo element
    const img = document.createElement('img');
    img.src = real_l; // Assume this is the logo URL

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>

            h1 {
              display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
            background:lightgrey;
          }
            body {
              border: 1px solid black;
            padding: 1%;
          }
            #heading-section {
              height:fit-content;
            margin-bottom:0;
            padding-bottom:0;
            display:flex;
            margin-top: 2px;
            margin-left: 23px;
            border: ridge;
            padding: 1%;
           
          }

            #section-text{
              height:fit-content;
            margin-bottom:0;
            padding-bottom:0;
            display:flex;
            margin-top: 22px;
            margin-left: 23px;
             font-size: 14px !important;
          }

            button {
              visibility: hidden;
          }

            img {
              display: inline - block;
            height: 50px; /* Set the height of the image as required */
            margin - right: 10px; /* Set the spacing between the image and title as required */
          }

            #section-heading{
              width: 9em;
                 font-size: 18px !important;
          }

            #attachment{
              visibility:hidden}
          </style>
        </head>
        <body>

          <h1>${img.outerHTML}</h1>
          <div>${selectedReport.innerHTML}</div>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + ' ' + formattedTime}</p>

        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print(); // Trigger print
  };


  const handleFilter = () => {
    let filtered = reports;

    if (filterShift) {
      filtered = filtered.filter((report) => report.shift === filterShift);
    }

    if (filterSupervisor) {
      filtered = filtered.filter((report) => report.supervisor === filterSupervisor);
    }

    setFilteredReports(filtered);
  };



  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modal: {
      position: 'relative',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '90%',
      maxHeight: '90%',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      zIndex: 1001,
    },
    downloadButton: {
      position: 'absolute',
      top: '10px',
      right: '45px',
      fontSize: '24px',
      cursor: 'pointer',
      zIndex: 1002,
    },
    downloadLink: {
      color: 'black',
      textDecoration: 'none',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '15px',
      fontSize: '24px',
      cursor: 'pointer',
      zIndex: 1002,
    },
  };

  const uniqueSupervisors = [...new Set(reports.map((report) => report.supervisor))];

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <div id="shift_report_block">
        <div style={{ marginBottom: '20px', marginInline: 'auto' }}>
          <div >
            <label>
              Filter by Date:
              <div class="row">
                <input
                  type="date"
                  className="form-control form-rounded"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  style={{ marginLeft: '10px', padding: '5px' }}
                />
              </div>
              <div class="row">
                <input
                  type="date"
                  className="form-control form-rounded"
                  value={Enddate}
                  onChange={(e) => setEnddate(e.target.value)}
                  style={{ marginLeft: '10px', padding: '5px' }}
                />
              </div>
            </label>
          </div>
        </div>
        <div style={{ marginBottom: '20px', marginInline: 'auto' }}>
          <label>
            Filter by Shift:
            <select
              value={filterShift}
              className="form-control form-rounded"
              onChange={(e) => setFilterShift(e.target.value)}
              onBlur={handleFilter}
              style={{ marginLeft: '10px', padding: '5px' }}
            >
              <option value="">All</option>
              <option value="Morning">Morning</option>
              <option value="Evening">Evening</option>
            </select>
          </label>
        </div>
        <div style={{ marginBottom: '20px', marginInline: 'auto' }}>
          <label>
            Filter by Supervisor:
            <select
              className="form-control form-rounded"
              value={filterSupervisor}
              onChange={(e) => setFilterSupervisor(e.target.value)}
              onBlur={handleFilter}
              style={{ marginLeft: '10px', padding: '5px' }}
            >
              <option value="">All</option>
              {uniqueSupervisors.map((supervisor) => (
                <option key={supervisor} value={supervisor}>
                  {supervisor}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
      {filteredReports ?
        <div id="all-report-section">
          {filteredReports.map((report) => (
            <div
              key={report.id}
              style={{
                marginBottom: '20px',
                border: '2px solid black',
                padding: '10px',
                borderRadius: '10px',
              }}
              id={`report-${report.id}`}
            >
              <div
                id="Reports_id"
                style={{ display: 'inline-flex', paddingRight: '10px' }}
              >
                <p style={{ marginRight: '15px' }}>
                  <b>Report Created: </b> {format(new Date(report.created_at), 'PPpp')}
                </p>
                <p style={{ marginRight: '15px' }}>
                  <b>Shift:</b> {report.shift}
                </p>
                <p style={{ marginRight: '15px' }}>
                  <b>Supervisor:</b> {report.supervisor}
                </p>

                <button class="btn btn-primary" onClick={() => handlePrint(report.id)}>Print</button>
              </div>

              {/* Check if contentState is an array (headings present) or a single EditorState */}
              {Array.isArray(report.contentState) ? (
                report.contentState.map((section, index) => (
                  <div key={index} id="heading-section">
                    <p style={{ fontSize: '24px' }} id='section-heading'>{section.heading}</p> {/*, textAlign: 'justify'*/}
                    <div style={{
                      padding: '10px',
                      background: 'ghostwhite',
                      borderRadius: '4px',
                    }} id="section-text">
                      <Editor
                        editorState={section.content}
                        readOnly
                        plugins={[inlineToolbarPlugin]}
                      />
                    </div>
                  </div>
                ))
              ) : null}

              <div id="attachment">
                {report.attachments &&
                  JSON.parse(report.attachments).map((attachment, index) => (
                    <div key={index}>
                      {attachment.includes('.mp4') ? (
                        <video
                          controls
                          src={`https://${attachment}`}
                          style={{ maxWidth: '25%', marginBottom: '10px', cursor: 'pointer' }}
                          onClick={() => openModal(`https://${attachment}`)}
                        >
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={`${attachment}`}
                          alt={`Attachment ${index + 1}`}
                          style={{ maxWidth: '25%', marginBottom: '10px', cursor: 'pointer' }}
                          onClick={() => openModal(`${attachment}`)}
                        />
                      )}
                    </div>
                  ))}
              </div>

              {selectedImage && (
                <div style={modalStyles.overlay} onClick={closeModal}>
                  <div style={modalStyles.modal} onClick={(e) => e.stopPropagation()}>
                    <span style={modalStyles.downloadButton}>
                      <a href={selectedImage} download target="_blank" rel="noopener noreferrer" style={modalStyles.downloadLink}>
                        &#128229;
                      </a>
                    </span>
                    <span style={modalStyles.closeButton} onClick={closeModal}>
                      &times;
                    </span>
                    <img
                      src={selectedImage}
                      alt="Attachment Full Size"
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        : null}
    </div>
  );
};

export default ShiftReport_All;

import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import "../ShippingData.css";
import "./Add_Part_Dataset.css";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Host } from '../App';
import { loadCustomers } from '../Dashboard';

import App from '../App';
import Add_Internal_Part_Dataset from './Add_Internal_Part_Dataset';
import Upholstery_Part_Dataset from './Upholstery_Part_Dataset';

function Add_Part_Dataset() {

  //search for data
  const [PartNo, setPartno] = useState({});
  const [PartDesc, setPartDesc] = useState({});
  const [Customers, setCustomers] = useState({});
  const [Category, setCategory] = useState({});
  const [editable, seteditable] = useState(false);
  const [del, setdel] = useState(0);

  const [partDataset, setpartDataset] = useState([]);
  const [operadata, setOpeartordata] = useState([]);
  const [Part_No, setPartNo] = useState("");
  const [Part_Description, setPart_description] = useState("");
  const [Customer, setCustomer] = useState("");
  const [CATEGORY, setcategory] = useState("");
  const [Unit_Price, setunitprice] = useState("");
  const [CURRENCY, setcurrency] = useState("");
  const [SIZE, setsize] = useState("");
  const [BASE_STYLE_TUHOY, setbasestyletoughy] = useState("");
  const [MOLDING, setMoulding] = useState("");
  const [WOODSHOP, setWoodshop] = useState("");
  const [UPHOLSTERY, setUpholstery] = useState("");
  const [BUY_AND_SELL, setbuyandsell] = useState("");
  const [newUnit_Price, setNewUnit_price] = useState("");
  //set to add new employee
  const [EmployeeName, setEmployeeName] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [Department, setDepartment] = useState("");
  const [Source, setSource] = useState("");
  const [Position, setPosition] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [Status, setStatus] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [PD, setPD] = useState(true);
  const [ED, setED] = useState(false);
  const [IPD, setIPD] = useState(false);
  const [UPD, setUPD] = useState(false);
  var userName = (window.localStorage.getItem("Name").replace(/[{""}]/g, ""));
  const [section, setsection] = useState("");
  const [call, setCall] = useState(0);
  const [rank, setrank] = useState(false);

  var Ranked = (window.localStorage.getItem("Rank"));
  var Stage = (window.localStorage.getItem("Stage"));

  var token = (window.localStorage.getItem("acessToken"));

  const [data, setData] = useState([
    { Emp_ID: "", EmployeeName: "", ContactNumber: "", Department: "", Source: "", Position: "", StartDate: "", EndDate: "", Status: "" },
  ]);
  const [filter, setFilter] = useState(
    [{ Emp_ID: "", EmployeeName: "", ContactNumber: "", Department: "", Source: "", Position: "", Status: "", token: token },
    ]);
  const updateFilter = (e, key) => {
    const { value } = e.target;
    setFilter(prevData => {
      const newData = [...prevData];
      newData[0][key] = value;
      return newData;
    });
  };


  const partfilters = {
    PartNo: PartNo,
    PartDesc: PartDesc,
    Customers: Customers,
    Category: Category,
    token: token
  }
  useEffect(() => {
    if (Ranked === '"Admin"' && Stage === '"2"') {
      setrank(true);
    }
  }, [Ranked]);
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/partdataset`, { params: partfilters }).then((data) => {
      setpartDataset(data.data)
    });
  }, [PartNo, PartDesc, Customers, Category, del])
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/operatordetails/${token}`).then((data) => {
      setOpeartordata(data.data)
    });
  }, [operadata])

  useEffect(() => {
    const filterParams = filter[0];
    Axios.get(`https://` + Host + `/api/get/Employeedetails`, { params: filterParams }).then((data) => {
      const transformedData = (data.data).map((item, index) => ({
        Emp_ID: item.Emp_ID || "",
        EmployeeName: item.Name || "",
        ContactNumber: item.ContactNo || "",
        Department: item.Department || "",
        Source: item.Source || "",
        Position: item.Position || "",
        StartDate: item.StartDate || "",
        EndDate: item.EndDate || "",
        Status: item.Status || "",
        Emp_ID: item.Emp_ID || "", // Add any additional properties here
      }));

      setData(transformedData);
      setOriginalData(transformedData);

    });
  }, [call, filter])


  const [changedRows, setChangedRows] = useState(new Set());
  const handleUpdate = (e, index, key) => {
    const { value } = e.target;
    updateData(index, key, value);
  };

  const updateData = (index, key, value) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    setChangedRows(prevRows => new Set(prevRows).add(index));
  };

  //

  const [changedLine, setChangedLine] = useState(new Set());
  const handleInputChange = (e, index, key) => {
    const { value } = e.target;
    updateDatas(index, key, value);
  };

  const updateDatas = (index, key, value) => {
    setpartDataset(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    setChangedLine(prevRows => new Set(prevRows).add(index));
  };

  const print = () => {
    console.log(filter);
  }

  const Update = () => {
    const changedData_ = [...changedLine].map(index => {
      return partDataset[index];

    });
    Axios.post(`https://` + Host + `/api/partDataset/Update`, { changedData: JSON.stringify(changedData_), token: token }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        window.alert(response.data.message);
      }
      seteditable(false);
      setdel(del + 1);
    })
      .catch(function (error) {
        console.log(error.response);
      });
  };


  const submitoperatordata = () => {

    Axios.post(`https://` + Host + `/api/post/addnewemployee`, {
      EmployeeName: EmployeeName,
      ContactNumber: ContactNumber,
      Department: Department,
      Source: Source,
      Position: Position,
      StartDate: StartDate,
      EndDate: EndDate,
      Status: Status,
      token
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        window.alert(response.data.message);
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });

    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }
  const submitpartdatasetdata = () => {
    Axios.post(`https://` + Host + `/api/submitpartdatasetdata`, {
      Part_No: Part_No,
      Part_Description: Part_Description,
      Customer: Customer,
      CATEGORY: CATEGORY,
      Unit_Price: Unit_Price,
      CURRENCY: CURRENCY,
      SIZE: SIZE,
      BASE_STYLE_TUHOY: BASE_STYLE_TUHOY,
      MOLDING: MOLDING,
      WOODSHOP: WOODSHOP,
      UPHOLSTERY: UPHOLSTERY,
      BUY_AND_SELL: BUY_AND_SELL,
      token
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        window.alert(response.data.message);
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }

  const area = () => {
    if (section == "Part Dataset") {
      setPD(true);
      setED(false);
      setIPD(false);
      setUPD(false);
    }
    if (section == "Employee Dataset") {
      setPD(false);
      setED(true);
      setIPD(false);
      setUPD(false);

    }
    if (section == "Internal Part Dataset") {
      setPD(false);
      setED(false);
      setUPD(false);
      setIPD(true);

    } if (section == "Upholstery Part Dataset") {
      setPD(false);
      setED(false);
      setUPD(true);
      setIPD(false);
    }
  };

  const Update_employee = () => {
    const changedData = [...changedRows].map(index => {
      return data[index];
    });
    Axios.post(`https://` + Host + `/api/post/updateemployee`, {
      changedData: JSON.stringify(changedData),
      token
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        window.alert(response.data.message);
      }
      setCall(call + 1);

    })
      .catch(function (error) {
        console.log(error.response);
      });
  };
  return (
    <div className="MainPage_data">
      <div class="Container">

        <br></br>

        <div class="row">
          <div class="col-2">
            <select type="select" class="form-control form-rounded" id="Area_po_dashboard" placeholder="Area" name="area" onKeyDown={area} onTouchMove={area} onSelect={area} onChange={(e) => { setsection(e.target.value); }} onClick={area}>
              <option value="Welcome">---Select One---</option>
              <option value="Part Dataset">Part Dataset</option>
              <option value="Internal Part Dataset">Internal Part Dataset</option>
              <option value="Upholstery Part Dataset">Upholstery Part Dataset</option>
              <option value="Employee Dataset">Employee Dataset</option>
            </select>
          </div>
          <div class="col-10"></div>

        </div>


        {PD ? <div>

          <div class="add_section">
            <center>

              <h2 style={{ padding: 25 }}>Add New Part Dataset</h2>
              <div class="row" id="Part_dataset_add">
                <tr>
                  <td style={{ padding: 10 }}>
                    <label>Part No</label>
                    <input
                      type="text"
                      name="Part_No"
                      class="form-control form-rounded"
                      list='InternalPartNo'
                      onBlur={(e) => {
                        setPartNo(e.target.value);
                      }}
                    />
                    <div class="scrollbar">
                      <datalist id="InternalPartNo">
                        <option></option>
                        {partDataset.map((x) => {
                          return <option>{x.Part_No}</option>;
                        })}
                      </datalist>
                    </div>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Part Description</label>
                    <input
                      type="text"
                      name="Part_Description"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setPart_description(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Customer</label>
                    <input
                      type="text"
                      name="Customer"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setCustomer(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>CATEGORY</label>
                    <input
                      type="text"
                      name="CATEGORY"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setcategory(e.target.value);
                      }}
                      list="category"
                    />
                    <div class="scrollbar">
                      <datalist id="category">
                        <option></option>
                        {partDataset.map((x) => {
                          return <option>{x.CATEGORY}</option>;
                        })}
                      </datalist>
                    </div>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Unit Price</label>
                    <input
                      type="text"
                      name="Unit_price"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setunitprice(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>CURRENCY</label>
                    <input
                      type="text"
                      name="CURRENCY"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setcurrency(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: 10 }}>
                    <label>SIZE(CUSHIONS/PANELS/BUDS)</label>
                    <input
                      type="text"
                      name="SIZE(CUSHIONS/PANELS/BUDS)"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setsize(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>BASE STYLE TUHOY</label>
                    <input
                      type="text"
                      name="BASE_STYLE_TUHOY"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setbasestyletoughy(e.target.value);
                      }}
                      list="base"
                    />
                    <div class="scrollbar">
                      <datalist id="base">
                        <option></option>
                        {partDataset.map((x) => {
                          return <option>{x.BASE_STYLE_TUHOY}</option>;
                        })}
                      </datalist>
                    </div>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>MOLDING</label>
                    <input
                      type="text"
                      name="MOLDING"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setMoulding(e.target.value);
                      }}
                      list="molding"
                    />
                    <div class="scrollbar">
                      <datalist id="molding">
                        <option >YES</option>
                      </datalist>
                    </div>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>WOODSHOP</label>
                    <input
                      type="text"
                      name="WOODSHOP"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setWoodshop(e.target.value);
                      }}
                      list="molding"
                    />

                  </td>
                  <td style={{ padding: 10 }}>
                    <label>UPHOLSTERY</label>
                    <input
                      type="text"
                      name="UPHOLSTERY"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setUpholstery(e.target.value);
                      }}
                      list="molding"
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>BUY AND SELL</label>
                    <input
                      type="text"
                      name="BUY_AND_SELL"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setbuyandsell(e.target.value);
                      }}
                      list="molding"
                    />
                  </td>
                </tr>

                <div>
                  <input
                    type="button"
                    class="btn btn-success"
                    defaultValue="Submit"
                    style={{ width: "auto" }}
                    name="Submit"
                    onClick={submitpartdatasetdata}
                  />
                </div>
              </div>
              <br></br>
              <br></br>
            </center>
          </div>
          <div >

            <h2 style={{ padding: 10 }}>Part Dataset</h2>
            <br />
            <div class="row">
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Part No' onChange={(e) => {
                  setPartno(e.target.value)
                }} list='PartNo' />
                <datalist id="PartNo">
                  <option></option>
                  {partDataset.map(x => {
                    return <option>{x.Part_No}</option>
                  })}
                </datalist>
              </div>
              <div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Part Description' list='PartDescription' onChange={(e) => {
                  setPartDesc(e.target.value)
                }} />
                <datalist id="PartDescription">
                  <option></option>
                  {partDataset.map(x => {
                    return <option>{x.Part_Description}</option>
                  })}
                </datalist>
              </div><div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customer' onChange={(e) => {
                  setCustomers(e.target.value)
                }} />
                <datalist id="Customer">
                  <option></option>
                  {[...new Set(partDataset.map(x => x.Customer))].map((Customer, index) => (
                    <option key={index}>{Customer}</option>
                  ))}
                </datalist>
              </div><div class="col">
                <input type='text' class="form-control form-rounded" placeholder='Category' list='Category' onChange={(e) => {
                  setCategory(e.target.value)
                }} />
                <datalist id="Category">
                  <option></option>
                  {[...new Set(partDataset.map(x => x.CATEGORY))].map((CATEGORY, index) => (
                    <option key={index}>{CATEGORY}</option>
                  ))}
                </datalist>
              </div>
              <div class="col">
                <button className='btn btn-primary' onClick={() => { seteditable(!editable) }} >Edit</button>
              </div>
            </div >
            <br />
            <div class="datatable-wide_part">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>PART NO</th>
                    <th>PART DESCRIPTION</th>
                    <th>CUSTOMER</th>
                    <th>CATEGORY</th>
                    <th>UNIT PRICE</th>
                    <th>CURRENCY</th>
                    <th>SIZE(CUSH/PA/BUDS)</th>
                    <th>BASE STYLE TUHOY</th>
                    <th>MOLDING</th>
                    <th>WOODSHOP</th>
                    <th>UPHOLSTERY</th>
                    <th>BUY & SELL</th>
                    <th>BOM</th>
                    <th>Update</th>
                  </tr>
                </thead>
                <tbody >
                  {partDataset.map((val, index) => {
                    return (
                      <tr>
                        <td>{val.Part_No}</td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.Part_Description}
                              onChange={(e) => handleInputChange(e, index, 'Part_Description')}
                            />
                            : val.Part_Description
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.Customer}
                              onChange={(e) => handleInputChange(e, index, 'Customer')}
                            />
                            : val.Customer
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.CATEGORY}
                              onChange={(e) => handleInputChange(e, index, 'CATEGORY')}
                            />
                            : val.CATEGORY
                          }
                        </td>
                        <td>
                          {editable && rank ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.Unit_price}
                              onChange={(e) => handleInputChange(e, index, 'Unit_price')}
                            />
                            : val.Unit_price
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.CURRENCY}
                              onChange={(e) => handleInputChange(e, index, 'Unit_price')}
                            />
                            : val.CURRENCY
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.SIZE}
                              onChange={(e) => handleInputChange(e, index, 'SIZE')}
                            />
                            : val.SIZE
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.BASE_STYLE_TUHOY}
                              onChange={(e) => handleInputChange(e, index, 'BASE_STYLE_TUHOY')}
                            />
                            : val.BASE_STYLE_TUHOY
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.MOLDING}
                              onChange={(e) => handleInputChange(e, index, 'MOLDING')}
                            />
                            : val.MOLDING
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.WOODSHOP}
                              onChange={(e) => handleInputChange(e, index, 'WOODSHOP')}
                            />
                            : val.WOODSHOP
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.UPHOLSTERY}
                              onChange={(e) => handleInputChange(e, index, 'UPHOLSTERY')}
                            />
                            : val.UPHOLSTERY
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.BUY_AND_SELL}
                              onChange={(e) => handleInputChange(e, index, 'BUY_AND_SELL')}
                            />
                            : val.BUY_AND_SELL
                          }
                        </td>
                        <td>
                          {editable ?
                            <input
                              type="text"
                              className="form-control form-rounded"
                              value={val.BOM}
                              onChange={(e) => handleInputChange(e, index, 'BOM')}
                            />
                            : val.BOM
                          }
                        </td>
                        <td><button class="btn btn-success" onClick={() => { Update((val.Part_No)) }}>Update</button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div> : null}

        {ED ? <div>
          <br></br>
          <div class="add_section" id='Part_dataset_add'>
            <center>
              <h3 style={{ padding: 25 }}>Add new Operator/Customer/Machine/Area</h3>
              <div class="row">
                <tr>
                  <td style={{ padding: 10 }}>
                    <label>Employee Name</label>
                    <input
                      type="text"
                      name="Employee_Name"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setEmployeeName(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Contact Number</label>
                    <input
                      type="text"
                      name="Contact_Number"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setContactNumber(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Department</label>
                    <select
                      type="select"
                      name="Department"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setDepartment(e.target.value);
                      }}
                    >
                      <option value="Choose Department">Choose Department</option>
                      <option value="Night_Operators">Night_Operators</option>
                      <option value="Morning_Operators">Morning_Operators</option>
                      <option value="Woodshop">Woodshop</option>
                      <option value="Management">Management</option>
                      <option value="WFH">WFH</option>
                    </select>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Source</label>
                    <input
                      type="text"
                      name="Source"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setSource(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Position</label>
                    <input
                      type="text"
                      name="Position"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setPosition(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Start Date</label>
                    <input
                      type="date"
                      name="Start_Date"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>End Date</label>
                    <input
                      type="date"
                      name="End_Date"
                      class="form-control form-rounded"
                      onBlur={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Status</label>

                    <select
                      type="select"
                      className="form-control form-rounded"
                      onBlur={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option value="Select Status">Select Status</option>
                      <option value="Employed">Employed</option>
                      <option value="Unemployed">Unemployed</option>
                      <option value="Lay Off">Lay Off</option>
                      <option value="Resignation">Resignation</option>
                      <option value="Suspension">Suspension</option>
                      <option value="Termination">Termination</option>
                    </select>


                  </td>
                </tr>

              </div>
              <div>
                <input
                  type="button"
                  class="btn btn-success"
                  style={{ width: "auto" }}
                  defaultValue="Submit"
                  name="Submit"
                  onClick={submitoperatordata}
                />
              </div>
              <br></br>
              <br></br>
            </center>
          </div>
          <br></br>

          <br></br>
          <h2>Employees List</h2>
          <center>
            <div class="Employee_filter">
              <div class="row">
                <tr>
                  <td style={{ padding: 10 }}>
                    <label>Employee Name</label>
                    <input
                      type="text"
                      name="Employee_Name"
                      list="Employee_Name"
                      class="form-control form-rounded"
                      onBlur={(e) => updateFilter(e, 'EmployeeName')}
                    />
                    <datalist id="Employee_Name">
                      <option></option>
                      {originalData.map(x => {
                        return <option key={x.Emp_ID}>{x.EmployeeName}</option>
                      })}
                    </datalist>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Contact Number</label>
                    <input
                      type="text"
                      name="Contact_Number"
                      class="form-control form-rounded"
                      onBlur={(e) => updateFilter(e, 'ContactNumber')}
                      list="ContactNumber"
                    />
                    <datalist id="ContactNumber">
                      <option></option>
                      {originalData.map(x => {
                        return <option key={x.Emp_ID}>{x.ContactNumber}</option>
                      })}
                    </datalist>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Department</label>
                    <select
                      type="select"
                      name="Department"
                      class="form-control form-rounded"
                      onBlur={(e) => updateFilter(e, 'Department')}
                    >
                      <option value="">Choose Department</option>
                      <option value="Night_Operators">Night_Operators</option>
                      <option value="Morning_Operators">Morning_Operators</option>
                      <option value="Woodshop">Woodshop</option>
                      <option value="Management">Management</option>
                      <option value="WFH">WFH</option>
                    </select>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Source</label>
                    <input
                      type="text"
                      name="Source"
                      class="form-control form-rounded"
                      onBlur={(e) => updateFilter(e, 'Source')}
                      list="Source"
                    />
                    <datalist id="Source">
                      <option></option>
                      {originalData.map(x => {
                        return <option key={x.Emp_ID}>{x.Source}</option>
                      })}
                    </datalist>
                  </td>
                  <td style={{ padding: 10 }}>
                    <label>Position</label>
                    <input
                      type="text"
                      name="Position"
                      class="form-control form-rounded"
                      onBlur={(e) => updateFilter(e, 'Position')}
                      list="Position"
                    />
                    <datalist id="Position">
                      <option></option>
                      {originalData.map(x => {
                        return <option key={x.Emp_ID}>{x.Position}</option>
                      })}
                    </datalist>
                  </td>

                  <td style={{ padding: 10 }}>
                    <label>Status</label>

                    <select
                      type="select"
                      className="form-control form-rounded"
                      onBlur={(e) => updateFilter(e, 'Status')}
                    >
                      <option value="">Select Status</option>
                      <option value="Employed">Employed</option>
                      <option value="Unemployed">Unemployed</option>
                      <option value="Lay Off">Lay Off</option>
                      <option value="Resignation">Resignation</option>
                      <option value="Suspension">Suspension</option>
                      <option value="Termination">Termination</option>
                    </select>


                  </td>
                </tr>

              </div>
            </div>
          </center>
          <div class="datatable-wide_part"><table class="table table-bordered">
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Contact Number</th>
                <th>Department</th>
                <th>Source</th>
                <th>Position</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Update</th>
              </tr>
            </thead>

            <tbody>
              {data.map((val, index) => (
                <tr key={val.Emp_ID}>
                  <td>
                    <input
                      type="text"
                      className="form-control form-rounded"
                      Value={data[index].EmployeeName}
                      onBlur={(e) => handleUpdate(e, index, 'EmployeeName')}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control form-rounded"
                      Value={data[index].ContactNumber}
                      onBlur={(e) => handleUpdate(e, index, 'ContactNumber')}
                    ></input>
                  </td>
                  <td>
                    <select
                      type="select"
                      className="form-control form-rounded"
                      Value={data[index].Department}
                      onBlur={(e) => handleUpdate(e, index, 'Department')}
                    >
                      <option value={data[index].Department}>{data[index].Department}</option>
                      <option value="Night_Operators">Night_Operators</option>
                      <option value="Morning_Operators">Morning_Operators</option>
                      <option value="Woodshop">Woodshop</option>
                      <option value="Management">Management</option>
                      <option value="WFH">WFH</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control form-rounded"
                      Value={data[index].Source}
                      onBlur={(e) => handleUpdate(e, index, 'Source')}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control form-rounded"
                      Value={data[index].Position}
                      onBlur={(e) => handleUpdate(e, index, 'Position')}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control form-rounded"
                      Value={data[index].StartDate}
                      onBlur={(e) => handleUpdate(e, index, 'StartDate')}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control form-rounded"
                      Value={data[index].EndDate}
                      onBlur={(e) => handleUpdate(e, index, 'EndDate')}
                    ></input>
                  </td>
                  <td>
                    <select
                      type="select"
                      className="form-control form-rounded"
                      Value={data[index].Status}
                      onBlur={(e) => handleUpdate(e, index, 'Status')}
                    >
                      <option value={data[index].Status}>{data[index].Status}</option>
                      <option value="Employed">Employed</option>
                      <option value="Unemployed">Unemployed</option>
                      <option value="Lay Off">Lay Off</option>
                      <option value="Resignation">Resignation</option>
                      <option value="Suspension">Suspension</option>
                      <option value="Termination">Termination</option>
                    </select>
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => { Update_employee(); }}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div> : null}

        {IPD ? <Add_Internal_Part_Dataset /> : null}
        {UPD ? <Upholstery_Part_Dataset /> : null}
        <br></br>
        <br></br>
      </div>
    </div >
  )
}

export default Add_Part_Dataset
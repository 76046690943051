import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import Header from './Header';
import './ShippingData.css'
import './Dashboard.css'
import { Host } from './App';
import throttle from 'lodash/throttle';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function OpenOrders() {
  var [del, setdel] = useState(1);
  const [postList, setPostList] = useState([]);
  var [PoNo, setPoNo] = useState({});
  const [PartNo, setPartNo] = useState({});
  const [Date1, setDate1] = useState(new Date());
  const [Postartdate, setPoStartdate] = useState('2023-01-01');
  const [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [customer, setCustomer] = useState({});
  const [Customers, setCustomers] = useState([]);
  const [partNo, setpartNo] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  var token = (window.localStorage.getItem("acessToken"));
  var totalQtyShipped = 0;
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/shippedData/search/${JSON.stringify(PoNo)},${JSON.stringify(PartNo)},${Postartdate},${Poenddate},${JSON.stringify(customer)},${token}`).then((data) => {
      setPostList(data.data)
    })
  }, [PoNo, PartNo, Postartdate, Poenddate, customer, part_dataset, del])

  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/Customers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/partNo/${token}`).then((data) => {
      setpartNo(data.data)
    });
  }, [])
  // to retreive part dataset
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)
    });
  }, [])
  // method to retrieve data combinely
  const search = () => {
    Axios.get(`https://` + Host + `/api/shippedData/search/${JSON.stringify(PoNo)},${JSON.stringify(PartNo)},${Postartdate},${Poenddate},${JSON.stringify(customer)},${token}`).then((data) => {
      setPostList(data.data)
      console.log("Search called");
    })
  }
  const Deleted = (PO_NO, PART_NO, ACTUAL_SHIP_DATE) => {
    setdel(del = del + 1)
    console.log("delete called" + del);

    // Axios.delete('https://`+ Host + `/api/Shippeddata/Delete', { PO_NO: PO_NO, PART_NO: PART_NO })
    Axios.delete(`https://` + Host + `/api/actualShippeddata/Delete/${PO_NO},${PART_NO},${ACTUAL_SHIP_DATE},${token}`).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
        });
        search();
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });

  };

  const Delete = throttle((PO_NO, PART_NO, ACTUAL_SHIP_DATE) => {
    setdel(del = del + 1)
    console.log("delete called" + del);
    Axios.delete(`https://` + Host + `/api/actualShippeddata/Delete/${PO_NO},${PART_NO},${ACTUAL_SHIP_DATE},${token}`).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
        });
        search();
      }
    })
      .catch(function (error) {
        console.log(error.response);
      });

  });
  return (
    <div className="MainPage_data">
      <div>
        <br></br>
        <Link to="/">
          <input type="button" class="btn btn-success" defaultValue="Logout" id="logout" />
        </Link>
      </div>
      <div class="Container">
        <h1>Shipped parts</h1>
        <div class="row">
          <div class="col">
            {/* Grab the particular Operator name */}
            <input type='text' class="form-control form-rounded" placeholder='PO NO' list="operators" onChange={(e) => {
              setPoNo(e.target.value)
            }} />
          </div>
          <div class="col">
            {/* Grab the particular Operator name */}
            <input type='text' class="form-control form-rounded" placeholder='PART NO' list="PartNo" onChange={(e) => {
              setPartNo(e.target.value)
            }} />
            <datalist id="PartNo">
              <option></option>
              {partNo.map(x => {
                return <option>{x.Part_No}</option>
              })}
            </datalist>

          </div>
          <div class="col">
            <div class="row" >
              <div class="col" id='moldng'>
                {/* Grab the particular dates */}
                <input type='date' class="form-control form-rounded" placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                  setPoStartdate(e.target.value)
                  search();

                }} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                <span class="tooltiptext">Filter By ACTUAL SHIP DATE </span>

              </div>
              <div class="col" >
                <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setPoEnddate(e.target.value)
                  search();
                }} />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customers' onChange={(e) => {
                setCustomer(e.target.value)
              }} />
              <datalist id="Customers">
                <option></option>
                {Customers.map(x => {
                  return <option>{x.Customer}</option>
                })}
              </datalist>
            </div>
            <div class="row">
              <Link to="/adddata">
                <input type="button" class="btn btn-success" defaultValue="Back" />
              </Link>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>PO NO</th>
              <th>PART NO</th>
              <th>PO SHIP DATE</th>
              <th>PART DESCRIPTION</th>
              <th>CUSTOMER</th>
              <th>ACTUAL SHIP DATE</th>
              <th>QTY SHIPPED</th>
              <th>COMMENTS</th>
              <th>DELETE</th>
            </tr>
          </thead>

          {postList.map((val, key) => {
            totalQtyShipped = postList.reduce((acc, val) => acc + val.QTY_SHIPPED, 0);
            return (

              <tbody>
                <tr key={key}>
                  <td>{val.PO_NO}</td>
                  <td>{val.PART_NO}</td>
                  <td>{(val.PO_SHIP_DATE).slice(0, 10)}</td>
                  <td>{val.PART_DESCRIPTION}</td>
                  <td>{val.CUSTOMER}</td>
                  <td>{(val.ACTUAL_SHIP_DATE).slice(0, 10)}</td>
                  <td>{val.QTY_SHIPPED}</td>
                  <td>{val.COMMENTS}</td>
                  <td><button class="btn btn-danger" onClick={() => { window.confirm('Are you sure you want to delete this Entry?',) && Delete((val.PO_NO), (val.PART_NO), ((val.ACTUAL_SHIP_DATE).slice(0, 10))) }}> Delete</button></td>
                </tr>

              </tbody>
            )
          })}
          <thead>
            <th colSpan="6" style={{ "fontSize": "x-large" }}>Total</th>
            <th style={{ "fontSize": "x-large" }}>{totalQtyShipped}</th>
            <th colSpan="2"></th>
          </thead>
        </table>
      </div>


    </div >
  )
}

export default OpenOrders
import React, { useState, useEffect } from 'react'
import real_l from "../real_logo.png";
import { Link, Redirect, useParams } from "react-router-dom"
import Axios from 'axios'
import { Host } from '../App';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function BOM_pivot() {
  const [date, setDate] = useState(new Date().toISOString());
  var User = JSON.parse(window.localStorage.getItem("Name"));
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();
  const [showPart, setShowPart] = useState(false);
  const [Date1, setDate1] = useState(new Date());
  const [Postartdate, setPoStartdate] = useState(Date1.toISOString().slice(0, 10));
  const [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [isLoading, setIsLoading] = useState(false);
  var token = (window.localStorage.getItem("acessToken"));
  var [section, setsection] = useState("Supplies");
  const [Item_No, setItem_No] = useState("");

  const [bom, setBOM] = useState([{
    "Description": " ",
    "QTY": " ",
    "Receiving Date": "",
  }]);
  const [isBOM, setIsBOM] = useState(false);
  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const today = new Date();
    let startDate, endDate;

    if (selectedOption === 'lastWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    } else if (selectedOption === 'thisWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    } else if (selectedOption === 'lastMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else if (selectedOption === 'thisMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (selectedOption === 'lastYear') {
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today.getFullYear() - 1, 11, 31);
    } else if (selectedOption === 'thisYear') {
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
    } else if (selectedOption === 'nextWeek') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (13 - today.getDay()));


    } else if (selectedOption === 'nextMonth') {
      startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    } else if (selectedOption === 'nextYear') {
      startDate = new Date(today.getFullYear() + 1, 0, 1);
      endDate = new Date(today.getFullYear() + 1, 11, 31);
    } else if (selectedOption === 'nextQuarter') {
      const currentMonth = today.getMonth();
      const currentQuarter = Math.floor(currentMonth / 3) + 1;
      const nextQuarterStartMonth = (currentQuarter * 3) % 12; // Start month of the next quarter
      const nextQuarterEndMonth = nextQuarterStartMonth + 2; // End month of the next quarter

      // If the next quarter starts next year, adjust the year accordingly
      const nextQuarterYear = nextQuarterStartMonth === 0 ? today.getFullYear() + 1 : today.getFullYear();

      startDate = new Date(nextQuarterYear, nextQuarterStartMonth, 1);
      endDate = new Date(nextQuarterYear, nextQuarterEndMonth + 1, 0);
    } else if (selectedOption === 'currentQuarter') {
      const currentMonth = today.getMonth();
      const currentQuarter = Math.floor(currentMonth / 3) + 1;
      const currentQuarterStartMonth = (currentQuarter - 1) * 3; // Start month of the current quarter
      const currentQuarterEndMonth = currentQuarterStartMonth + 2; // End month of the current quarter

      startDate = new Date(today.getFullYear(), currentQuarterStartMonth, 1);
      endDate = new Date(today.getFullYear(), currentQuarterEndMonth + 1, 0);
    } else if (selectedOption === 'nextDay') {
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
    } else {
      // Handle other options if needed
    }

    setPoStartdate(startDate);
    setPoEnddate(endDate);

  };
  const queryParams = {
    start_date: Postartdate,
    end_date: Poenddate,
    token: token,
    Item_No: Item_No
  };
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      Axios.get(`https://` + Host + `/BOM/pivot`, { params: queryParams }).then((data) => {
        console.log(data.data);
        if (data.data === "Error") {
          setIsLoading(false);
        }
        else {
          if (data.data.length > 0) {
            setBOM(data.data);
          } else if (data.data.length <= 1) {
            window.alert("No Data Received!")
          }
        }
        setIsLoading(false);
      }, 2000);

      setTimeout(() => {
        setIsLoading(false)
      }, 3000)
    });

  }, [section, Poenddate, Postartdate, token, Item_No])


  const toggleparts = () => {
    setShowPart(!showPart);
  }
  const downloadCSV = () => {
    const filename = "BOM";
    // Convert data to CSV string
    const csv = XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(bom));
    // Create a Blob object and download the file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(blob, filename);

  };


  const handlePrint = () => {
    const table = document.querySelector('#alldata');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          table tr:last-child td {
            font-weight: bold;
            background-color: lightgrey; /* Add light grey background color */
          }
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <table class="table table-striped">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };
  return (
    <div className="MainPage_data">
      <center><h1>BOM Pivot</h1></center>
      <div >
        <div class="Container">
          <div class="row">

            <div class="col-3"></div>

            <center> <div class="col-2"><h2 id='title'>{section}</h2></div></center>
            <br />
          </div>
          <div class="row">
            <div class="col-3">
              <div class="row">
                <label>Choose ETA</label>
                <div class="col" id='moldng'>
                  {/* Grab the particular dates */}
                  <input type='date' class="form-control form-rounded" placeholder='ETA' defaultValue={Postartdate} onChange={(e) => {
                    setPoStartdate(e.target.value)
                  }} />
                  {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                  <span class="tooltiptext">Filter By ETA </span>
                </div>
                <div class="col">
                  <input type='date' class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                    setPoEnddate(e.target.value)
                  }} />
                </div>
                <div class="ROW">
                  <select class="form-control form-rounded" onChange={handleSelectChange}>
                    <option value="">Select</option>
                    <option value="lastWeek">Last Week</option>
                    <option value="thisWeek">This Week</option>
                    <option value="lastMonth">Last Month</option>
                    <option value="thisMonth">This Month</option>
                    <option value="nextWeek">Next Week</option>
                    <option value="nextMonth">Next Month</option>
                    <option value="currentQuarter">Current Quarter</option>
                    <option value="nextQuarter">Next Quarter</option>
                    <option value="nextDay">Next Day</option>

                  </select>

                </div>

              </div>
            </div>
            <div class="col-2">

              <label></label>
              <input type='text' class="form-control form-rounded" placeholder='Item No' list="ItemNo" onChange={(e) => {
                setItem_No(e.target.value)
              }} />
              <datalist id="ItemNo">
                <option></option>
                {bom.map(x => {
                  return <option>{x.Item_No}</option>
                })}
              </datalist>
            </div>
          </div>
          <div class="col-3">

          </div>
          <div class="col-3">

          </div>
          <div class="col-1">

          </div>
          <center><Link to="/BOM">
            <button className="btn btn-primary" >Back</button></Link>
            <button className="btn btn-success" onClick={toggleparts}>Show Parts</button>
            <button className="btn btn-primary" onClick={handlePrint}>Print Table</button>
          </center>
          <table className="table table-light table-bordered" id='alldata'>
            <thead>
              <tr>
                <th>ITEM NO</th>
                <th>QTY REQUIRED</th>
                <th>UNIT</th>
              </tr>
            </thead>
            <tbody>
              {bom.map((val, index) => (
                <tr key={index}>
                  <td>
                    <span className='tooltip-container' id='pivotj'>
                      <p className='Description' >
                        <a href="#" className='description_link' style={{ "textDecoration": "none" }} >{val.Item_No}</a></p>
                      {showPart ?
                        <span className='tooltiptext'>
                          {val.Part_Nos}
                        </span> : null}
                    </span>
                  </td>
                  <td>{val.Total_Qty_Required}</td>
                  <td>{val.Purchase_Unit}</td>

                </tr>
              ))}
              <tr>
                <th>Total</th>
                <th>{bom.reduce((acc, val) => acc + (val.Total_Qty_Required), 0).toFixed(2)}</th>
              </tr>
            </tbody>
          </table>
          <div class="col-7">

          </div>
          <div class="col-1">

          </div>
        </div>
        <br></br>
        <br></br>

      </div>
      {isLoading ?
        <div className='overlay'>
          <div className="loading-container">
            <div className="loading-dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            <p className='loading'>Loading</p>
          </div>

        </div> : null}
    </div>

  )
}

export default BOM_pivot;
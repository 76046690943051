import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { host } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { Button, Menu, Dropdown, Row } from 'antd';
import { Link } from "react-router-dom";
function PO_Supplies(props) {
  var token = (window.localStorage.getItem("acessToken"));
  const user = JSON.parse(window.localStorage.getItem("Name"));
  var [Date1, setDate1] = useState(new Date());
  const [Date2, setDate2] = useState(Date1.toISOString().slice(0, 10));
  const [part_dataset, setpart_dataset] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  var [Customers, setCustomers] = useState([]);
  var length = 0;

  const [data, setData] = useState([
    { Po_No: '', Item_No: '', Po_Date: '', Item_Description: '', Supplier: '', Category: '', Qty: '', Unit: '', Unit_Cost: '', Currency: '', ETA: '', Notes: '' },
    { Po_No: '', Item_No: '', Po_Date: '', Item_Description: '', Supplier: '', Category: '', Qty: '', Unit: '', Unit_Cost: '', Currency: '', ETA: '', Notes: '' },
    { Po_No: '', Item_No: '', Po_Date: '', Item_Description: '', Supplier: '', Category: '', Qty: '', Unit: '', Unit_Cost: '', Currency: '', ETA: '', Notes: '' },
    { Po_No: '', Item_No: '', Po_Date: '', Item_Description: '', Supplier: '', Category: '', Qty: '', Unit: '', Unit_Cost: '', Currency: '', ETA: '', Notes: '' },
    { Po_No: '', Item_No: '', Po_Date: '', Item_Description: '', Supplier: '', Category: '', Qty: '', Unit: '', Unit_Cost: '', Currency: '', ETA: '', Notes: '' }
  ]);


  // Hook to update the date as today's date
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  useEffect(() => {
    Axios.get(`https://` + host + `/api/supplychain/supplies/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data);
    });
  }, []);

  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/suppliers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])
  useEffect(() => {
    const newData = data.map((line) => ({ ...line, Date: Date2 }));
    setData(newData);
  }, [Date2])

  const submitValue = (e, index) => {
    const newData = [...data];
    newData[index].Item_No = e.target.value;
    newData[index].Supplier = (part_dataset.find((x) => `${x.Item_No}` === e.target.value).Supplier);
    // newData[index].Item_Description = part_dataset.find((x) => x.ITEM_NO === e.target.value).ITEM_DESCRIPTION;
    newData[index].Item_Description = part_dataset.find((x) => `${x.Item_No}` === e.target.value).Item_Description;
    newData[index].Unit = part_dataset.find((x) => `${x.Item_No}` === e.target.value).Unit;
    newData[index].Currency = part_dataset.find((x) => `${x.Item_No}` === e.target.value).CURRENCY;
    setData(newData);
  }

  const addLines = () => {
    if (data.length < 15) {
      const newData = [...data];
      for (let i = 0; i < 5; i++) {
        newData.push({ Po_No: '', Item_No: '', Po_Date: '', Item_Description: '', Supplier: '', Category: '', Qty: '', Unit: '', Unit_Cost: '', Currency: '', ETA: '', Notes: '' });
      }
      setData(newData);
    } else {
      window.alert("Lines Reached maximum limit")
    }

  }
  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    props.history.push('/');
  }
  const userMenu = (
    <Menu>
      <Menu.Item key="logout">
        <a onClick={handleLogout} href="/">
          <LogoutOutlined />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const submitdata = () => {
    const filteredData = [];


    data.forEach(item => {
      if (
        item.Po_Date &&
        item.Po_No &&
        item.Item_No &&
        item.Supplier &&
        item.Item_Description &&
        item.ETA
      ) {
        const newData = [
          item.Po_Date,
          item.Po_No,
          item.Item_No,
          item.Supplier,
          item.Item_Description,
          item.Category,
          item.Qty,
          item.Unit,
          item.ETA,
          item.Unit_Cost,
          item.Currency,
          item.Notes,
          item.CUSTOMS,
          item.SHIPPING
        ];

        filteredData.push(newData);
      }
      length = filteredData.length;
    });
    console.log(filteredData);
    setIsLoading(true);

    setTimeout(() => {
      Axios.post(`https://` + host + `/supplychain/PO_Supplies`, { filteredData })
        .then((response) => {
          if (response.status === 200) {
            window.alert(response.data.message);
            setIsLoading(false);
            window.location.reload();

          }
        })
        .catch(error => {
          window.alert("Error Occured")
          setIsLoading(false);
        });
    }, 2000);

    // window.location.reload(false);

  }
  const toggleShipping = (e, index) => {
    const newData = [...data];
    newData[index].SHIPPING = newData[index].SHIPPING === "Yes" ? "No" : "Yes";
    setData(newData);
  };
  const toggleCustoms = (e, index) => {
    const newData = [...data];
    newData[index].CUSTOMS = newData[index].CUSTOMS === "Yes" ? "No" : "Yes";
    setData(newData);
  };
  return (
    <div>
      <br></br>
      <button onClick={addLines} class="btn btn-success">Add Row</button>
      <br></br>
      <br></br>

      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>Po No</th>
            <th>Item No</th>
            <th>Po Date</th>
            <th>Item Description</th>
            <th>Supplier</th>
            <th>Category</th>
            <th>Qty</th>
            <th>Unit</th>
            <th>Unit Cost</th>
            <th>Currency</th>
            <th>ETA</th>
            <th>CUSTOMS</th>
            <th>SHIPPING</th>
            <th>Notes</th>
          </tr>
          {data.map((line, index) => (
            <tr>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Po_No = e.target.value;
                    setData(newData);
                  }}
                  Value={data[index].Po_No}
                  placeholder="Po No"
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Item_No = e.target.value;
                    setData(newData);
                  }}
                  onBlur={(e) => submitValue(e, index)} // Call the submitValue function on blur
                  list="PartNo"
                  id="operator"
                  placeholder="Enter Item No"
                />
                <div className="scrollbar">
                  <datalist id="PartNo">
                    <option></option>
                    {part_dataset.map((x) => {
                      return <option value={x.Item_No}>{x.Item_No}-{x.Supplier}</option>;
                    })}
                  </datalist>
                </div>
              </td>
              <td>
                <input
                  type="date"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Po_Date = e.target.value;
                    setData(newData);
                  }}
                  placeholder="Po Date"
                  Value={data[index].Po_Date}
                  name="target"
                  required
                />
              </td>
              <td class="col-2">
                <input
                  type="text"
                  className="form-control form-rounded"
                  Value={data[index].Item_Description}
                  placeholder="Item Description"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Item_Description = e.target.value;
                    setData(newData);
                  }}
                  required

                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={data[index].Supplier}
                  placeholder="Supplier"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Supplier = e.target.value;
                    setData(newData);
                  }}
                  list='Suppliers'
                />
                <datalist id="Suppliers">
                  <option></option>
                  {Customers.map(x => {
                    return <option>{x.Supplier}</option>
                  })}
                </datalist>
              </td>
              <td >
                <input
                  type="text"
                  className="form-control form-rounded"
                  placeholder="Category"
                  list="category"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Category = e.target.value;
                    setData(newData);
                  }}
                />
                <datalist id='category'>
                  <option>PRODUCTION</option>
                  <option>SAFETY</option>
                  <option>OFFICE</option>
                  <option>CRISTMAS</option>
                  <option>MAINTAINENANCE</option>
                </datalist>
              </td>
              <td >
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Qty = e.target.value;
                    setData(newData);
                  }}
                  placeholder="Qty"
                />
              </td>
              <td >
                <input
                  type="text"
                  className="form-control form-rounded"
                  placeholder="Unit"
                  Value={data[index].Unit}
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Unit = e.target.value;
                    setData(newData);
                  }}
                  list="unit"
                />
                <datalist id='unit'>
                  <option>Each</option>
                  <option>Feet</option>
                  <option>FT</option>
                  <option>GAL</option>
                  <option>SHEET</option>
                  <option>KG</option>
                  <option>LBS</option>
                  <option>METER</option>
                  <option>PACK</option>
                  <option>PC</option>
                  <option>ROLL</option>
                  <option>YARDS</option>
                </datalist>
              </td>
              <td >
                <input
                  type="text"
                  className="form-control form-rounded"
                  placeholder="Unit Cost"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Unit_Cost = e.target.value;
                    setData(newData);
                  }}
                />
              </td>
              <td >
                <input
                  type="select"
                  className="form-control form-rounded"
                  placeholder="Currency"
                  Value={data[index].Currency}
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Currency = e.target.value;
                    setData(newData);
                  }}
                  list='currency'
                />
                <datalist id='currency'>
                  <option>CAD</option>
                  <option>USD</option>
                </datalist>
              </td>
              <td>
                <input
                  type="date"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].ETA = e.target.value;
                    setData(newData);
                  }}
                  placeholder="Po Date"
                  name="target"
                  required
                />
              </td>
              <td>
                <button
                  className="btn btn-light"
                  onClick={(e) => toggleCustoms(e, index)}
                >
                  {data[index].CUSTOMS}
                </button></td>
              <td><button
                className="btn btn-light"
                onClick={(e) => toggleShipping(e, index)}
              >
                {data[index].SHIPPING}
              </button>
              </td>
              <td>
                <input
                  type="textarea"
                  className="form-control form-rounded"
                  placeholder="Notes"
                  name="Supervisor_notes"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Notes = e.target.value;
                    setData(newData);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={submitdata} class="btn btn-primary">Submit</button>
      {isLoading ?
        <div className='overlay'>
          <div className="loading-container">
            <div className="loading-dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            <p className='loading'>Submitting</p>
          </div>

        </div> : null}
    </div>
  )
}

export default PO_Supplies;